/* eslint-disable react-hooks/exhaustive-deps */
// import HeaderTitle from "../../component/header-title";
import ChartLineIcon from "../../img/chart-line-up.svg";
import ClockIcon from "../../img/clock.svg";
import FacemeshIcon from "../../img/face-meh.svg";
import Header from "../../component/header";
import FooterTab from "../../component/footer-tab";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import {
  getTransactionHistory,
  isTradeSuccess,
  getUser,
} from "../../redux/actions";
import moment from "moment";
import BitLogo from "../../img/bit-logo.png";
import EthLogo from "../../img/eth-logo.png";
import DogeLogo from "../../img/doge.png";
import UsdtLogo from "../../img/usdt-logo-1.png";
import BnbLogo from "../../img/bnb_logo.png";
import { Link } from "react-router-dom";

function Transaction() {
  const dispatch = useDispatch();
  const transactionHistory = useSelector(
    (state) => state.trasactionReducer.transactionHistory
  );
  // const userDetails = useSelector((state) => state.user.userData);
  const user = JSON.parse(localStorage.getItem("user"));
  useEffect(() => {
    dispatch(getTransactionHistory({ user_id: user._id }));
    dispatch(isTradeSuccess());
    dispatch(getUser({ emailId: user.emailId }));
  }, []);

  const getDifferenceOfDates = (endTime, startTime, diffDays, index) => {
    const currentValue = new Date().getTime();
    const startValue = new Date(startTime).getTime();
    const endValue = new Date(endTime).getTime();
    if (currentValue > endValue) {
      return 100;
    }
    const days = Math.ceil((currentValue - startValue) / (1000 * 3600 * 24));
    var isPreviousDate = moment(endTime).isBefore(moment());
    if (diffDays === 1) {
      var hours;
      var daysPercent;
      if (!isPreviousDate) {
        hours = (currentValue - startValue) / 1000 / 3600;
        daysPercent = ((hours / 24) * 100).toFixed(2);
      } else {
        daysPercent = 100;
      }
      return daysPercent;
    } else if (diffDays) {
      const daysPercent = (days / diffDays) * 100;
      console.log(startTime, daysPercent);
      return daysPercent.toFixed(2);
    } else {
      return 0;
    }
  };

  const textStatus = (endTime) => {
    var isPreviousDate = moment(endTime).isBefore(moment());
    return isPreviousDate === false ? "Cumulative" : "End";
  };

  const profileamount = (amount, per) => {
    let calculateAmount = amount * (per / 100);
    return calculateAmount.toFixed(5);
  };

  return (
    <div>
      <Header />
      <section className="py-2 px-3 contant space">
        <div className="robot-sec ">
          <div className="px-3 py-2 mb-2">
            <div className="row align-items-center ">
              <div className="col-11">
                <p className="text-white mb-0">Robot List</p>
              </div>
              <div className="col-1"></div>
            </div>
          </div>
        </div>
        {Array.isArray(transactionHistory) &&
        transactionHistory.length === 0 ? (
          <div
            className="text-center row d-flex justify-content-center align-items-center"
            style={{ height: "100vh" }}
          >
            <div>
              <div className="align-middle fs-2 fw-bold">
                No transaction to display
              </div>
              <div style={{ fontSize: "1.2rem", fontWeight: "normal" }}>
                Head Towards{" "}
                <span className="fw-bold">
                  <Link to="/home">Trade </Link>
                </span>
                for some exciting deals
              </div>
            </div>
          </div>
        ) : (
          transactionHistory.map((ele, index) => (
            <div
              key={index}
              className="interest-box-trans p-3 bg-white shadow-sm mb-3"
            >
              <div className="border-bottom px-3 mb-3">
                <div className="row ">
                  <div className="col-8 px-0">
                    <ul className="list-unstyled">
                      <li className="d-inline-block pe-2 lg-ic">
                        {ele.coinstype === "BTC" && (
                          <img
                            src={BitLogo}
                            className="img-fluid img-class-trade"
                            alt=""
                          />
                        )}
                        {ele.coinstype === "DOGE" && (
                          <img
                            src={DogeLogo}
                            className="img-fluid img-class-trade"
                            alt=""
                          />
                        )}
                        {ele.coinstype === "BNB" && (
                          <img
                            src={BnbLogo}
                            className="img-fluid img-class-trade"
                            alt=""
                          />
                        )}
                        {ele.coinstype === "ETH" && (
                          <img
                            src={EthLogo}
                            className="img-fluid img-class-trade"
                            alt=""
                          />
                        )}
                        {ele.coinstype === "USDT" && (
                          <img
                            src={UsdtLogo}
                            className="img-fluid img-class-trade"
                            alt=""
                          />
                        )}
                        <span style={{ marginLeft: 10 }}>{ele.coinstype}</span>
                      </li>
                    </ul>
                  </div>
                  <div className="col-4 px-0">
                    <ul className="d-flex justify-content-end list-unstyled">
                      <li className="d-inline-block ps-2">
                        <img height={16} src={ChartLineIcon} alt="" />
                      </li>
                      <li className="d-inline-block  ps-2">
                        <img height={16} src={ClockIcon} alt="" />
                      </li>
                      <li className="d-inline-block  ps-2">
                        <img height={16} src={FacemeshIcon} alt="" />
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="row mt-2">
                <div className="col-3 px-3">
                  <h3 className="text-muted">{ele?.per}%</h3>
                  <p className="text-muted">Expected Profit</p>
                </div>
                <div className="col-3">
                  <h3 className="text-right">
                    ${Math.round((ele?.amount + Number.EPSILON) * 100) / 100}
                  </h3>
                  <p className="text-muted text-right">Commission Amount</p>
                </div>
                <div style={{ textAlign: "end" }} className="col-6 pr-3 ">
                  <button
                    type="submit"
                    className="btn btn-sm px-3 theme-btn rounded-pill"
                  >
                    {textStatus(ele.endTime)}
                  </button>
                </div>
              </div>
              <div className="row align-items-center border-bottom pb-3 mx-1 ">
                <div className="col-6 px-0">
                  <div className="progress">
                    <div
                      className="progress-bar theme-bg"
                      role="progressbar"
                      style={{
                        width: `${getDifferenceOfDates(
                          ele.endTime,
                          ele.startTime,
                          ele.days,
                          index
                        )}%`,
                      }}
                      aria-valuenow={100}
                      aria-valuemin={0}
                      aria-valuemax={100}
                    ></div>
                  </div>
                </div>
                <div className="col-6 px-0 text-end">
                  <p className="mb-0 text-muted">
                    {getDifferenceOfDates(
                      ele.endTime,
                      ele.startTime,
                      ele.days,
                      index
                    )}
                    /100 Commission Progess
                  </p>
                </div>
              </div>
              <div className="row px-3 detail-box pt-3">
                <div className="col-6">
                  <h4 className="mb-1">{ele.days} Days</h4>
                  <p className="mb-0 text-muted">Trust Period</p>
                </div>
                <div className="col-6">
                  <h4 className="mb-1">
                    ${profileamount(ele.amount, ele.per)}
                  </h4>
                  <p className="mb-0 text-muted">Trading Income</p>
                </div>
              </div>
              <div className="row px-3 detail-box pt-3">
                <div className="col-6">
                  <h4 className="mb-1">
                    ${profileamount(ele.amount, ele.per)}
                  </h4>
                  <p className="mb-0 text-muted">Trading Income</p>
                </div>
                <div className="col-6">
                  <h4 className="mb-1">24x7</h4>
                  <p className="mb-0 text-muted">Working Hours</p>
                </div>
              </div>
              <div className="row px-3 detail-box pt-3">
                <div className="col-6">
                  <h4 className="mb-1">
                    {moment(`${ele.startTime}`).format("DD/MM/YYYY hh:mm")}
                  </h4>
                  <p className="mb-0 text-muted">Creation Time</p>
                </div>
                <div className="col-6">
                  <h4 className="mb-1">
                    {moment(`${ele.endTime}`).format("DD/MM/YYYY hh:mm")}
                  </h4>
                  <p className="mb-0 text-muted">End Time</p>
                </div>
              </div>
            </div>
          ))
        )}
      </section>
      <FooterTab to="/transaction" />
    </div>
  );
}
export default Transaction;
