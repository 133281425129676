/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useRef, useState } from "react";
import Logo from "../../img/logo_new.png";
import AngelImg from "../../img/angle-left.svg";
import { useSelector, useDispatch } from "react-redux";
import { Link, useLocation, useParams } from "react-router-dom";
import { Row, Col } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { CountryData } from "../../constantData";
import { registerNewUser } from "../../redux/actions";
import { Formik } from "formik";
import * as yup from "yup";
import { publicIp } from "public-ip";
function Register() {
  const ref = useRef(null);
  const navigate = useNavigate();
  const { referal_code } = useParams();
  const registedUser = useSelector((state) => state.user.registerData);
  const dispatch = useDispatch();
  const [ipAddr, setIpAddr] = useState("");
  const [disabled, setDisabled] = useState(false);
  const { state } = useLocation();
  const initialValues = {
    firstname: "",
    lastname: "",
    phonenumber: "",
    email: state?.email ? state?.email : "",
    password: "",
    confirmpassword: "",
    referalBy: referal_code ? referal_code : "",
    pin: "",
  };

  const registerValidationSchema = yup.object().shape({
    firstname: yup.string().required("Please enter the firstname"),
    lastname: yup.string().required("Please enter the lastname"),
    phonenumber: yup.number().required("Please enter the phone number"),
    email: yup
      .string()
      .email("Please enter valid email")
      .required("Email Address is Required"),
    password: yup
      .string()
      .min(8, ({ min }) => `Password must be at least ${min} characters`)
      .required("Password is required"),
    confirmpassword: yup
      .string()
      .oneOf([yup.ref("password")], "Password does not match")
      .min(8, ({ min }) => `Password must be at least ${min} characters`)
      .required("Password is required"),
    pin: yup
      .string()
      .max(6)
      .min(6, ({ min }) => `Pin must be at least ${min} characters`)
      .required("Create pin is required"),
  });

  const registerUserOne = (values) => {
    setDisabled(true);
    const regiesterUSer = {
      fName: values.firstname,
      lName: values.lastname,
      emailId: values.email,
      phone: Number(values.phonenumber),
      password: values.password,
      confirmPassword: values.confirmpassword,
      referalBy: values.referalBy,
      pin: Number(values.pin),
      macAddress: ipAddr,
    };
    dispatch(registerNewUser(regiesterUSer));
    if (registedUser) {
      navigate("/home");
    }
  };

  useEffect(() => {
    getIP();
  }, []);

  useEffect(() => {
    if (registedUser) {
      navigate("/home");
    }
  }, [registedUser]);

  const getIP = async () => {
    const ipDetails = await publicIp();
    setIpAddr(`${ipDetails}`);
  };

  return (
    <div className="mb-3">
      <div className="mt-3 ">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-2" onClick={() => navigate("/")}>
              <img src={AngelImg} height={25} alt="" />
            </div>
            <div className="col-8 text-center">
              <h4 className="mb-0 fs-3">Register</h4>
            </div>
          </div>
        </div>
      </div>
      <section className="register  ">
        <div className="container">
          <div className="d-flex py-3">
            <img src={Logo} className=" mx-auto" width={200} alt="logo" />
          </div>
          <div className=" px-3">
            <div className="mx-auto">
              <Formik
                enableReinitialize
                innerRef={ref}
                validationSchema={registerValidationSchema}
                initialValues={initialValues}
                onSubmit={(values) => {
                  registerUserOne(values);
                }}
              >
                {({
                  handleChange,
                  handleBlur,
                  handleSubmit,
                  values,
                  errors,
                  isValid,
                  dirty,
                }) => (
                  <>
                    <form>
                      <Row>
                        <Col xs={6}>
                          <div className="input-cont">
                            <div className="input-log">
                              <label
                                htmlFor="mobile_country"
                                className="fs-6 font-weight-500"
                              >
                                First Name
                              </label>
                              <div className="row align-items-center">
                                <div className="col-12">
                                  <input
                                    type="text"
                                    placeholder="Enter First Name"
                                    name="firstName"
                                    value={values.firstname}
                                    className="form-input custom_input w-100"
                                    onChange={handleChange("firstname")}
                                  />
                                </div>
                              </div>
                            </div>
                            {errors.firstname && (
                              <div>
                                <span fontSize={10} style={{ color: "red" }}>
                                  {" "}
                                  {errors.firstname}
                                </span>
                              </div>
                            )}
                          </div>
                        </Col>
                        <Col xs={6}>
                          <div className="input-cont">
                            <div className="input-log">
                              <label
                                htmlFor="mobile_country"
                                className="fs-6 font-weight-500"
                              >
                                Last Name
                              </label>
                              <div className="row align-items-center">
                                <div className="col-12">
                                  <input
                                    type="text"
                                    name="lastName"
                                    value={values.lastname}
                                    placeholder="Enter Last Name"
                                    className="form-input custom_input w-100"
                                    onChange={handleChange("lastname")}
                                  />
                                </div>
                              </div>
                            </div>
                            {errors.lastname && (
                              <div>
                                <span fontSize={10} style={{ color: "red" }}>
                                  {" "}
                                  {errors.lastname}
                                </span>
                              </div>
                            )}
                          </div>
                        </Col>
                      </Row>
                      <div className="input-cont">
                        <div className="input-log">
                          <label
                            htmlFor="mobile_country"
                            className="fs-6 font-weight-500"
                          >
                            Email
                          </label>
                          <div className="row align-items-center">
                            <div className="col-12">
                              <input
                                type="email"
                                name="emailId"
                                value={values.email}
                                placeholder="Enter email "
                                className="form-input custom_input w-100"
                                onChange={handleChange("email")}
                              />
                            </div>
                          </div>
                        </div>
                        {errors.email && (
                          <div>
                            <span fontSize={10} style={{ color: "red" }}>
                              {" "}
                              {errors.email}
                            </span>
                          </div>
                        )}
                      </div>

                      <div className="input-cont">
                        <div className="input-log">
                          <label
                            htmlFor="mobile_country"
                            className="fs-6 font-weight-500"
                          >
                            Password
                          </label>
                          <div className="row align-items-center">
                            <div className="col-12">
                              <input
                                type="password"
                                name="password"
                                value={values.password}
                                placeholder="Enter password"
                                className="form-input custom_input w-100"
                                onChange={handleChange("password")}
                              />
                            </div>
                          </div>
                        </div>
                        {errors.password && (
                          <div>
                            <span fontSize={10} style={{ color: "red" }}>
                              {" "}
                              {errors.password}
                            </span>
                          </div>
                        )}
                      </div>

                      <div className="input-cont">
                        <div className="input-log">
                          <label
                            htmlFor="mobile_country"
                            className="fs-6 font-weight-500"
                          >
                            Confirm Password
                          </label>
                          <div className="row align-items-center">
                            <div className="col-12">
                              <input
                                type="password"
                                name="confirmPassword"
                                value={values.confirmpassword}
                                placeholder="Enter confirm password"
                                className="form-input custom_input w-100"
                                onChange={handleChange("confirmpassword")}
                              />
                            </div>
                          </div>
                        </div>
                        {errors.confirmpassword && (
                          <div>
                            <span fontSize={10} style={{ color: "red" }}>
                              {" "}
                              {errors.confirmpassword}
                            </span>
                          </div>
                        )}
                      </div>

                      <div className="input-cont">
                        <div className="input-log">
                          <label
                            htmlFor="mobile_country"
                            className="fs-6 font-weight-500"
                          >
                            Mobile phone number
                          </label>
                          <div className="d-flex">
                            <select
                              className="form-select custom_select "
                              name="mobileCountry"
                              id="mobile_country"
                              style={{ width: "100px" }}
                            >
                              {CountryData.map((ele, index) => (
                                <option key={index} value={ele.dial_code}>
                                  {ele.code}
                                  {ele.dial_code}
                                </option>
                              ))}
                            </select>
                            <input
                              type="tel"
                              placeholder="Enter Mobile number"
                              className="form-input custom_input w-100"
                              name="phone"
                              value={values.phonenumber}
                              onChange={handleChange("phonenumber")}
                            />
                          </div>
                        </div>
                        {errors.phonenumber && (
                          <div>
                            <span fontSize={10} style={{ color: "red" }}>
                              {" "}
                              {errors.phonenumber}
                            </span>
                          </div>
                        )}
                      </div>

                      <div className="input-cont">
                        <div className="input-log">
                          <label
                            htmlFor="mobile_country"
                            className="fs-6 font-weight-500"
                          >
                            Refer Code
                          </label>
                          <div className="row align-items-center">
                            <div className="col-12">
                              <input
                                type="text"
                                placeholder="Enter Refer code"
                                name="referalBy"
                                value={values.referalBy}
                                className="form-input custom_input w-100"
                                onChange={handleChange("referalBy")}
                              />
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="form-groupinput-log">
                        <label
                          htmlFor="mobile_country"
                          className="fs-6 font-weight-500"
                        >
                          Create Pin
                        </label>
                        <div className="row align-items-center">
                          <div className="col-12">
                            <input
                              type="tel"
                              placeholder="Enter 6 digit pin"
                              value={values.pin}
                              onChange={handleChange("pin")}
                              className="form-input custom_input w-100"
                              maxLength="6"
                            />
                          </div>
                        </div>
                        {errors.pin && (
                          <div>
                            <span fontSize={10} style={{ color: "red" }}>
                              {" "}
                              {errors.pin}
                            </span>
                          </div>
                        )}
                      </div>

                      <button
                        disabled={!isValid || disabled}
                        type="submit"
                        className="theme-btn btn w-100 mt-3"
                        onClick={handleSubmit}
                      >
                        Submit
                      </button>
                      <p className="mt-4 mb-3 pb-5">
                        If you have already account!{" "}
                        <Link to="/login">Click here to Login</Link>
                      </p>
                    </form>
                  </>
                )}
              </Formik>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
export default Register;
