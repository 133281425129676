function Otp() {
  return (
    <div className="mb-3">
      <div className="mt-3">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-12 text-center">
              <h4 className="mb-0 fs-3">Login</h4>
            </div>
          </div>
        </div>
      </div>

      <div className="form-group input-box">
        <label htmlFor="mobile_country" className="fs-6 font-weight-500">
          Message verification code
        </label>
        <div className="row align-items-center">
          <div className="col-6">
            <input
              type="text"
              placeholder="Enter verification code"
              className="form-input custom_input"
            />
          </div>
          <div className="col-6 text-end">
            <button className="p-0 btn-sm btn text-primary ">
              <small>Send verification code</small>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Otp;
