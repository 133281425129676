import React from "react";
import Nav from "../../component/nav";
import Footer from "../../component/footer";

const Privacy = () => {
  const supportEmail = "Support@Metafoxbot.com";
  const scrollToFAQ = () => {
    const faqSection = document.getElementById("faq");
    if (faqSection) {
      faqSection.scrollIntoView({ behavior: "smooth" });
    }
  };
  return (
    <>
      <Nav scrollToFAQ={scrollToFAQ} />
      <div
        style={{ textAlign: "justify" }}
        className="d-flex bg-white flex-column align-items-center  justify-content-center px-4"
      >
        <div className="fs-1 fw-bold pb-2" style={{ marginTop: "80px" }}>
          Privacy Policy
        </div>
        <div>
          Welcome to MetaFoxBot! Your privacy is important to us, and we are
          committed to protecting and respecting your personal information. This
          Privacy Policy explains how MetaFoxBot collects, uses, and safeguards
          your data when you use our website and services.
        </div>
        <div className="fw-bold pt-2">Our Mission:</div>
        <div>
          Empowering users to navigate the complex world of crypto staking with
          confidence and ease. We strive to eliminate uncertainties by providing
          a secure and intelligent solution that minimizes risks while
          maximizing rewards.
        </div>

        <div className="fw-bold pt-2">1. Information We Collect:</div>
        <div>
          MetaFoxBot reserves the right to update and modify these Terms &
          Conditions. Any changes will be posted on this page, and the date of
          the last update will be revised accordingly.Personal Information: We
          may collect personal information such as your name, email address, and
          contact details when you create an account or engage with our
          services. Transaction Data: In the course of using MetaFoxBot for
          crypto staking, we may collect transaction data related to your
          staking activities. Device Information: We may collect information
          about the device you use to access MetaFoxBot, including the IP
          address, browser type, and operating system.
        </div>
        <div className="fw-bold pt-2">2. How We Use Your Information:</div>
        <div>
          Crypto Staking Services: Your personal information is used to provide
          you with our crypto-staking services, including account management and
          transaction processing. Communication: We may use your contact
          information to send important updates, notifications, and promotional
          materials related to MetaFoxBot. You can opt out of marketing
          communications at any time. Improving Our Services: We analyze usage
          data to enhance and optimize MetaFoxBot, ensuring a better user
          experience and the effectiveness of our artificial intelligence robot.
        </div>
        <div className="fw-bold pt-2">3. Data Security:</div>
        <div>
          We implement robust security measures to protect your personal
          information from unauthorized access, disclosure, alteration, and
          destruction. Our AI-driven platform is designed with your data
          security as a top priority.
        </div>
        <div className="fw-bold pt-2">4. Sharing Your Information:</div>
        <div>
          We do not sell, trade, or otherwise transfer your personal information
          to third parties without your explicit consent, except for the purpose
          of providing our services or as required by law.
        </div>
        <div className="fw-bold pt-2">5. Cookies and Similar Technologies:</div>
        <div>
          MetaFoxBot uses cookies and similar technologies to enhance your
          browsing experience. You can manage your cookie preferences through
          your browser settings.
        </div>
        <div className="fw-bold pt-2">6. Your Rights: </div>
        <div>
          You have the right to access, correct, or delete your personal
          information. You can exercise these rights by contacting us through
          the provided channels.
        </div>
        <div className="fw-bold pt-2">7. Changes to the Privacy Policy: </div>
        <div>
          MetaFoxBot reserves the right to update and modify this privacy
          policy. Any changes will be posted on this page, and the date of the
          last update will be revised accordingly.
        </div>
        <div className="fw-bold pt-2">8. Contact Us: </div>
        <div>
          8. Contact Us: If you have any questions, concerns, or requests
          regarding your privacy or this privacy policy, please contact us at{" "}
          <a href={`mailto:${supportEmail}`}>{supportEmail}</a>.
        </div>
        <div className="pt-2">
          Thank you for choosing MetaFoxBot. Your trust and privacy are
          fundamental to our commitment to providing you with a secure and
          innovative crypto-staking experience.
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Privacy;
