import HomeActiveIcon from "../../img/home-active.svg";
import HomeInActiveIcon from "../../img/home.svg";
import TransactionIcon from "../../img/transaction.svg";
import TransactionActiveIcon from "../../img/transaction_act.svg";
import WalletIcon from "../../img/Wallet.svg";
import WalletActiveIcon from "../../img/Wallet_act.svg";
import UserIcon from "../../img/Profile.svg";
import UserActiveIcon from "../../img/Profile_act.svg";
import { Link, useMatch, useResolvedPath } from "react-router-dom";
import "./footer.css";

function FooterTab({ to }) {
  let resolved = useResolvedPath(to);
  let match = useMatch({ path: resolved.pathname, end: true });

  return (
    <footer className="bg-white footer">
      <div className="container">
        <div className="row align-items-center">
          <div className="col-3 px-1 mt-2">
            <Link to="/home">
              <div
                className={`card border-0 text-center footer-nav ${
                  match.pathname === "/home" ? "active" : ""
                }`}
              >
                <img
                  src={
                    match.pathname === "/home"
                      ? HomeActiveIcon
                      : HomeInActiveIcon
                  }
                  className=" mx-auto mb-1"
                  width={20}
                  height={20}
                  alt="home"
                />
                <div className="card-body p-1">
                  <p className="mb-0">Home</p>
                </div>
              </div>
            </Link>
          </div>
          <div className="col-3 px-1 mt-2 ">
            <Link to="/transaction">
              <div
                className={`card border-0 text-center footer-nav ${
                  match.pathname === "/transaction" ? "active" : ""
                }`}
              >
                <img
                  src={
                    match.pathname === "/transaction"
                      ? TransactionActiveIcon
                      : TransactionIcon
                  }
                  className=" mx-auto mb-1"
                  width={20}
                  height={20}
                  alt="transaction"
                />
                <div className="card-body p-1">
                  <p className="mb-0">Transaction</p>
                </div>
              </div>
            </Link>
          </div>
          <div className="col-3 px-1 mt-2">
            <Link to="/myteam">
              <div
                className={`card border-0 text-center footer-nav ${
                  match.pathname === "/myteam" ? "active" : ""
                }`}
              >
                <img
                  src={
                    match.pathname === "/myteam" ? WalletActiveIcon : WalletIcon
                  }
                  className=" mx-auto mb-1"
                  width={20}
                  height={20}
                  alt="myTeamIcon"
                />
                <div className="card-body p-1">
                  <p className="mb-0">My Team</p>
                </div>
              </div>
            </Link>
          </div>
          <div className="col-3 px-1 mt-2">
            <Link to="/profile">
              <div
                className={`card border-0 text-center footer-nav ${
                  match.pathname === "/profile" ? "active" : ""
                }`}
              >
                <img
                  src={
                    match.pathname === "/profile" ? UserActiveIcon : UserIcon
                  }
                  className=" mx-auto mb-1"
                  width={20}
                  height={20}
                  alt="profileIcon"
                />
                <div className="card-body p-1">
                  <p className="mb-0">Mine</p>
                </div>
              </div>
            </Link>
          </div>
        </div>
      </div>
    </footer>
  );
}
export default FooterTab;
