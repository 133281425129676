import Login from "./pages/login";
import Landing from "./pages/landing";
import Register from "./pages/register";
import Home from "./pages/home";
import Transaction from "./pages/transaction";
import HelpSection from "./pages/help";
import Announcements from "./pages/announcements";
import Funds from "./pages/funds";
import Interest from "./pages/interest";
import MyTeam from "./pages/myteam";
import PersonalInformation from "./pages/personal-infromation";
import Profile from "./pages/profile";
import ReferalFriend from "./pages/referal";
import Recharge from "./pages/recharge";
import WithdrawRecharge from "./pages/withdrawrecords";
import TeamIncome from "./pages/team-income";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "react-responsive-modal/styles.css";
import AddAmount from "./pages/depositeaddamount";
import Withdrawal from "./pages/withdrawal";
import Otp from "./pages/otp";
import DepositeModal from "./pages/depositemodal";
import IMPSDepositeModal from "./pages/impsDepositModal";
import EditProfileInformation from "./pages/editprofile";
import LoadingOverlay from "react-loading-overlay";
import { useSelector } from "react-redux";
import ProtectedRoute from "./ProtectedRoute";
import TradePage from "./pages/trade";
import KYCSection from "./pages/kyc";
import { useEffect, useState } from "react";
import { api } from "./api";
import Maintenance from "./pages/maintenance";
import { useLayoutEffect } from "react";
import Rewards from "./pages/rewards";
import AboutUs from "./pages/about";
import TermsnConditions from "./pages/tnC";
import Privacy from "./pages/privacyPolicy";
LoadingOverlay.propTypes = undefined;

function App() {
  const isLoading = useSelector((state) => state.user.loading);
  var userDetails = localStorage.getItem("user");
  const [maintenance, setMaintance] = useState(false);

  useLayoutEffect(() => {
    if (isLoading) {
      document.body.style.overflow = "hidden";
      document.body.style.height = "100%";
    }
    if (!isLoading) {
      document.body.style.overflow = "auto";
      document.body.style.height = "auto";
    }
  }, [isLoading]);

  useEffect(() => {
    const getmaintenance = async () => {
      try {
        const response = await api.get("site/maintenance");
        setMaintance(response.data.maintenance);
      } catch (error) {
        console.log(error.message);
      }
    };
    getmaintenance();
  }, []);

  return (
    <Router>
      <LoadingOverlay
        active={isLoading}
        spinner
        className="MyLoader_content"
        text="Loading..."
      >
        <Routes>
          <Route element={<Maintenance maintenance={maintenance} />}>
            <Route path="/" element={<Landing />} />
            <Route path="register/:referal_code" element={<Register />} />
            <Route path="register/" element={<Register />} />

            <Route path="login" element={<Login />} />
            <Route path="otp" element={<Otp />} />
            <Route path="aboutUs" element={<AboutUs />} />
            <Route path="termsnconditions" element={<TermsnConditions />} />
            <Route path="privacy" element={<Privacy />} />

            <Route element={<ProtectedRoute user={userDetails} />}>
              <Route path="home" element={<Home />} />
              <Route path="transaction" element={<Transaction />} />
              <Route path="interest" element={<Interest />} />
              <Route path="referalfriend" element={<ReferalFriend />} />
              <Route path="recharge" element={<Recharge />} />
              <Route path="teamincome" element={<TeamIncome />} />
              <Route path="profile" element={<Profile />} />
              <Route path="userinfo" element={<PersonalInformation />} />
              <Route path="myteam" element={<MyTeam />} />
              <Route path="funds" element={<Funds />} />
              <Route path="help" element={<HelpSection />} />
              <Route path="announcements" element={<Announcements />} />
              <Route path="addamount" element={<AddAmount />} />
              <Route path="withdrawal" element={<Withdrawal />} />
              <Route path="withdrawalRecords" element={<WithdrawRecharge />} />
              <Route path="qrdeposit" element={<DepositeModal />} />
              <Route path="impsdeposit" element={<IMPSDepositeModal />} />
              <Route path="editprofile" element={<EditProfileInformation />} />
              <Route path="trade" element={<TradePage />} />
              <Route path="kyc" element={<KYCSection />} />
              <Route path="rewards" element={<Rewards />} />
            </Route>
          </Route>
        </Routes>
      </LoadingOverlay>
      <ToastContainer />
    </Router>
  );
}

export default App;
