import LeftIcon from "../../img/angle-left.svg";
import { useNavigate } from "react-router-dom";

function HeaderTitle({ title }) {
  const navigate = useNavigate();

  const handleBack = () => {
    navigate(-1);
  };

  return (
    <div className="py-3 bg-white fixed-top ">
      <div className="container">
        <div className="row align-items-center">
          <div className="col-2" onClick={handleBack}>
            <img src={LeftIcon} height={25} alt="" />
          </div>
          <div className="col-8 text-center">
            <h3 className="mb-0 ">{title}</h3>
          </div>
        </div>
      </div>
    </div>
  );
}
export default HeaderTitle;
