/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import TransactionHashImg from "../../img/transaction_hash.jpeg";
import { toast } from "react-toastify";
import { useLocation, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { addDeposit } from "../../redux/actions";
import HeaderTitle from "../../component/header-title";

function DepositeModal() {
  const dispatch = useDispatch();

  const [mintuesTime, setMinutes] = useState(15);
  const [secondsTime, setSeconds] = useState("00");
  const [transactionId, setTransactionId] = useState("");
  const navigate = useNavigate();
  const { state } = useLocation();
  const allPaymentData = useSelector(
    (state) => state.trasactionReducer.paymentData
  );
  const [paymentData, setPaymentData] = useState();

  const { amount } = state;

  useEffect(() => {
    const isReload = sessionStorage.getItem("loaded") || "false";

    if (isReload === "false") {
      sessionStorage.setItem("loaded", true);
    }

    if (isReload === "true") {
      navigate(-1);
    }
    return () => {
      sessionStorage.setItem("loaded", false);
    };
  }, []);

  const handleCopyClipart = (value) => {
    navigator.clipboard.writeText(value);
    toast.success("Address copied", {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: true,
      closeOnClick: false,
      pauseOnHover: false,
      draggable: false,
      progress: undefined,
    });
  };

  useEffect(() => {
    window.addEventListener("beforeunload", alertUser);
    return () => {
      window.removeEventListener("beforeunload", alertUser);
    };
  }, []);

  const alertUser = (e) => {
    e.preventDefault();
    e.returnValue = "";
  };

  useEffect(() => {
    const filteredData = allPaymentData.filter(
      (item) => item.method === "TRC20"
    );
    setPaymentData(filteredData);
    console.log(filteredData);
  }, []);

  useEffect(() => {
    const myInterval = setInterval(() => {
      if (secondsTime > 0) {
        if (secondsTime < 11) {
          setSeconds((prev) => {
            return "0" + (parseInt(prev) - 1);
          });
        } else {
          setSeconds(secondsTime - 1);
        }
      }
      if (secondsTime === "00") {
        if (mintuesTime === 0) {
          clearInterval(myInterval);
        } else {
          setMinutes(() => mintuesTime - 1);
          setSeconds(59);
        }
      }
    }, 1000);

    return () => clearInterval(myInterval);
  }, [secondsTime, mintuesTime]);

  const handleSubmit = () => {
    if (transactionId.toString().trim() === "") {
      toast.error("transaction Id is required");
      return;
    }
    dispatch(
      addDeposit(
        {
          amount: amount,
          transactionId: transactionId,
          method: "TRC20",
        },
        navigate
      )
    );
  };

  const handleUserInput = (e) => {
    const value = e.target.value;
    setTransactionId(value);
  };

  const getImageURL = (imageUrl) => {
    let imageUrlGet = process.env.REACT_APP_API_ENDPOINT;
    console.log(imageUrlGet + "/" + imageUrl);
    return imageUrlGet + "/" + imageUrl;
  };

  return (
    <>
      <HeaderTitle title={"Deposit"} />
      <div className="text-center mb-3 modal-container mx-3 mt-3 space">
        <div className="text-center  mb-3 first-container">
          <h4 className="mb-0 mx-3  mb-3">Payment QR code (USDT-TRC20)</h4>
          <img
            src={
              paymentData && paymentData.length > 0
                ? getImageURL(paymentData[0].image)
                : ""
            }
            alt="code"
            className="mt-2 w-50"
          />

          <div className="bg-info  mt-3 py-2 mb-2 mx-5  rounded">
            <h4 className="mb-0 mx-3 ">Amount: ${amount.toFixed(3)}</h4>
          </div>

          <div className="bg-info mt-1 py-2 mb-2 mx-5 rounded">
            <h4 className="mb-0 mx-3 ">
              Expiration Time: {mintuesTime}:{secondsTime}
            </h4>
          </div>

          <div className="bg-info mt-1 py-2 mb-2 mx-5 rounded">
            <h4 className="mb-0 mx-3 ">Payment Address:</h4>
            <p className="mb-0  ">
              {paymentData && paymentData.length > 0
                ? paymentData[0].paymentAddress
                : ""}
            </p>
          </div>
          <div className="bg-info mt-1 py-2 mb-2 mx-5 rounded">
            <h4
              className="mb-0 mx-3 "
              onClick={() =>
                handleCopyClipart(
                  paymentData && paymentData.length > 0
                    ? paymentData[0].paymentAddress
                    : ""
                )
              }
            >
              Copy Address
            </h4>
          </div>
          <div className="text-center alert-text my-2 py-4">
            <h3>SUBMIT TRANSACTION ID BELOW AFTER PAYMENT ONLY !!</h3>
          </div>
        </div>

        <div className="first-container  mb-3  my-3">
          <p className="text-danger">Transaction Id (See Examples Below)</p>
          <p>
            <input
              type="text"
              placeholder="Please Input Transaction Id"
              className="form-control text-center"
              onChange={handleUserInput}
              value={transactionId}
            />
          </p>

          <div className="text-center">
            <button
              className="btn btn-sm theme-btn w-50 py-2"
              onClick={handleSubmit}
            >
              Submit
            </button>
          </div>
        </div>

        <div className="first-container my-2">
          <div className="text-center text-danger mb-2">
            !! Transaction Id Example !!
          </div>

          <div>
            <img src={TransactionHashImg} className="w-100" alt="hash id" />
          </div>
        </div>
      </div>
    </>
  );
}

export default DepositeModal;
