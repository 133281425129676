import "./footer.css";
/* eslint-disable react-hooks/exhaustive-deps */
import { Link } from "react-router-dom";
import { SocialIcon } from "react-social-icons";
import FAQ from "../../component/faq";
import Markets from "../../component/markets";
import LandingPage2 from "../../img/landingPage2.png";

const Footer = () => {

  const openInNewTab = (url) => {
    window.open(url, "_blank", "noreferrer");
  };

  return (

    <>
      <div className=" bg-white" style={{ paddingBottom: "0.4rem" }}>
        <FAQ id="faq" />
        <div
          className="d-flex column justify-content-between mx-auto"
          style={{ width: "90%" }}
        >
          <div className="d-inline-flex row w-50 ">
            <div
              className="d-flex fw-bold fs-5 my-2 justify-content-start"
              style={{ color: "#2563eb" }}
            >
              LEGAL
            </div>
            <div className="d-flex justify-content-start">
              <ul
                style={{
                  listStyle: "none",
                  textAlign: "left",
                  color: "#9ca3af",
                  paddingInlineStart: "0",
                }}
              >
                <li className="mb-2 fs-6">
                  <Link style={{ color: "inherit" }} to={"/privacy"} reloadDocument>Privacy Policy</Link>
                </li>
                <li className="mb-2 fs-6">
                  <Link style={{ color: "inherit" }} reloadDocument to={"/termsnconditions"}>
                    Tearms&nbsp;&&nbsp;conditions
                  </Link>
                </li>
              </ul>
            </div>
          </div>
          <div className="w-50">
            <div
              className="fw-bold fs-5 my-2 d-flex justify-content-end"
              style={{ color: "#2563eb" }}
            >
              USEFUL LINKS
            </div>
            <div className="d-flex justify-content-end">
              <ul
                style={{
                  listStyle: "none",
                  textAlign: "left",
                  color: "#9ca3af",
                  paddingInlineStart: "0",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "end",
                }}
              >
                <li className="mb-2 fs-6">
                  <Link reloadDocument to={"/"} style={{ color: "inherit" }}>
                    Home
                  </Link>
                </li>

                <li className="mb-2 fs-6">
                  <Link
                    reloadDocument
                    to={"/aboutUs"}
                    style={{ color: "inherit" }}
                  >
                    About Us
                  </Link>
                </li>
                <li className="mb-2 fs-6">
                  <Link
                    to="https://tawk.to/chat/65322408f2439e1631e658e1/1hd5tppha"
                    target="_blank"
                    style={{ color: "inherit" }}
                  >
                    Contact Us
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div
          className="d-flex justify-content-center"
          style={{ gap: "0.75rem" }}
        >
          <SocialIcon
            url="www.facebook.com"
            as="div"
            onClick={() => openInNewTab("https://www.facebook.com/Metafoxbot")}
          />
          <SocialIcon
            url="www.instagram.com"
            as="div"
            onClick={() => openInNewTab("https://instagram.com/metafoxbot")}
          />
          <SocialIcon
            url="www.x.com"
            as="div"
            onClick={() => openInNewTab("https://x.com/Metafoxbot")}
          />
          <SocialIcon
            url="www.youtube.com"
            as="div"
            onClick={() => openInNewTab("https://youtube.com/@metafoxbot")}
          />
          <SocialIcon
            url="www.telegram.com"
            as="div"
            onClick={() =>
              openInNewTab("https://telegram.me/metafoxbot_announcements")
            }
          />
        </div>
      </div>
      <div className=" font-small blue pt-4">
        <div className="text-center py-3 text-black ">
          © 2023 Copyright <span className="domain">metafoxbot.com</span>
        </div>
      </div>
    </>
  );
}

export default Footer;
