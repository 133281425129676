/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable jsx-a11y/no-distracting-elements */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { Carousel } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import FooterTab from "../../component/footer-tab";
import Header from "../../component/header";
import Banner1 from "../../img/Banner1.png";
import Banner2 from "../../img/Banner2.png";
import Banner3 from "../../img/B3.png";
import CustomerIcon from "../../img/customer.png";
import HelpIcon from "../../img/help.png";
import RewardIcon from "../../img/rewards.png";
import HomeLogo from "../../img/home.png";
import UserIcon from "../../img/user.png";
import BitLogo from "../../img/bit-logo.png";
import EthLogo from "../../img/eth-logo.png";
import DogeLogo from "../../img/doge.png";
import UsdtLogo from "../../img/usdt-logo-1.png";
import BnbLogo from "../../img/bnb_logo.png";
import {
  getAnnouncements,
  getCoinDetailsData,
  getTransactionData,
} from "../../redux/actions";
import TawkTo from "tawkto-react";
import "./home.css";
function Home(props) {
  var tawk = new TawkTo("65322408f2439e1631e658e1", "1hd5tppha");
  const [showWidget, setShowWedget] = useState(false);

  useEffect(() => {
    tawk.onStatusChange((status) => {
      if (showWidget === false) {
        tawk.hideWidget();
      }
    });
    if (showWidget === false) {
      tawk.hideWidget();
    }
  }, []);

  const handleCustomerSupport = () => {
    setShowWedget(!showWidget);
    tawk.hideWidget();
    tawk.toggle();
  };

  const transactionDetails = useSelector((state) => state.trasactionReducer);
  const navigate = useNavigate();

  const getRandomValue14 = () => {
    var val = Math.floor(1000000000000 + Math.random() * 9000000000000);
    return val;
  };

  const getRandomValue4 = () => {
    var val = Math.floor(1000 + Math.random() * 9000);
    return val;
  };
  const dispatch = useDispatch();
  const bannerData = [{ url: Banner1 }, { url: Banner2 }, { url: Banner3 }];
  const [seletedTab, changeSelectedTab] = useState("Hot");
  const onOpenModal = (symbol, minimumPayment, percentage, days) => {
    navigate("/trade", {
      state: {
        symbol: symbol,
        minimum: minimumPayment,
        percentage: percentage,
        days: days,
      },
    });
  };

  const selectTab = (tabName) => {
    changeSelectedTab(tabName);
  };

  const [announcements, setAnnouncements] = useState([]);

  useEffect(() => {
    dispatch(getTransactionData());
    dispatch(getCoinDetailsData());
    dispatch(getAnnouncements(setAnnouncements));
  }, []);

  const handleUserNameHide = (text) => {
    var t = text.substr(0, 2);
    var y = text.substr(1, text.length - 2);
    var k = text.substr(text.length - 2, text.length - 1);
    var z = y.replace(y, "*****");
    return t + z + k;
  };

  const UsdtEthPercentage = (day) => {
    switch (day) {
      case "1":
        return "50%";
      case "7":
        return "65%";
      case "14":
        return "80%";
      case "30":
        return "55%";
      default:
        return "45%";
    }
  };
  const BnbEthPercentage = (day) => {
    switch (day) {
      case "1":
        return "65%";
      case "7":
        return "75%";
      case "14":
        return "80%";
      case "30":
        return "68%";
      default:
        return "60%";
    }
  };

  const BtcPercentage = (day) => {
    switch (day) {
      case "1":
        return "80%";
      case "7":
        return "83%";
      case "14":
        return "85%";
      case "30":
        return "90%";
      default:
        return "87%";
    }
  };
  return (
    <div>
      <Header />
      <section className="pb-2  space">
        <div className="container position-relative ">
          <Carousel indicators={false}>
            {bannerData.map((item, index) => (
              <Carousel.Item interval={5000} key={index} controls={false}>
                <img
                  className="d-block w-100 rounded service-box"
                  src={item.url}
                  alt="First slide"
                />
              </Carousel.Item>
            ))}
          </Carousel>
        </div>
      </section>
      <div className="container my-2">
        <section className=" d-flex align-items-center p-1">
          <div className="marquee">
            <div>
              <span>{announcements[0]?.Body}</span>
              <span>{announcements[0]?.Body}</span>
            </div>
          </div>
          <div className="px-2">
            <button
              className="theme-btn btn-sm px-4 rounded-pill"
              onClick={() => navigate("/announcements")}
            >
              Notice{" "}
            </button>
          </div>
        </section>
      </div>
      <div className="container">
        <section className="service-box bg-white  p-3 my-3">
          <div className="overflow-hidden">
            <div className="d-flex overflow-scroll pb-3">
              <a
                onClick={() => selectTab("Hot")}
                id="Hot"
                className={
                  seletedTab === "Hot"
                    ? "btn btn-sm theme-btn rounded-pill me-3"
                    : "btn btn-sm btn-light rounded-pill me-3"
                }
              >
                Hot
              </a>
              <a
                onClick={() => selectTab("DOGE")}
                id="DOGE"
                className={
                  seletedTab === "DOGE"
                    ? "btn btn-sm theme-btn  rounded-pill me-3"
                    : "btn btn-sm btn-light rounded-pill me-3 "
                }
              >
                DOGE
              </a>
              <a
                onClick={() => selectTab("USDT")}
                id="USDT"
                className={
                  seletedTab === "USDT"
                    ? "btn btn-sm theme-btn  rounded-pill me-3"
                    : "btn btn-sm btn-light rounded-pill me-3 "
                }
              >
                USDT
              </a>
              <a
                onClick={() => selectTab("BNB")}
                id="BNB"
                className={
                  seletedTab === "BNB"
                    ? "btn btn-sm theme-btn  rounded-pill me-3"
                    : "btn btn-sm btn-light rounded-pill me-3 "
                }
              >
                BNB
              </a>
              <a
                onClick={() => selectTab("ETH")}
                id="ETH"
                className={
                  seletedTab === "ETH"
                    ? "btn btn-sm theme-btn  rounded-pill me-3"
                    : "btn btn-sm btn-light rounded-pill me-3 "
                }
              >
                ETH
              </a>
              <a
                onClick={() => selectTab("BTC")}
                id="BTC"
                className={
                  seletedTab === "BTC"
                    ? "btn btn-sm theme-btn  rounded-pill me-3"
                    : "btn btn-sm btn-light rounded-pill me-3 "
                }
              >
                BTC
              </a>
            </div>
          </div>
        </section>
        {seletedTab === "Hot" && (
          <section>
            <div>
              {Array.isArray(transactionDetails.coinDetails) &&
                transactionDetails.coinDetails
                  .filter(
                    (transaction) =>
                      (transaction.type === "Btc" ||
                        transaction.type === "Eth") &&
                      transaction.days === "30"
                  )
                  .map((el, index) => (
                    <div
                      key={index}
                      className="service-box p-3 my-3  bg-white shadow-sm rounded mb-3"
                    >
                      <div className="row mb-3 mx-0">
                        <div className="logo-text">
                          <img
                            src={el.type === "Eth" ? EthLogo : BitLogo}
                            className="img-fluid img-class"
                            alt=""
                          />{" "}
                          {el.type}
                        </div>
                      </div>
                      <div className="row mt-2">
                        <div className="col-4 px-3">
                          <h3 className="text-muted">{el.percentage}%</h3>
                          <p className="text-muted">Expected Profit</p>
                        </div>
                        <div className="col-4 px-3">
                          <h3>
                            {el.days}
                            <small> days</small>
                          </h3>
                          <p className="text-muted">Trust Period</p>
                        </div>
                        <div className="col-4 px-3">
                          <button
                            className="theme-btn btn-sm px-4 rounded-pill"
                            onClick={() =>
                              onOpenModal(
                                el.type.toUpperCase(),
                                el.miniMumPayment,
                                el.percentage,
                                el.days
                              )
                            }
                          >
                            Trade
                          </button>
                        </div>
                      </div>
                      <div className="row align-items-center">
                        <div className="col-7">
                          <div className="progress">
                            <div
                              className="progress-bar theme-bg"
                              role="progressbar"
                              style={{ width: UsdtEthPercentage(el.days) }}
                              aria-valuenow={5}
                              aria-valuemin={0}
                              aria-valuemax={100}
                            />
                          </div>
                          <p className="mb-0 mt-2 text-muted">
                            24x7 always online
                          </p>
                        </div>
                        <div className="col-5 text-end">
                          <p className="mb-0 text-muted">
                            0{getRandomValue14()}/{getRandomValue4()}
                            <br />0 USDT
                          </p>
                        </div>
                      </div>
                    </div>
                  ))}
            </div>
          </section>
        )}
        {/* USDT */}
        {seletedTab === "USDT" && (
          <section className="">
            <div>
              {transactionDetails.coinDetails
                .filter((o) => o.type === "Usdt")
                .map((el, index) => (
                  <div
                    key={index}
                    className="service-box p-3 my-3 bg-white shadow-sm rounded mb-3"
                  >
                    <div className="row mb-3 mx-0">
                      <div className="logo-text">
                        <img
                          src={UsdtLogo}
                          className="img-fluid img-class"
                          alt=""
                        />{" "}
                        USDT
                      </div>
                    </div>
                    <div className="row mt-2">
                      <div className="col-4 px-3">
                        <h3 className="text-muted">{el.percentage}%</h3>
                        <p className="text-muted">Expected Profit</p>
                      </div>
                      <div className="col-4 px-3">
                        <h3>
                          {el.days}
                          <small> days</small>
                        </h3>
                        <p className="text-muted">Trust Period</p>
                      </div>
                      <div className="col-4 px-3">
                        <button
                          className="theme-btn btn-sm px-4 rounded-pill"
                          onClick={() =>
                            onOpenModal(
                              seletedTab,
                              el.miniMumPayment,
                              el.percentage,
                              el.days
                            )
                          }
                        >
                          Trade
                        </button>
                      </div>
                    </div>
                    <div className="row align-items-center">
                      <div className="col-7">
                        <div className="progress">
                          <div
                            className="progress-bar theme-bg"
                            role="progressbar"
                            style={{ width: UsdtEthPercentage(el.days) }}
                            aria-valuenow={5}
                            aria-valuemin={0}
                            aria-valuemax={100}
                          />
                        </div>
                        <p className="mb-0 mt-2 text-muted">
                          24x7 always online
                        </p>
                      </div>
                      <div className="col-5 text-end">
                        <p className="mb-0 text-muted">
                          0{getRandomValue14()}/{getRandomValue4()}
                          <br />0 USDT
                        </p>
                      </div>
                    </div>
                  </div>
                ))}
            </div>
          </section>
        )}

        {/* ETH */}
        {seletedTab === "ETH" && (
          <section className="">
            <div>
              {transactionDetails.coinDetails
                .filter((o) => o.type === "Eth")
                .map((el, index) => (
                  <div
                    key={index}
                    className="service-box p-3 my-3  bg-white shadow-sm rounded mb-3"
                  >
                    <div className="row mb-3 mx-0">
                      <div className="logo-text">
                        <img
                          src={EthLogo}
                          className="img-fluid img-class"
                          alt=""
                        />{" "}
                        ETH
                      </div>
                    </div>
                    <div className="row mt-2">
                      <div className="col-4 px-3">
                        <h3 className="text-muted">{el.percentage}%</h3>
                        <p className="text-muted">Expected Profit</p>
                      </div>
                      <div className="col-4 px-3">
                        <h3>
                          {el.days}
                          <small> days</small>
                        </h3>
                        <p className="text-muted">Trust Period</p>
                      </div>
                      <div className="col-4 px-3">
                        <button
                          className="theme-btn btn-sm px-4 rounded-pill"
                          onClick={() =>
                            onOpenModal(
                              seletedTab,
                              el.miniMumPayment,
                              el.percentage,
                              el.days
                            )
                          }
                        >
                          Trade
                        </button>
                      </div>
                    </div>
                    <div className="row align-items-center">
                      <div className="col-7">
                        <div className="progress">
                          <div
                            className="progress-bar theme-bg"
                            role="progressbar"
                            style={{ width: BnbEthPercentage(el.days) }}
                            aria-valuenow={5}
                            aria-valuemin={0}
                            aria-valuemax={100}
                          />
                        </div>
                        <p className="mb-0 mt-2 text-muted">
                          24x7 always online
                        </p>
                      </div>
                      <div className="col-5 text-end">
                        <p className="mb-0 text-muted">
                          0{getRandomValue14()}/{getRandomValue4()}
                          <br />0 USDT
                        </p>
                      </div>
                    </div>
                  </div>
                ))}
            </div>
          </section>
        )}

        {/* BTC */}
        {seletedTab === "BTC" && (
          <section className="">
            <div>
              {transactionDetails.coinDetails
                .filter((o) => o.type === "Btc")
                .map((el, index) => (
                  <div
                    key={index}
                    className="service-box p-3 my-3  bg-white shadow-sm rounded mb-3"
                  >
                    <div className="row mb-3 mx-0">
                      <div className="logo-text">
                        <img
                          src={BitLogo}
                          className="img-fluid img-class"
                          alt=""
                        />{" "}
                        BTC
                      </div>
                    </div>
                    <div className="row mt-2">
                      <div className="col-4 px-3">
                        <h3 className="text-muted">{el.percentage}%</h3>
                        <p className="text-muted">Expected Profit</p>
                      </div>
                      <div className="col-4 px-3">
                        <h3>
                          {el.days}
                          <small> days</small>
                        </h3>
                        <p className="text-muted">Trust Period</p>
                      </div>
                      <div className="col-4 px-3">
                        <button
                          className="theme-btn btn-sm px-4 rounded-pill"
                          onClick={() =>
                            onOpenModal(
                              seletedTab,
                              el.miniMumPayment,
                              el.percentage,
                              el.days
                            )
                          }
                        >
                          Trade
                        </button>
                      </div>
                    </div>
                    <div className="row align-items-center">
                      <div className="col-7">
                        <div className="progress">
                          <div
                            className="progress-bar theme-bg"
                            role="progressbar"
                            style={{ width: BtcPercentage(el.days) }}
                            aria-valuenow={5}
                            aria-valuemin={0}
                            aria-valuemax={100}
                          />
                        </div>
                        <p className="mb-0 mt-2 text-muted">
                          24x7 always online
                        </p>
                      </div>
                      <div className="col-5 text-end">
                        <p className="mb-0 text-muted">
                          0{getRandomValue14()}/{getRandomValue4()}
                          <br />0 USDT
                        </p>
                      </div>
                    </div>
                  </div>
                ))}
            </div>
          </section>
        )}

        {/* DOGE */}
        {seletedTab === "DOGE" && (
          <section className="">
            <div>
              {transactionDetails.coinDetails
                .filter((o) => o.type === "Doge")
                .map((el, index) => (
                  <div
                    key={index}
                    className="service-box p-3 my-3  bg-white shadow-sm rounded mb-3"
                  >
                    <div className="row mb-3 mx-0">
                      <div className="logo-text">
                        <img
                          src={DogeLogo}
                          className="img-fluid img-class mr-2"
                          alt=""
                        />{" "}
                        DOGE
                      </div>
                    </div>
                    <div className="row mt-2">
                      <div className="col-4 px-3">
                        <h3 className="text-muted">{el.percentage}%</h3>
                        <p className="text-muted">Expected Profit</p>
                      </div>
                      <div className="col-4 px-3">
                        <h3>
                          {el.days}
                          <small> days</small>
                        </h3>
                        <p className="text-muted">Trust Period</p>
                      </div>
                      <div className="col-4 px-3">
                        <button
                          className="theme-btn btn-sm px-4 rounded-pill"
                          onClick={() =>
                            onOpenModal(
                              seletedTab,
                              el.miniMumPayment,
                              el.percentage,
                              el.days
                            )
                          }
                        >
                          Trade
                        </button>
                      </div>
                    </div>
                    <div className="row align-items-center">
                      <div className="col-7">
                        <div className="progress">
                          <div
                            className="progress-bar theme-bg"
                            role="progressbar"
                            style={{ width: UsdtEthPercentage(el.days) }}
                            aria-valuenow={5}
                            aria-valuemin={0}
                            aria-valuemax={100}
                          />
                        </div>
                        <p className="mb-0 mt-2 text-muted">
                          24x7 always online
                        </p>
                      </div>
                      <div className="col-5 text-end">
                        <p className="mb-0 text-muted">
                          0{getRandomValue14()}/{getRandomValue4()}
                          <br />0 USDT
                        </p>
                      </div>
                    </div>
                  </div>
                ))}
            </div>
          </section>
        )}
        {/* BNB */}
        {seletedTab === "BNB" && (
          <section className="">
            <div>
              {transactionDetails.coinDetails
                .filter((o) => o.type === "Bnb")
                .map((el, index) => (
                  <div
                    key={index}
                    className="service-box p-3 my-3  bg-white shadow-sm rounded mb-3"
                  >
                    <div className="row mb-3 mx-0">
                      <div className="logo-text">
                        <img
                          src={BnbLogo}
                          className="img-fluid img-class mr-2"
                          alt=""
                        />{" "}
                        BNB
                      </div>
                    </div>
                    <div className="row mt-2">
                      <div className="col-4 px-3">
                        <h3 className="text-muted">{el.percentage}%</h3>
                        <p className="text-muted">Expected Profit</p>
                      </div>
                      <div className="col-4 px-3">
                        <h3>
                          {el.days}
                          <small> days</small>
                        </h3>
                        <p className="text-muted">Trust Period</p>
                      </div>
                      <div className="col-4 px-3">
                        <button
                          className="theme-btn btn-sm px-4 rounded-pill"
                          onClick={() =>
                            onOpenModal(
                              seletedTab,
                              el.miniMumPayment,
                              el.percentage,
                              el.days
                            )
                          }
                        >
                          Trade
                        </button>
                      </div>
                    </div>
                    <div className="row align-items-center">
                      <div className="col-7">
                        <div className="progress">
                          <div
                            className="progress-bar theme-bg"
                            role="progressbar"
                            style={{ width: BnbEthPercentage(el.days) }}
                            aria-valuenow={5}
                            aria-valuemin={0}
                            aria-valuemax={100}
                          />
                        </div>
                        <p className="mb-0 mt-2 text-muted">
                          24x7 always online
                        </p>
                      </div>
                      <div className="col-5 text-end">
                        <p className="mb-0 text-muted">
                          0{getRandomValue14()}/{getRandomValue4()}
                          <br />0 USDT
                        </p>
                      </div>
                    </div>
                  </div>
                ))}
            </div>
          </section>
        )}
      </div>
      <section className="service mb-3">
        <div className="container">
          <div className="row mx-0 py-3 px-2 bg-white service-box">
            <div className="col-3 px-0 my-2">
              <a
                href="https://telegram.me/metafoxbot_announcements"
                target="_blank"
                className="text-muted"
              >
                <div className="card border-0 text-center">
                  <img
                    src={HomeLogo}
                    className="img-fluid mx-auto mb-1"
                    width={50}
                    alt="official"
                  />
                  <div className="card-body p-1">
                    <h4 className="mb-0">Telegram</h4>
                  </div>
                </div>
              </a>
            </div>

            <div className="col-3 px-0 my-2">
              <Link to="/help" className="text-muted">
                <div className="card border-0 text-center">
                  <img
                    src={HelpIcon}
                    className="img-fluid mx-auto mb-1"
                    width={50}
                    alt="official"
                  />
                  <div className="card-body p-1">
                    <h4 className="mb-0">Help Center</h4>
                  </div>
                </div>
              </Link>
            </div>

            <div className="col-3 px-0 my-2">
              <a onClick={handleCustomerSupport} className="text-muted">
                <div className="card border-0 text-center">
                  <img
                    src={CustomerIcon}
                    className="img-fluid mx-auto mb-1"
                    width={50}
                    alt="official"
                  />
                  <div className="card-body p-1">
                    <h4 className="mb-0">Customer Service</h4>
                  </div>
                </div>
              </a>
            </div>

            <div className="col-3 px-0 my-2">
              <Link to="/rewards" className="text-muted">
                <div className="card border-0 text-center">
                  <img
                    src={RewardIcon}
                    className="img-fluid mx-auto mb-1"
                    width={50}
                    alt="official"
                  />
                  <div className="card-body p-1">
                    <h4 className="mb-0">Rewards</h4>
                  </div>
                </div>
              </Link>
            </div>
          </div>
        </div>
      </section>
      <section className="pb-2">
        <div className="container">
          <div className="mx-0 px-3 bg-white service-box2 service-box ">
            <div className="bg-white">
              <h5 className="m-0 py-3">Transaction data</h5>
              <hr className="m-0" />
            </div>
            <marquee
              className="px-1 pl-3"
              direction="up"
              style={{ height: "30vh" }}
            >
              {Array.isArray(transactionDetails.transactionData) &&
                transactionDetails.transactionData.map((ele, index) => {
                  return (
                    <div key={index}>
                      <div className="row my-4">
                        <div className="col-6">
                          <div className="media d-flex align-items-center">
                            <img
                              src={UserIcon}
                              className="img-fluid"
                              width={30}
                              alt=""
                            />
                            <div className="media-body pl-3">
                              <h6 className="mb-0">
                                {handleUserNameHide(ele.userName)}
                              </h6>
                            </div>
                          </div>
                        </div>
                        <div className="col-6">
                          <h3 className="mb-0">Profit: {ele.profit}$</h3>
                          <p className="mb-0">
                            Transaction amount:{ele.investmentAmount}$
                          </p>
                        </div>
                      </div>
                      <hr />
                    </div>
                  );
                })}
            </marquee>
          </div>
        </div>
      </section>
      <FooterTab to="/home" />
    </div>
  );
}
export default Home;
