/* eslint-disable react-hooks/exhaustive-deps */
import "./style.css";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import WalletIcon from "../../img/Wallet.svg";
import HeaderTitle from "../../component/header-title";
import DepositeModal from "../depositemodal";
import { Modal } from "react-responsive-modal";
import { useLocation, useNavigate } from "react-router-dom";
import BitLogo from "../../img/bit-logo.png";
import EthLogo from "../../img/eth-logo.png";
import DogeLogo from "../../img/doge.png";
import UsdtLogo from "../../img/usdt-logo-1.png";
import BnbLogo from "../../img/bnb_logo.png";
import { useSelector, useDispatch } from "react-redux";
import { addTrade } from "../../redux/actions";

function TradePage(props) {
  const navigate = useNavigate();
  const [amount, setAmount] = useState(0);
  const [open, setOpen] = useState(false);
  const location = useLocation();
  const dispatch = useDispatch();
  const userDetails = useSelector((state) => state.user.userData);
  const [buttonPressed, setButtonPressed] = useState(false);
  const isTradeSuccess = useSelector(
    (state) => state.trasactionReducer.isTradeSuccess
  );

  const onCloseModal = () => setOpen(false);

  const handleAddamount = (value, percentage) => {
    setAmount((prev) => prev + value);
  };

  useEffect(() => {
    if (isTradeSuccess === true) {
      navigate("/transaction");
    }
  }, [isTradeSuccess]);

  const handleSubmit = () => {
    setButtonPressed(true);
    var isMinimumBalance = location.state.minimum.replace(
      /[|&;$%@"<>()+,]/g,
      ""
    );
    if (amount >= parseFloat(isMinimumBalance)) {
      let data = {
        emailId: userDetails.emailId,
        coinstype: location.state.symbol,
        days: parseInt(location.state.days),
        per: parseFloat(location.state.percentage),
        amount: parseFloat(amount),
      };

      dispatch(addTrade(data));
    } else {
      toast.warn(`Add ${location.state.minimum} minimum amount`, {
        position: "top-right",
        autoClose: 1000,
        hideProgressBar: true,
        closeOnClick: false,
        pauseOnHover: false,
        draggable: false,
        progress: undefined,
      });
    }
  };

  const handleAllAmount = () => {
    setAmount(Math.floor(userDetails?.availableBalance * 10000) / 10000);
  };

  const handleUserInput = (e) => {
    const value = e.target.value;
    if (/^\d*\.?\d{0,4}$/.test(value)) {
      setAmount(value);
    }
  };

  return (
    <div className="bg-white heightDiv">
      <HeaderTitle title={"Trade"} />
      <div className="mx-3 mt-3 service-box p-3">
        <div className="d-flex mb-2">
          {location.state && (
            <>
              {location.state.symbol === "BTC" && (
                <img
                  src={BitLogo}
                  className="img-fluid img-class-trade"
                  alt=""
                />
              )}
              {location.state.symbol === "DOGE" && (
                <img
                  src={DogeLogo}
                  className="img-fluid img-class-trade"
                  alt=""
                />
              )}
              {location.state.symbol === "BNB" && (
                <img
                  src={BnbLogo}
                  className="img-fluid img-class-trade"
                  alt=""
                />
              )}
              {location.state.symbol === "ETH" && (
                <img
                  src={EthLogo}
                  className="img-fluid img-class-trade"
                  alt=""
                />
              )}
              {location.state.symbol === "USDT" && (
                <img
                  src={UsdtLogo}
                  className="img-fluid img-class-trade"
                  alt=""
                />
              )}
              <h4 className="trade_label">{location.state.symbol}</h4>
            </>
          )}
        </div>
        <div className="d-flex align-items-center">
          <img src={WalletIcon} width="20px" alt="" />
          <div className="d-flex align-items-center mt-2">
            <h4 className="mx-2 mb-2  text-muted">Available Balance:</h4>
            <h4>${userDetails?.availableBalance.toFixed(3)}</h4>
          </div>
        </div>
        <p>Enter Amount</p>
        <div className="form-inline Ptag pb-3">
          <input
            type="number"
            step="0.0001"
            onChange={handleUserInput}
            className="form-control"
            value={amount}
          />
          <div className="text-end">
            <button
              className="p-0 btn-sm btn text-primary rightText"
              onClick={handleAllAmount}
            >
              ALL
            </button>
          </div>
        </div>
        <h4 className="trade_label fs-7">
          Minimun Amount:{location?.state?.minimum}
        </h4>
        <div className="my-3 mb-3 d-flex">
          <p
            className="btn btn-sm btn-outline-light rounded-pill me-3 active"
            onClick={() => handleAddamount(100)}
          >
            +$100
          </p>
          <p
            className="btn btn-sm btn-outline-light rounded-pill me-3 active"
            onClick={() => handleAddamount(500)}
          >
            +$500
          </p>
          <p
            className="btn btn-sm btn-outline-light rounded-pill me-3 active"
            onClick={() => handleAddamount(1000)}
          >
            +$1000
          </p>
          <p
            className="btn btn-sm btn-outline-light rounded-pill me-3 active"
            onClick={() => handleAddamount(5000)}
          >
            +$5000
          </p>
          <p
            className="btn btn-sm btn-outline-light rounded-pill me-3 active"
            onClick={() => handleAddamount(10000)}
          >
            +$10000
          </p>
        </div>

        <div className="text-center">
          <button
            className="btn btn-sm theme-btn w-50 py-2"
            disabled={buttonPressed}
            onClick={handleSubmit}
          >
            Submit
          </button>
        </div>
      </div>
      <Modal open={open} onClose={onCloseModal} center>
        <DepositeModal />
      </Modal>
    </div>
  );
}

export default TradePage;
