
export const GET_PRODUCTS_DATA_LIST_BEGIN = 'GET_PRODUCTS_DATA_LIST_BEGIN';
export const GET_PRODUCTS_DATA_LIST_SUCCESS = 'GET_PRODUCTS_DATA_LIST_SUCCESS';
export const GET_PRODUCTS_DATA_LIST_FAILED = 'GET_PRODUCTS_DATA_LIST_FAILED';

export const GET_SORT_PRODUCTS_BEGIN = 'GET_SORT_PRODUCTS_BEGIN';
export const GET_SORT_PRODUCTS_SUCCESS = 'GET_SORT_PRODUCTS_SUCCESS';
export const GET_SORT_PRODUCTS_FAILED = 'GET_SORT_PRODUCTS_FAILED';


export const REGISTER_USER_BEGIN = 'REGISTER_USER_BEGIN'
export const REGISTER_USER_SUCCESS = 'REGISTER_USER_SUCCESS'
export const REGISTER_USER_FAILED = 'REGISTER_USER_FAILED'

export const GET_USER_BEGIN = 'GET_USER_BEGIN'
export const GET_USER_SUCCESS = 'GET_USER_SUCCESS'
export const GET_USER_FAILED = 'GET_USER_FAILED'

export const GET_USER_TEAMS_SUCCESS = 'GET_USER_TEAMS_SUCCESS'
export const GET_USER_TEAMS_FAILED = 'GET_USER_TEAMS_FAILED'



export const IS_LOADING = 'IS_LOADING';
export const IS_TRANSACTION_LOADING = 'IS_TRANSACTION_LOADING';
export const SIGNIN_SUCCESS = 'SIGNIN_SUCCESS';
export const SIGNIN_FAILED = 'SIGNIN_FAILED';

export const SIGNUP_SUCCESS = 'SIGNUP_SUCCESS';
export const SIGNUP_FAILED = 'SIGNUP_FAILED';



export const UPDATE_USER_SUCCESS = 'UPDATE_USER_SUCCESS';
export const UPDATE_USER_FAILED = 'UPDATE_USER_FAILED';


export const SEND_VERIFICATION_SUCCESS = 'SEND_VERIFICATION_SUCCESS'
export const SEND_VERIFICATION_FAILED = 'SEND_VERIFICATION_FAILED'

export const CHANGE_PASSWORD_SUCCESS = 'CHANGE_PASSWORD_SUCCESS'
export const CHANGE_PASSWORD_FAILED = 'CHANGE_PASSWORD_FAILED'

export const GET_TRANSACTION_SUCCESS = 'GET_TRANSACTION_SUCCESS'
export const GET_TRANSACTION_FAILED = 'GET_TRANSACTION_FAILED'

export const DELETE_TRANSACTION_SUCCESS = 'DELETE_TRANSACTION_SUCCESS'
export const DELETE_TRANSACTION_FAILED = 'DELETE_TRANSACTION_FAILED'

export const ADD_WITHDRAW_SUCCESS = 'ADD_WITHDRAW_SUCCESS'
export const ADD_WITHDRAW_FAILED = 'ADD_WITHDRAW_FAILED'


export const UPDATE_WITHDRAWAL_SUCCESS = 'UPDATE_WITHDRAWAL_SUCCESS'
export const UPDATE_WITHDRAWAL_FAILED = 'UPDATE_WITHDRAWAL_FAILED'


export const DELETE_WITHDRAWAL_SUCCESS = 'DELETE_WITHDRAWAL_SUCCESS'
export const DELETE_WITHDRAWAL_FAILED = 'DELETE_WITHDRAWAL_FAILED'

export const ADD_DEPOSIT_SUCCESS = 'ADD_DEPOSIT_SUCCESS'
export const ADD_DEPOSIT_FAILED = 'ADD_DEPOSIT_FAILED'

export const GET_DEPOSIT_SUCCESS = 'GET_DEPOSIT_SUCCESS'
export const GET_DEPOSIT_FAILED = 'GET_DEPOSIT_FAILED'

export const GET_FUNDDETAILS_SUCCESS = 'GET_FUNDDETAILS_SUCCESS'
export const GET_FUNDDETAILS_FAILED = 'GET_FUNDDETAILS_FAILED'

export const GET_WITHDRAWAL_SUCCESS = 'GET_WITHDRAWAL_SUCCESS'
export const GET_WITHDRAWAL_FAILED = 'GET_WITHDRAWAL_FAILED'

export const GET_TRANSACTION_DATA = 'GET_TRANSACTION_DATA'
export const GET_TRANSACTION_DATA_FAILED = 'GET_TRANSACTION_DATA_FAILED'

export const GET_TRANSACTION_HISTORY_DATA_SUCCESS = 'GET_TRANSACTION_HISTORY_DATA_SUCCESS'
export const GET_TRANSACTION_DATA_HISTORY_FAILED = 'GET_TRANSACTION_DATA_HISTORY_FAILED'


export const GET_TRADE_DATA_SUCCESS = 'GET_TRADE_DATA_SUCCESS'
export const GET_TRADE_DATA_FAILED = 'GET_TRADE_DATA_FAILED'

export const IS_TRADE_SUCCESS = 'IS_TRADE_SUCCESS'

export const IS_WITHDRAWAL_SUCCESS = 'IS_WITHDRAWAL_SUCCESS'

export const GET_COINDETAILS_DATA = 'GET_COINDETAILS_DATA'
export const GET_COINDETAILS_DATA_FAILED = 'GET_COINDETAILS_DATA_FAILED'

export const GET_PAYMENTDETAILS_DATA = 'GET_PAYMENTDETAILS_DATA'
export const GET_PAYMENTDETAILS_DATA_FAILED = 'GET_PAYMENTDETAILS_DATA_FAILED'

export const GET_FUNDSDETAILS_DATA = 'GET_FUNDSDETAILS_DATA'
export const GET_FUNDSDETAILS_DATA_FAILED = 'GET_FUNDSDETAILS_DATA_FAILED'

export const GET_KYC_DATA = 'GET_KYC_DATA'
export const GET_KYC_DATA_FAILED = 'GET_KYC_DATA_FAILED'

export const GET_KYC_DATA_UPLOAD = 'GET_KYC_DATA_UPLOAD'
export const GET_KYC_DATA_UPLOAD_FAILED = 'GET_KYC_DATA_FAILED'

export const USER_LOGOUT  = 'USER_LOGOUT';
