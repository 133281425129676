import React from "react";
import Nav from "../../component/nav";
import Footer from "../../component/footer";

const TermsnConditions = () => {
  const scrollToFAQ = () => {
    const faqSection = document.getElementById("faq");
    if (faqSection) {
      faqSection.scrollIntoView({ behavior: "smooth" });
    }
  };
  return (
    <>
      <Nav scrollToFAQ={scrollToFAQ} />
      <div style={{ textAlign: "justify" }} className="d-flex bg-white flex-column align-items-center  justify-content-center px-4">
        <div className="fs-1 fw-bold pb-2" style={{ marginTop: "80px" }}>
          TERMS AND CONDITIONS
        </div>
        <div className="fw-bold">
          Welcome to MetaFoxBot - Your Gateway to Seamless Crypto Staking!
        </div>
        <div>
          By accessing and using our website and services, you agree to comply with and be bound by the following terms and conditions. Please read these terms carefully before using MetaFoxBot.
        </div>
        <div className="fw-bold pt-3">1. Acceptance of Terms:</div>
        <div>
          By using MetaFoxBot, you acknowledge that you have read, understood, and agree to these Terms & Conditions. If you do not agree with any part of these terms, please do not use our services.
        </div>
        <div className="fw-bold pt-2">2. Description of Services: </div>
        <div>
          MetaFoxBot provides crypto staking services with an artificial intelligence robot, allowing users to stake their crypto assets without loss.
        </div>
        <div className="fw-bold pt-2">3. User Accounts:  </div>
        <div>
          To access certain features of MetaFoxBot, you may be required to create an account. You are responsible for maintaining the confidentiality of your account information and for all activities that occur under your account.
        </div>
        <div className="fw-bold pt-2">4. Privacy: </div>
        <div>
          Our Privacy Policy outlines how we collect, use, and protect your personal information.By using MetaFoxBot, you consent to the practices described in the Privacy Policy.
        </div>
        <div className="fw-bold pt-2">5. Prohibited Activities: </div>
        <div>
          <span>You agree not to engage in any activities that may:</span>
          <ul className="text-start">
            <li>Violate any applicable laws or regulations.</li>
            <li>Infringe on the rights of others.</li>
            <li>Disrupt or interfere with the functionality of MetaFoxBot.</li>
            <li>Attempt to gain unauthorized access to our systems or data.</li>
          </ul>
        </div>
        <div className="fw-bold pt-2">6. Intellectual Property: </div>
        <div>
          All content and materials on MetaFoxBot, including logos, text, graphics, and software, are the property of MetaFoxBot and are protected by intellectual property laws. You may not reproduce, distribute, or use any content without our express written permission.
        </div>
        <div className="fw-bold pt-3">7. Disclaimer of Warranties: </div>
        <div>
          MetaFoxBot is provided "as is" and "as available" without any warranties, expressed or implied.
        </div>
        <div className="fw-bold pt-3">8. Limitation of Liability </div>
        <div>
          MetaFoxBot and its affiliates shall not be liable for any direct, indirect, incidental, special, or consequential damages resulting from the use or inability to use our services.
        </div>
        <div className="fw-bold pt-2">9. Changes to Terms & Conditions: </div>
        <div>
          MetaFoxBot reserves the right to update and modify these Terms & Conditions. Any changes will be posted on this page, and the date of the last update will be revised accordingly.
        </div>
        <div className="pt-2" style={{ textAlign: "justify" }}>
          Thank you for choosing MetaFoxBot. If you have any questions or concerns about these terms, please contact us at [Support@Metafoxbot.com].
        </div>
      </div >
      <Footer />
    </>
  );
};

export default TermsnConditions;
