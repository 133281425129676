import { useNavigate } from "react-router-dom";
import Logo from "../../img/logo_new.png";
import './header.css';

function Header() {
  const navigate = useNavigate();
  return (
    <header>
      <div className=" fixed-top bg-body rounded-2 pl-5 z-3 nav-style">
        <div className="d-flex flex-row align-items-center justify-content-between">
          <div
            className="logo logoo"
            onClick={() => navigate("/")}
            style={{ cursor: "pointer" }}
          >
            <img
              src={Logo}
              className="img-fluid"
              alt="logo"
              style={{ paddingBottom: "1.4rem" }}
            />
          </div>
        </div>
      </div>
    </header>
  );
}
export default Header;
