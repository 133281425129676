/* eslint-disable react-hooks/exhaustive-deps */
import { useState } from "react";
import HeaderTitle from "../../component/header-title";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { isEmpty } from "lodash";
import { Link, useNavigate } from "react-router-dom";
import { Formik } from "formik";
import * as yup from "yup";
import { addWithdraw } from "../../redux/actions";

function Withdrawal() {
  const userDetails = JSON.parse(localStorage.getItem("user"));
  const [trcError, setTrcError] = useState(false);
  const [impsError, setImpsError] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const ValidationSchema = yup.object().shape({
    amount: yup.string().required("Amount is required"),
    actualCredit: yup.string().required("Actual Credit is required"),
    pin: yup
      .string()
      .max(6)
      .min(6, ({ min }) => `Pin must be at least ${min} characters`)
      .required("Create pin is required"),
    selectedOption: yup.string().required("Withdrawal Method is Required"),
  });

  const handleFormSubmit = (values) => {
    if (parseInt(values.amount) >= 20 && parseFloat(values.amount) <= 1000) {
      let data = {
        transactionAddress:
          values.selectedOption === "IMPS"
            ? JSON.stringify(values.impsAddress)
            : values.withdrawalAddress,
        amount: values.amount,
        method: values.selectedOption,
        actualAmount: values.amount - (values.amount * 3) / 100,
        pin: values.pin,
        emailId: userDetails.emailId,
      };
      console.log(data);
      dispatch(addWithdraw(data, navigate));
    } else {
      toast.warn(
        `${
          parseInt(values.amount) < 20
            ? "Add $20 minimum amount"
            : " Maximum One Time Withrawal Limit Is 1000$"
        }`,
        {
          position: "top-right",
          autoClose: 1000,
          hideProgressBar: true,
          closeOnClick: false,
          pauseOnHover: false,
          draggable: false,
          progress: undefined,
        }
      );
    }
  };

  // useEffect(() => {
  //   if (withdrawalSuccess) {
  //     naviagte(-1);
  //   }
  // }, [withdrawalSuccess]);

  return (
    <div className="h-100 pb-5">
      <HeaderTitle title={"Transfer"} />
      <Formik
        validationSchema={ValidationSchema}
        initialValues={{
          withdrawalAddress:
            userDetails && userDetails?.transactionAddress
              ? userDetails?.transactionAddress
              : "",
          impsAddress:
            userDetails && userDetails?.impsAddress
              ? userDetails?.impsAddress
              : "",
          amount: "",
          actualCredit: "",
          pin: "",
          selectedOption: "",
        }}
        onSubmit={(values) => {
          handleFormSubmit(values);
        }}
      >
        {({
          handleChange,
          handleBlur,
          handleSubmit,
          values,
          errors,
          isValid,
          dirty,
          setValues,
          isSubmitting,
        }) => (
          <div className="pt-3 px-3 bg-white space">
            <div className="input-box">
              <label htmlFor="mobile_country" className="fs-6 font-weight-500">
                TRC-20 Address
              </label>
              <div className="row align-items-center">
                <div className="col-12">
                  {!isEmpty(values.withdrawalAddress) && (
                    <input
                      type="text"
                      placeholder="Enter TRC-20 Address"
                      disabled
                      className="form-input custom_input w-100"
                      value={values.withdrawalAddress}
                      onChange={handleChange("withdrawalAddress")}
                    />
                  )}
                  {isEmpty(values.withdrawalAddress) && (
                    <Link to="/editprofile">
                      <div className="card-body p-1 mt-2">
                        <h4 className="mb-0">
                          Click here to add Withdrawal Address
                        </h4>
                      </div>
                    </Link>
                  )}
                </div>
              </div>
              {trcError && (
                <div className="mt-1">
                  <span fontSize={10} style={{ color: "red" }}>
                    Please add your Withdrawal Address to withdraw money through
                    TRC20
                  </span>
                </div>
              )}
            </div>
            <div className=" input-box">
              <label
                htmlFor="mobile_country"
                className="fs-6 font-weight-normal"
              >
                IMPS Details :
              </label>
              <div className="row align-items-center">
                <div className="col-12">
                  {!isEmpty(values.impsAddress) && (
                    <>
                      <div>
                        <label className="d-inline mx-2 fs-6">
                          Bank Account No:{" "}
                        </label>
                        <input
                          type="text"
                          placeholder="bank Account No"
                          disabled
                          className="form-input custom_input d-inline font-weight-normal"
                          value={values.impsAddress.bankAccountNo}
                        />
                      </div>

                      <div>
                        <label className="d-inline mx-2 fs-6">
                          Bank IFSC Code:{" "}
                        </label>
                        <input
                          type="text"
                          placeholder="bank IFSC Code"
                          disabled
                          className="form-input custom_input d-inline font-weight-normal"
                          value={values.impsAddress.bankIFSC}
                        />
                      </div>

                      <div>
                        <label className="d-inline mx-2 fs-6">
                          Account Holder Name:{" "}
                        </label>
                        <input
                          type="text"
                          placeholder="Account Holder Name"
                          disabled
                          className="form-input custom_input d-inline font-weight-normal"
                          value={values.impsAddress.nameInBank}
                        />
                      </div>
                    </>
                  )}
                  {isEmpty(values.impsAddress) && (
                    <Link to="/editprofile">
                      <div className="card-body p-1 mt-2">
                        <h4 className="mb-0">Click here to add IMPS details</h4>
                      </div>
                    </Link>
                  )}
                </div>
              </div>
              {impsError && (
                <div>
                  <span fontSize={10} style={{ color: "red" }}>
                    Please add your IMPS details to withdraw money through IMPS
                  </span>
                </div>
              )}
            </div>
            <div className=" input-box">
              <label htmlFor="mobile_country" className="fs-6 font-weight-500">
                Available Balance: ${userDetails?.availableBalance.toFixed(3)}
              </label>
              <div className="row align-items-center">
                <div className="col-12">
                  <input
                    onChange={(e) => {
                      values.amount = e.target.value;
                      values.actualCredit =
                        e.target.value - (e.target.value * 3) / 100;
                      values.actualCredit =
                        values.selectedOption === "IMPS"
                          ? values.actualCredit * 88
                          : values.actualCredit;
                      setValues(values);
                    }}
                    type="tel"
                    placeholder="Enter transfer amount"
                    value={values.amount}
                    className="form-input custom_input w-100"
                  />
                </div>
                {!dirty && errors.amount && (
                  <div>
                    <span fontSize={10} style={{ color: "red" }}>
                      {" "}
                      {errors.amount}
                    </span>
                  </div>
                )}
              </div>
            </div>

            <div className=" input-box">
              <label htmlFor="mobile_country" className="fs-6 font-weight-500">
                Actual Credit(Fee rate: 3.0%)
              </label>
              <div className="row align-items-center">
                <div className="col-12 d-flex align-items-center ">
                  {values.selectedOption === "IMPS" ? "₹" : "$"}
                  <input
                    disabled={true}
                    type="text"
                    placeholder="0"
                    value={values.actualCredit}
                    className=" custom_input pb-1 px-1 w-100"
                  />
                </div>
              </div>
            </div>
            <div className=" input-box">
              <label htmlFor="mobile_country" className="fs-6 font-weight-500">
                PIN
              </label>
              <div className="row align-items-center">
                <div className="col-12 d-flex align-items-center justify-content-between">
                  <input
                    type="tel"
                    placeholder=" Enter  6 digit pin"
                    className="form-input custom_input w-100"
                    value={values.pin}
                    maxLength="6"
                    onChange={handleChange("pin")}
                  />
                </div>
              </div>
              {!dirty && errors.pin && (
                <div>
                  <span fontSize={10} style={{ color: "red" }}>
                    {" "}
                    {errors.pin}
                  </span>
                </div>
              )}
            </div>
            <div className="d-flex flex-row">
              <p className="fs-6">Withdrawal Method : </p>

              <label className="mx-4 fs-6">
                <input
                  type="radio"
                  value="TRC20"
                  checked={values.selectedOption === "TRC20"}
                  onChange={(e) => {
                    values.selectedOption = e.target.value;
                    values.actualCredit =
                      values.amount - (values.amount * 3) / 100;
                    values.actualCredit =
                      values.selectedOption === "IMPS"
                        ? values.actualCredit * 88
                        : values.actualCredit;
                    setImpsError(false);
                    setValues(values);
                    if (isEmpty(values.withdrawalAddress)) {
                      setTrcError(true);
                    }
                  }}
                  className="mx-2"
                />
                TRC20
              </label>

              <label className="mx-4 fs-6">
                <input
                  className="mx-2"
                  type="radio"
                  value="IMPS"
                  checked={values.selectedOption === "IMPS"}
                  onChange={(e) => {
                    values.selectedOption = e.target.value;
                    values.actualCredit =
                      values.amount - (values.amount * 3) / 100;
                    values.actualCredit =
                      values.selectedOption === "IMPS"
                        ? values.actualCredit * 88
                        : values.actualCredit;
                    setTrcError(false);
                    setValues(values);
                    if (isEmpty(values.impsAddress)) {
                      setImpsError(true);
                    }
                  }}
                />
                IMPS
              </label>
              <br />
            </div>
            <button
              disabled={!isValid || trcError || impsError}
              type="submit"
              className="theme-btn btn w-100 mt-3"
              onClick={handleSubmit}
            >
              Submit
            </button>
            <div className="text-center"></div>
          </div>
        )}
      </Formik>
    </div>
  );
}
export default Withdrawal;
