import { useState, useRef } from "react";
import "./custom_styles.css";

const faqs = [
  {
    id: 1,
    header: "What Is Metafoxbot ?",
    text: `Metafoxbot Is  Artificially Intelligent Robot Ever Developed By Traders . Metafoxbot is a digital marketplace that enable users to trade cryptocurrency like USDT, BITCOIN, Ethereum Etc without any risk.
        `,
  },
  {
    id: 2,
    header: "What Is Our Mission?",
    text: `Our Mission Is To Change The Entire Cryptocurrency Market. Our Mission Is To Provide You A Loss Free Guaranteed Profit To Our Users.`,
  },
  {
    id: 3,
    header: "How To Earn From Metafoxbot ?",
    text: `In Metafoxbot Our User Can Earn In Variable Ways. We Offer Our Users Three Level Multi Level Marketing . Sleep & Earn While Your Downline Members Are Working For You.`,
  },
  {
    id: 4,
    header: "Why We Are Best ?",
    text: `We Are Best In The World Of Cryptocurrency Market Because We Provide Guaranteed Profit To Our User First Time In The World.`,
  },
  {
    id: 5,
    header: " How To Trade On Metafoxbot ?",
    text: `
        Trade On Metafoxbot Is Easy Than Anything. Just Simply Deposit Fund In Your Wallet, Choose Your Preferred Coins And Stake Days, Click On Trade & Its Done`,
  },
];

const AccordionItem = (props) => {
  const contentEl = useRef();
  const { handleToggle, active, faq } = props;
  const { header, id, text } = faq;

  return (
    <div className="rc-accordion-card">
      <div className="rc-accordion-header">
        <div
          className={`rc-accordion-toggle p-3 ${active === id ? "active" : ""}`}
          onClick={() => handleToggle(id)}
        >
          <h5 className="rc-accordion-title">{header}</h5>
          <i className="fa fa-chevron-down rc-accordion-icon"></i>
        </div>
      </div>
      <div
        ref={contentEl}
        className={`rc-collapse ${active === id ? "show" : ""}`}
        style={
          active === id
            ? { height: contentEl.current.scrollHeight }
            : { height: "0px" }
        }
      >
        <div className="rc-accordion-body">
          <p className="mb-0">{text}</p>
        </div>
      </div>
    </div>
  );
};

const FAQ = () => {
  const [active, setActive] = useState(null);

  const handleToggle = (index) => {
    if (active === index) {
      setActive(null);
    } else {
      setActive(index);
    }
  };

  return (
    <>
      <div className="container-fluid mt-5 mb-5" id="faq">
        <div className="row justify-content-center">
          <div className="col-md-8 mt-2">
            <div className="carrd card" style={{ border: "none" }}>
              <div className="card-body">
                <h4 className="form-heading mb-4 bold text-center mt-3">
                  Frequently Asked Questions
                </h4>
                {faqs.map((faq, index) => {
                  return (
                    <AccordionItem
                      key={index}
                      active={active}
                      handleToggle={handleToggle}
                      faq={faq}
                    />
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default FAQ;
