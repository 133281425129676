/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import HeaderTitle from "../../component/header-title";
import { useDispatch } from "react-redux";
import { getAnnouncements } from "../../redux/actions";
import { Accordion } from "react-bootstrap";

function Announcement() {
  const dispatch = useDispatch();
  const [announcements, setAnnouncements] = useState([]);
  useEffect(() => {
    dispatch(getAnnouncements(setAnnouncements));
  }, []);

  return (
    <div>
      <HeaderTitle title={"Announcements"} />
      <div className="bg-white pl-3 py-3 px-3 space">
        <Accordion alwaysOpen>
          {announcements.map((announcement, index) => (
            <Accordion.Item
              key={announcement._id}
              eventKey={index}
              style={{ margin: "2rem", borderTop: "1px solid #D9D9D9" }}
            >
              <Accordion.Header>
                <div className="ps-3"> {announcement.Title} </div>
              </Accordion.Header>
              <Accordion.Body>
                <div
                  style={{
                    whiteSpace: "pre-line",
                  }}
                >
                  {announcement.Body}
                </div>
              </Accordion.Body>
            </Accordion.Item>
          ))}
        </Accordion>
      </div>
    </div>
  );
}

export default Announcement;
