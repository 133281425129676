import HeaderTitle from '../../component/header-title';
import Buy from '../buy';
import { Modal } from 'react-responsive-modal';
import React, { useState } from 'react';


function Interest() {

    const [open, setOpen] = useState(false);

    const onOpenModal = () => setOpen(true);
    const onCloseModal = () => setOpen(false);
    return (
        <div>
            <HeaderTitle />

            <section className="py-2 px-3">
                <div className>
                    {[1, 2, 3, 4, 5, 6].map((_,index) => (<div key={index} className="interest-box p-3 bg-white shadow-sm rounded mb-3">
                        <div className="row mb-3 mx-0">
                            <img src="img/usdt-logo.png" className="img-fluid" alt="" />
                        </div>
                        <div className="row mt-2">
                            <div className="col-4 px-3">
                                <h3 className="text-muted">1%</h3>
                                <p className="text-muted">Expected Profit</p>
                            </div>
                            <div className="col-4 px-3">
                                <h3>1 <small>days</small></h3>
                                <p className="text-muted">Trust Period</p>
                            </div>
                            <div className="col-4 px-3">
                                <button  className="theme-btn btn-sm px-4 rounded-pill" onClick={onOpenModal}>Buy</button>
                            </div>
                        </div>
                        <div className="row align-items-center">
                            <div className="col-7">
                                <div className="progress">
                                    <div className="progress-bar theme-bg" role="progressbar" style={{ width: '5%' }} aria-valuenow={5} aria-valuemin={0} aria-valuemax={100} />
                                </div>
                                <p className="mb-0 mt-2 text-muted">24x7 always online</p>
                            </div>
                            <div className="col-5 text-end">
                                <p className="mb-0 text-muted">0205585000002/5000<br />0 USDT</p>
                            </div>
                        </div>
                    </div>))}
                </div>
            </section>
            <Modal open={open} onClose={onCloseModal} center >
                <Buy/>
            </Modal>

        </div>
    )

}

export default Interest;