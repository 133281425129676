/* eslint-disable react-hooks/exhaustive-deps */
import HeaderTitle from "../../component/header-title";
import { useDispatch, useSelector } from "react-redux";
import { getDeposit, getFunds } from "../../redux/actions";
import { getWithdraw } from "../../redux/actions";
import { useEffect, useState } from "react";
import downArrow from "../../img/down_arrow.jpg";
import moment from "moment";

function Funds() {
  const compareDates = (a, b) => {
    return moment(a.date) - moment(b.date);
  };
  const dispatch = useDispatch();
  const depositeRecords = useSelector(
    (state) => state.trasactionReducer.depositeRecords
  );
  const withdrawRecords = useSelector(
    (state) => state.trasactionReducer.withdrawRecords
  );
  const userDetails = JSON.parse(localStorage.getItem("user"));

  const otherRecords = useSelector(
    (state) => state.trasactionReducer.otherRecords
  );
  const fundRecords = userDetails?.fund;
  fundRecords.sort(compareDates);
  const [showReachargeRecords, setShowReachargeRecords] = useState(true);
  const [showWithdrawalRecords, setShowWithdrawalRecords] = useState(true);
  const [showOtherRecords, setShowOtherRecords] = useState(true);
  const [showMiscRecords, setShowMiscRecords] = useState(true);

  useEffect(() => {
    dispatch(getDeposit());
    dispatch(getWithdraw());
    dispatch(getFunds());
  }, []);

  return (
    <div>
      <HeaderTitle title={"Details of Funds"} />
      <div className="info-content p-3 contant space">
        <div className="info-box bg-white rounded p-2 my-bx-shadow mb-3">
          <div className="row">
            <div className="col-3">
              <p className="mb-0 fw-bold fs-6">Time</p>
            </div>
            <div className="col-2">
              <p className="mb-0 fw-bold fs-6">Amount</p>
            </div>
            <div className="col-3">
              <p className="mb-0 ps-2 fw-bold fs-6 d-flex justify-content-end text-right">
                Status
              </p>
            </div>
            <div className="col-4">
              <p className="mb-0 fw-bold fs-6 d-flex justify-content-end">
                Remark
              </p>
            </div>
          </div>
        </div>

        <div
          className="py-3 bg-white rounded shadow-sm"
          onClick={() => setShowReachargeRecords(!showReachargeRecords)}
        >
          <div className="container">
            <div className="row align-items-center">
              <div className="text-center">
                <h6 className="mb-0">
                  Recharge Records{" "}
                  <img
                    src={downArrow}
                    alt=""
                    width={10}
                    height={10}
                    style={{
                      transform: showReachargeRecords ? "rotate(180deg)" : "",
                      transition: "transform 150ms ease",
                    }}
                  />
                </h6>
              </div>
            </div>
          </div>
        </div>
        <div className="bg-white mb-2">
          {Array.isArray(depositeRecords) &&
            showReachargeRecords &&
            depositeRecords.map((ele, index) => (
              <div className="row py-2 mt-4" key={index}>
                <div className="col-3">
                  <p className="mb-0">
                    {moment(`${ele.addedAt}`).format("DD/MM/YYYY hh:mm")}
                  </p>
                </div>
                <div className="col-3">
                  <p className="mb-0">
                    <span style={{ fontWeight: 800, color: "green" }}>+</span>$
                    {ele.amount.toFixed(4)}
                  </p>
                </div>
                <div className="col-3">
                  <p className="mb-0 d-flex justify-content-end">
                    {ele.status}
                  </p>
                </div>
                <div className="col-3">
                  <p className="mb-0 d-flex justify-content-end text-end">
                    {ele.remark}
                  </p>
                </div>
              </div>
            ))}
        </div>
        <hr />
        <div
          className="py-3 bg-white shadow-sm"
          onClick={() => setShowWithdrawalRecords(!showWithdrawalRecords)}
        >
          <div className="container">
            <div className="row align-items-center">
              <div className="text-center">
                <h6 className="mb-0 ">
                  Withdrawal Records{" "}
                  <img
                    src={downArrow}
                    alt=""
                    width={10}
                    height={10}
                    style={{
                      transform: showWithdrawalRecords ? "rotate(180deg)" : "",
                      transition: "transform 150ms ease",
                    }}
                  />
                </h6>
              </div>
            </div>
          </div>
        </div>
        <div className="bg-white">
          {Array.isArray(withdrawRecords) &&
            showWithdrawalRecords &&
            withdrawRecords.map((ele, index) => (
              <div className="row py-2 mt-4" key={index}>
                <div className="col-3">
                  <p className="mb-0">
                    {moment(`${ele.addedAt}`).format("DD/MM/YYYY hh:mm")}
                  </p>
                </div>
                <div className="col-3">
                  <p className="mb-0">
                    <span style={{ fontWeight: 800, color: "red" }}>-</span>$
                    {ele.actualAmount.toFixed(4)}
                  </p>
                </div>
                <div className="col-3">
                  <p className="mb-0 d-flex justify-content-end">
                    {ele.status}
                  </p>
                </div>
                <div className="col-3">
                  <p className="mb-0 d-flex justify-content-end text-end">
                    {ele.remark}
                  </p>
                </div>
              </div>
            ))}
        </div>
        <hr />
        <div
          className="py-3 bg-white shadow-sm"
          onClick={() => setShowOtherRecords(!showOtherRecords)}
        >
          <div className="container">
            <div className="row align-items-center">
              <div className="text-center">
                <h6 className="mb-0 ">
                  Other Records{" "}
                  <img
                    src={downArrow}
                    alt=""
                    width={10}
                    height={10}
                    style={{
                      transform: showOtherRecords ? "rotate(180deg)" : "",
                      transition: "transform 150ms ease",
                    }}
                  />
                </h6>
              </div>
            </div>
          </div>
        </div>
        <div className="bg-white">
          {Array.isArray(fundRecords) &&
            showOtherRecords &&
            fundRecords?.length > 0 &&
            fundRecords?.reverse().map((ele, index) => (
              <div className="p-2 row mt-4" key={index}>
                <div className="col-3">
                  <p className="mb-0">
                    {moment(`${ele?.date}`).format("DD/MM/YYYY hh:mm")}
                  </p>
                </div>
                <div className="col-3">
                  <p className="mb-0">
                    <span style={{ fontWeight: 800, color: "green" }}>+</span>$
                    {ele?.amount.toFixed(4)}
                  </p>
                </div>
                <div className="col-3">
                  <p className="mb-0 d-flex justify-content-end">
                    {ele?.status}
                  </p>
                </div>
                <div className="col-3">
                  <p className="mb-0 d-flex justify-content-end text-end">
                    {ele?.remark}
                  </p>
                </div>
              </div>
            ))}
        </div>
        <hr />
        <div
          className="py-3 bg-white shadow-sm"
          onClick={() => setShowMiscRecords(!showMiscRecords)}
        >
          <div className="container">
            <div className="row align-items-center">
              <div className="text-center">
                <h6 className="mb-0 ">
                  Miscellaneous Records{" "}
                  <img
                    src={downArrow}
                    alt=""
                    width={10}
                    height={10}
                    style={{
                      transform: showMiscRecords ? "rotate(180deg)" : "",
                      transition: "transform 150ms ease",
                    }}
                  />
                </h6>
              </div>
            </div>
          </div>
        </div>
        <div className="bg-white">
          {Array.isArray(otherRecords) &&
            showMiscRecords &&
            otherRecords.map((ele, index) => (
              <div className="py-2 row mt-4" key={index}>
                <div className="col-3">
                  <p className="mb-0">
                    {moment(`${ele.addedAt}`).format("DD/MM/YYYY hh:mm")}
                  </p>
                </div>
                <div className="col-3">
                  <p className="mb-0">
                    <span
                      style={{
                        fontWeight: 800,
                        color: ele.fundDetail === "Debit" ? "red" : "green",
                      }}
                    >
                      {ele.fundDetail === "Debit" ? "-" : "+"}
                    </span>
                    ${ele.amount.toFixed(4)}
                  </p>
                </div>
                <div className="col-3">
                  <p className="mb-0 d-flex justify-content-end">
                    {ele.fundDetail}
                  </p>
                </div>
                <div className="col-3">
                  <p className="mb-0 d-flex justify-content-end text-end">
                    {ele.remark}
                  </p>
                </div>
              </div>
            ))}
        </div>

        <hr />
      </div>
    </div>
  );
}
export default Funds;
