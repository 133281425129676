import React, { useRef, useState } from "react";
import HeaderTitle from "../../component/header-title";
import InfoIcon from "../../img/info.png";
import "./style.css";
import { Formik } from "formik";
import * as yup from "yup";
import { CountryData } from "../../constantData";
import ImagLogo from "../../img/uploadexample.jpg";
import ImagBackLogo from "../../img/uploadexampleBack.jpg";
import ReviewImg from "../../img/review.png";
import SelfiImg from "../../img/selfImg.jpg";
import ThumbImg from "../../img/thumbs-up.png";
import { useSelector, useDispatch } from "react-redux";
import { updateKycDocs, uploadKycDocs } from "../../redux/actions";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

function KYCSection() {
  const inputFile = useRef(null);
  const inputFileBack = useRef(null);
  const mobileInputFileSelfi = useRef(null);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [identityUploaded, setIdentityUploaded] = useState("");
  const [identityBackUploaded, setIdentityBackUploaded] = useState("");
  const [selfieUploaded, setSelfieUploaded] = useState("");
  const [buttonPressed, setButtonPressed] = useState(false);

  const userDetails = useSelector((state) => state.user.userData);

  const loginValidationSchema = yup.object().shape({
    country: yup.string().ensure().required("Country is Required"),
    username: yup.string().required("Name is required"),
    idNumber: yup.string().required(),
    file: yup.string().required("Upload Docs is required"),
    fileBack: yup.string().required("Upload Docs is required"),
    SelfiFile: yup.string().required("Selfie  is required"),
  });

  const handleUserKyc = async (values) => {
    try {
      setButtonPressed(true);
      let data = {
        nameOnIdCard: values.username,
        idNo: values.idNumber,
        country: values.country,
        kycUpdated: new Date(),
        approveStatus: "Pending",
      };
      let formData = new FormData();
      formData.append("file", values.file);
      formData.append("file", values.fileBack);
      formData.append("file", values.SelfiFile);
      dispatch(uploadKycDocs(formData));
      dispatch(updateKycDocs(data));
      toast.success("Kyc under review");
    } catch (error) {
      console.log(error);
    } finally {
      navigate("/profile");
    }
  };

  // console.log(userDetails.status,"  ", userDetails.approveStatus);
  if (
    userDetails &&
    userDetails.status === "Completed" &&
    userDetails.approveStatus === "Pending"
  ) {
    return (
      <div className="contant">
        <HeaderTitle title={"Kyc verification"} />

        <div className="d-flex align-items-center msg-title px-3 pt-2 contant flex-column justify-content-center">
          <img src={ReviewImg} alt="infoIcon" width="50" />
          <div className="mt-3">
            <p className="text-muted mx-3 mb-4">
              Your Kyc has been under review. Please wait 12-24 hours for Kyc
              update
            </p>
          </div>
        </div>
      </div>
    );
  }
  if (
    userDetails &&
    userDetails.status === "Processing" &&
    userDetails.approveStatus === "Pending"
  ) {
    return (
      <div className="contant">
        <HeaderTitle title={"Kyc verification"} />

        <div className="d-flex align-items-center msg-title px-3 pt-2 contant flex-column justify-content-center">
          <img src={ReviewImg} alt="infoIcon" width="50" />
          <div className="mt-3">
            <p className="text-muted mx-3 mb-4">
              Your Kyc has been under review. Please wait 12-24 hours for Kyc
              update
            </p>
          </div>
        </div>
      </div>
    );
  }
  if (
    userDetails &&
    userDetails.status === "Completed" &&
    userDetails.approveStatus === "Approved"
  ) {
    return (
      <div className="contant">
        <HeaderTitle title={"Kyc verification"} />

        <div className="d-flex align-items-center msg-title px-3 pt-2 contant flex-column justify-content-center">
          <img src={ThumbImg} alt="infoIcon" width="50" />
          <div className="mt-3">
            <p className="text-muted mx-3 mb-4">
              Your Kyc has been Approved successfully
            </p>
          </div>
        </div>
      </div>
    );
  }
  if (userDetails && userDetails.status === "Pending") {
    return (
      <div>
        <HeaderTitle title={"Kyc verification"} />
        <div className="d-flex align-items-start msg-title px-3 pt-2">
          <img src={InfoIcon} alt="infoIcon" width="16" />
          {userDetails && userDetails.approveStatus === "Reject" ? (
            <p className="text-muted mx-3 mb-4">
              Your KYC is rejected, please uplaod the valid documents.
            </p>
          ) : (
            <div>
              <p className="text-muted mx-3 mb-4">
                To strengthen the security of your account, please supplement
                your identity information.
              </p>
            </div>
          )}
        </div>
        <div className="bg-white pl-3 pt-3  px-3 h-100 pb-3">
          <Formik
            validationSchema={loginValidationSchema}
            initialValues={{
              country: "",
              username: "",
              idNumber: "",
              file: "",
              fileBack: "",
              SelfiFile: "",
            }}
            onSubmit={(values) => {
              handleUserKyc(values);
            }}
          >
            {({
              handleChange,
              handleBlur,
              handleSubmit,
              values,
              errors,
              isValid,
              dirty,
              setFieldValue,
            }) => (
              <>
                <form className="needs-validation pb-3" noValidate>
                  <div className="input-cont">
                    <div className="input-log">
                      <label
                        htmlFor="mobile_country"
                        className="fs-6 font-weight-500"
                      >
                        Select Country
                      </label>
                      <div className="row align-items-center">
                        <div className="col-12 pt-2">
                          <select
                            className="form-select form-control w-100"
                            required
                            id="mobile_country"
                            onChange={(e) => {
                              setButtonPressed(false);
                              handleChange("country")(e.target.value);
                            }}
                          >
                            {CountryData.map((ele, index) => (
                              <option key={index} value={ele.name}>
                                {ele.name}
                              </option>
                            ))}
                          </select>
                        </div>
                      </div>
                    </div>

                    {errors.country && (
                      <div>
                        <span fontSize={10} style={{ color: "red" }}>
                          {" "}
                          {errors.country}
                        </span>
                      </div>
                    )}
                  </div>

                  <div className="input-cont-dis">
                    <div className="input-log">
                      <label
                        htmlFor="mobile_country"
                        className="fs-6 font-weight-500"
                      >
                        Name of Id Card
                      </label>
                      <div className="row align-items-center">
                        <div className="col-12">
                          <input
                            required
                            type="text"
                            placeholder="Enter Name"
                            className="form-control w-100"
                            value={values.username}
                            onChange={handleChange("username")}
                          />
                        </div>
                      </div>
                    </div>
                    {errors.username && (
                      <div>
                        <span fontSize={10} style={{ color: "red" }}>
                          {" "}
                          {errors.username}
                        </span>
                      </div>
                    )}
                  </div>

                  <div className="input-cont-dis">
                    <div className="input-log">
                      <label
                        htmlFor="mobile_country"
                        className="fs-6 font-weight-500"
                      >
                        Id Number
                      </label>
                      <div className="row align-items-center">
                        <div className="col-12">
                          <input
                            required
                            type="text"
                            placeholder="Enter Id number"
                            className="form-control w-100"
                            value={values.idNumber}
                            onChange={handleChange("idNumber")}
                          />
                        </div>
                      </div>
                    </div>
                    {errors.idNumber && (
                      <div>
                        <span fontSize={10} style={{ color: "red" }}>
                          {" "}
                          {errors.idNumber}
                        </span>
                      </div>
                    )}
                  </div>
                  {!identityUploaded && (
                    <div className="upload-dis">
                      <div className="">
                        <label
                          htmlFor="mobile_country"
                          className="fs-6 font-weight-500"
                        >
                          Upload Identity Document(Front)
                        </label>
                        <div className="row align-items-center">
                          <div className="col-12">
                            <input
                              accept=".jpg, .jpeg, .png, .gif"
                              name="file"
                              type="file"
                              required
                              ref={inputFile}
                              style={{ display: "none" }}
                              onChange={(event) => {
                                setFieldValue(
                                  "file",
                                  event.currentTarget.files[0]
                                );
                                if (
                                  event.target.files &&
                                  event.target.files[0]
                                ) {
                                  setIdentityUploaded(
                                    URL.createObjectURL(event.target.files[0])
                                  );
                                }
                              }}
                            />
                            <img
                              src={ImagLogo}
                              className="w-100"
                              onClick={() => inputFile.current.click()}
                              alt=""
                            />
                          </div>
                        </div>
                      </div>
                      {errors.file && (
                        <div>
                          <span fontSize={10} style={{ color: "red" }}>
                            {" "}
                            {errors.file}
                          </span>
                        </div>
                      )}
                    </div>
                  )}

                  {identityUploaded && (
                    <div>
                      <div className="row align-items-center">
                        <div className="col-12">
                          <img
                            className="w-100"
                            src={identityUploaded}
                            alt=""
                          />
                        </div>
                      </div>
                      <div
                        style={{
                          borderBottomWidth: 1,
                          borderBottomColor: "black",
                        }}
                        onClick={() => {
                          setFieldValue("file", "");
                          setIdentityUploaded("");
                        }}
                        className="text-align-start"
                      >
                        Remove
                      </div>
                    </div>
                  )}

                  {!identityBackUploaded && (
                    <div className="upload-dis">
                      <div className="">
                        <label
                          htmlFor="mobile_country"
                          className="fs-6 font-weight-500"
                        >
                          Upload Identity Document(Back)
                        </label>
                        <div className="row align-items-center">
                          <div className="col-12">
                            <input
                              accept=".jpg, .jpeg, .png, .gif"
                              required
                              name="file"
                              type="file"
                              ref={inputFileBack}
                              style={{ display: "none" }}
                              onChange={(event) => {
                                setFieldValue(
                                  "fileBack",
                                  event.currentTarget.files[0]
                                );
                                if (
                                  event.target.files &&
                                  event.target.files[0]
                                ) {
                                  setIdentityBackUploaded(
                                    URL.createObjectURL(event.target.files[0])
                                  );
                                }
                              }}
                            />
                            <img
                              src={ImagBackLogo}
                              className="w-100"
                              onClick={() => inputFileBack.current.click()}
                              alt=""
                            />
                          </div>
                        </div>
                      </div>
                      {errors.file && (
                        <div>
                          <span fontSize={10} style={{ color: "red" }}>
                            {" "}
                            {errors.fileBack}
                          </span>
                        </div>
                      )}
                    </div>
                  )}
                  {identityBackUploaded && (
                    <div>
                      <div className="row align-items-center">
                        <div className="col-12">
                          <img
                            className="w-100"
                            src={identityBackUploaded}
                            alt=""
                          />
                        </div>
                      </div>
                      <div
                        style={{
                          borderBottomWidth: 1,
                          borderBottomColor: "black",
                        }}
                        onClick={() => {
                          setFieldValue("file", "");
                          setIdentityBackUploaded("");
                        }}
                        className="text-align-start"
                      >
                        Remove
                      </div>
                    </div>
                  )}
                  {!selfieUploaded && (
                    <div className="upload-dis">
                      <div className="">
                        <label
                          htmlFor="icon-button-file"
                          className="fs-6 font-weight-500"
                        >
                          Upload Selfie with Identity Doc
                        </label>
                        <div className="row align-items-center">
                          <div className="col-12">
                            <input
                              accept="image/*"
                              required
                              id="icon-button-file"
                              name="SelfiFile"
                              type="file"
                              ref={mobileInputFileSelfi}
                              capture="environment"
                              onChange={(event) => {
                                setFieldValue(
                                  "SelfiFile",
                                  event.currentTarget.files[0]
                                );
                                if (
                                  event.target.files &&
                                  event.target.files[0]
                                ) {
                                  setSelfieUploaded(
                                    URL.createObjectURL(event.target.files[0])
                                  );
                                }
                              }}
                              style={{ display: "none" }}
                            />
                            <img
                              src={SelfiImg}
                              className="w-100"
                              onClick={() =>
                                mobileInputFileSelfi.current.click()
                              }
                              alt=""
                            />
                          </div>
                        </div>
                      </div>
                      {errors.SelfiFile && (
                        <div>
                          <span fontSize={10} style={{ color: "red" }}>
                            {" "}
                            {errors.SelfiFile}
                          </span>
                        </div>
                      )}
                    </div>
                  )}

                  {selfieUploaded && (
                    <div>
                      <div className="row align-items-center">
                        <div className="col-12">
                          <img className="w-100" src={selfieUploaded} alt="" />
                        </div>
                      </div>
                      <div
                        style={{
                          borderBottomWidth: 1,
                          borderBottomColor: "black",
                        }}
                        onClick={() => {
                          setFieldValue("SelfiFile", "");
                          setSelfieUploaded("");
                        }}
                        className="text-align-start"
                      >
                        Remove
                      </div>
                    </div>
                  )}
                  <button
                    type="submit"
                    disabled={!isValid || buttonPressed}
                    className="theme-btn btn w-100 mt-3"
                    onClick={handleSubmit}
                  >
                    Submit
                  </button>
                </form>
              </>
            )}
          </Formik>
        </div>
      </div>
    );
  }
}

export default KYCSection;
