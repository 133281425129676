import { Formik } from "formik";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import * as yup from "yup";
import { SendOtp, changePassword, verifyOtp } from "../../redux/actions";

const ForgotPasswordModal = ({ setOpen, email = "", isMailExists = false }) => {
  const [mailSent, setMailSent] = useState(false);
  const [otpVerified, setOtpVerified] = useState(false);
  const [buttonPressed, setButtonPressed] = useState(false);
  const dispatch = useDispatch();

  const handleButtonClick = (data) => {
    if (!mailSent) {
      setButtonPressed(true);
      dispatch(SendOtp({ emailId: data.email }, setMailSent)).then(() =>
        setButtonPressed(false)
      );
    } else {
      setButtonPressed(true);
      dispatch(
        verifyOtp({ emailId: data.email, otp: data.otp }, setOtpVerified)
      ).then(() => setButtonPressed(false));
    }
  };

  const handleSubmit = (data) => {
    setOpen(false);
    setButtonPressed(true);
    dispatch(changePassword({ emailId: data.email, password: data.password }));
  };

  const ForgotPasswordValidationSchema = yup.object().shape({
    email: yup
      .string()
      .email("Please enter valid email")
      .required("Email Address is Required"),
    otp: yup
      .string()
      .required()
      .matches(/^[0-9]+$/, "Must be only digits")
      .min(4, "Must be exactly 4 digits")
      .max(4, "Must be exactly 4 digits"),
    password: yup
      .string()
      .min(8, ({ min }) => `Password must be at least ${min} characters`)
      .required("Password is required"),
    confirmPassword: yup
      .string()
      .oneOf([yup.ref("password")], "Password does not match")
      .min(8, ({ min }) => `Password must be at least ${min} characters`)
      .required("Password is required"),
  });

  return (
    <div style={{ width: "300px" }}>
      <div className="py-3 bg-white">
        <div className="container">
          <div className="row align-items-center">
            <div className="text-center">
              <h6 className="mb-0 ">Forgot Password</h6>
            </div>
          </div>
        </div>
      </div>
      <Formik
        validationSchema={ForgotPasswordValidationSchema}
        initialValues={{
          email: email,
          otp: "",
          password: "",
          confirmPassword: "",
        }}
        onSubmit={(values) => {
          handleSubmit(values);
        }}
      >
        {({
          handleChange,
          handleBlur,
          handleSubmit,
          values,
          errors,
          isValid,
          dirty,
        }) => (
          <>
            <form className="needs-validation" noValidate>
              <div className="input-cont">
                <div className="input-log">
                  <label
                    htmlFor="mobile_country"
                    className="fs-6 font-weight-500"
                  >
                    Email
                  </label>
                  <div className="row align-items-center">
                    <div className="col-12">
                      <input
                        type="email"
                        name="emailId"
                        disabled={mailSent || isMailExists}
                        placeholder="Enter email "
                        className="form-input custom_input w-100"
                        value={values.email}
                        onChange={handleChange("email")}
                      />
                    </div>
                  </div>
                </div>

                {errors.email && (
                  <div>
                    <span fontSize={10} style={{ color: "red" }}>
                      {" "}
                      {errors.email}
                    </span>
                  </div>
                )}
              </div>

              {mailSent && !otpVerified && (
                <div className="input-cont">
                  <div className="input-log">
                    <label
                      htmlFor="mobile_country"
                      className="fs-6 font-weight-500"
                    >
                      OTP
                    </label>
                    <div className="row align-items-center">
                      <div className="col-12">
                        <input
                          type="number"
                          maxLength={4} // not working
                          placeholder="Enter OTP"
                          className="form-input custom_input w-100"
                          value={values.otp}
                          onChange={handleChange("otp")}
                        />
                      </div>
                    </div>
                  </div>
                  {errors.otp && (
                    <div>
                      <span fontSize={10} style={{ color: "red" }}>
                        {" "}
                        {errors.otp}
                      </span>
                    </div>
                  )}
                </div>
              )}

              {otpVerified && (
                <>
                  <div className="input-cont">
                    <div className="input-log">
                      <label
                        htmlFor="mobile_country"
                        className="fs-6 font-weight-500"
                      >
                        Password
                      </label>
                      <div className="row align-items-center">
                        <div className="col-12">
                          <input
                            type="password"
                            name="password"
                            value={values.password}
                            placeholder="Enter password"
                            className="form-input custom_input w-100"
                            onChange={handleChange("password")}
                          />
                        </div>
                      </div>
                    </div>
                    {errors.password && (
                      <div>
                        <span fontSize={10} style={{ color: "red" }}>
                          {" "}
                          {errors.password}
                        </span>
                      </div>
                    )}
                  </div>

                  <div className="input-cont">
                    <div className="input-log">
                      <label
                        htmlFor="mobile_country"
                        className="fs-6 font-weight-500"
                      >
                        Confirm Password
                      </label>
                      <div className="row align-items-center">
                        <div className="col-12">
                          <input
                            type="password"
                            name="confirmPassword"
                            value={values.confirmPassword}
                            placeholder="Enter confirm password"
                            className="form-input custom_input w-100"
                            onChange={handleChange("confirmPassword")}
                          />
                        </div>
                      </div>
                    </div>
                    {errors.confirmPassword && (
                      <div>
                        <span fontSize={10} style={{ color: "red" }}>
                          {" "}
                          {errors.confirmPassword}
                        </span>
                      </div>
                    )}
                  </div>
                </>
              )}

              <button
                type="button"
                className="theme-btn btn w-100 mt-3"
                disabled={(buttonPressed)}
                onClick={() =>
                  handleButtonClick({ email: values.email, otp: values.otp })
                }
                hidden={otpVerified}
              >
                {mailSent ? "Verify OTP" : "Send OTP"}
              </button>
              <button
                type="submit"
                disabled={!isValid || buttonPressed}
                className="theme-btn btn w-100 mt-3"
                onClick={handleSubmit}
                hidden={!otpVerified}
              >
                Change Password
              </button>
            </form>
          </>
        )}
      </Formik>
    </div>
  );
};

export default ForgotPasswordModal;
