import HeaderTitle from '../../component/header-title';
import FooterTab from "../../component/footer-tab";
function TeamIncome() {
    return (
        <div>
            <HeaderTitle title={'Team incomes'} />
            <div className="contant space">
                <div className="info-content p-3">
                    <div className="info-box bg-white rounded p-2 my-bx-shadow">
                        <div className="row">
                            <div className="col-4">
                                <p className="mb-0">Grade</p>
                            </div>
                            <div className="col-4">
                                <p className="mb-0">Time</p>
                            </div>
                            <div className="col-4">
                                <p className="mb-0 d-flex justify-content-end">Amount</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <FooterTab to="/teamincome" />

        </div>

    )
}
export default TeamIncome;