import {Outlet, useNavigate} from 'react-router-dom';
import {Navigate} from 'react-router-dom';
import { useSelector, useDispatch} from 'react-redux';
import {useEffect} from 'react';
import {getUser} from './redux/actions';

const ProtectedRoute = ({ user }) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const userData = useSelector((state) => state.user.userData);
    var userDetails = localStorage.getItem('user');

  useEffect(() =>{
    if(userDetails){
      var user = JSON.parse(userDetails);
      if (user.emailId) {
        dispatch(getUser({emailId:user.emailId}));
      }
    }
  },[])

    if (!userDetails) {
      return <Navigate to="/" replace />;
    }

  
    return <Outlet />;
  };

  export default ProtectedRoute;
