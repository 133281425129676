import React from "react";
import { Outlet } from "react-router-dom";
import maintenanceImg from "../../img/Maintenance.png";
import Header from "../../component/header";

const Maintenance = ({ maintenance }) => {
  return (
    <div>
      <Header />
      {maintenance ? (
        <div style={{ height: "100vh" }}>
          <img
            src={maintenanceImg}
            alt="maintenance"
            style={{ height: "100%", width: "100%", objectFit: "contain" }}
          />
        </div>
      ) : (
        <Outlet />
      )}
    </div>
  );
};

export default Maintenance;
