/* eslint-disable jsx-a11y/anchor-is-valid */
import ReferalIcon from "../../img/reffer.png";
import CloseIcon from "../../img/xmark.svg";
import { useNavigate } from "react-router-dom";
import { RWebShare } from "react-web-share";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";

function ReferalFriend() {
  const [user, setUser] = useState(null);
  const handleCopyClipart = (value) => {
    navigator.clipboard.writeText(value);
    toast.success("Invitation code is successfully copied", {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: true,
      closeOnClick: false,
      pauseOnHover: false,
      draggable: false,
      progress: undefined,
    });
  };

  useEffect(() => {
    const userData = JSON.parse(localStorage.getItem("user"));
    setUser(userData);
  }, []);
  const navigate = useNavigate();
  return (
    <div>
      <div className="refer-main border-bottomu fixed-top">
        <div className="container">
          <div className="refer-top">
            <div className="d-flex align-items-center py-3 px-4">
              <div>
                <h6>Refer friends. Get reward</h6>
              </div>
              <div className=" ms-auto">
                <span>
                  <img
                    className="opacity-5"
                    height={21}
                    src={CloseIcon}
                    onClick={() => navigate(-1)}
                    alt=""
                  />
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="contant space">
        <div className="reward">
          <div className="container">
            <div className="inner-section text-center ">
              <img
                src={ReferalIcon}
                className="img-fluid mb-4 "
                width={100}
                alt=""
              />
              <h5>Get Earn Commission</h5>
              <p>Introduce your friends and earn commission</p>
            </div>
          </div>
        </div>
        <div className="refer-link py-3">
          <div className="container">
            <div className="refer-link-contant text-center">
              <section>
                <h5>Your referral code:</h5>
                <div>
                  {user && (
                    <div className="d-inline-block px-4 py-2 my-3 refer-link-section theme-bg text-white">
                      {user.referalCode}
                    </div>
                  )}
                </div>
                <div
                  style={{ cursor: "pointer" }}
                  onClick={() => handleCopyClipart(user.referalCode)}
                >
                  Copy-code
                </div>
              </section>
              <div className="mt-4">
                <strong>Commission rebating rules</strong>
                <br />
                <small>
                  For every profit earned by friend after registering, you will
                  receive a corresponding percentage of commission
                </small>
              </div>
              <div className="mt-4">
                <strong>Commission rate rules are as follows</strong>
                <table className="table table-sm mt-1 cus-table">
                  <tbody>
                    <tr className="bg-light">
                      <td>Grade of user</td>
                      <td>Rule</td>
                      <td>Commission rate</td>
                    </tr>
                    <tr>
                      <td>LV1</td>
                      <td>Directly invited users</td>
                      <td>10%</td>
                    </tr>
                    <tr>
                      <td>LV2</td>
                      <td>LV 1 invited users</td>
                      <td>5%</td>
                    </tr>
                    <tr>
                      <td>LV3</td>
                      <td>LV 2 invited users</td>
                      <td>3%</td>
                    </tr>
                  </tbody>
                </table>
                <p className="small theme-text">
                  The commission is settled in USDT and can be withdrawn and
                  deposited into your wallet any time
                </p>
              </div>
              <section>
                <h6>
                  Let's get ready to join worlds most artificially Inteligant
                  robotic trade with MetaFoxBot.
                </h6>
                {user && (
                  <div className="share-with-friend">
                    <RWebShare
                      data={{
                        text: `
                        🚀 Join Now and Get $2 Sign-up Bonus !.\n\n🪄 Unlock the Power of Referral Rewards\n\n🌟 Earn 30-50% Returns Every Month !\n\n👥 Level 3 Commission System :\n  - Level 1: 10% Commission \n  - Level 2: 5% Commision \n  - Level 3: 3% Commission \n\nSpread the word and watch your rewards grow ! Don't miss out on this opportunity.\n\nSign up today and start earning ! 📈\n\n`,
                        url:
                          `${window.location.origin}/register/` +
                          user.referalCode,
                        title: "MetaFoxBot",
                      }}
                      onClick={() => {}}
                    >
                      <button>Share with friends</button>
                    </RWebShare>
                  </div>
                )}
              </section>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default ReferalFriend;
