/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import HeaderTitle from "../../component/header-title";
import { Formik } from "formik";
import * as yup from "yup";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { updateUserInfo } from "../../redux/actions/index";
import { isEmpty } from "lodash";
import { useNavigate } from "react-router-dom";
import "./styles.css";

function EditProfileInformation() {
  const userDetails = JSON.parse(localStorage.getItem("user"));
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [disabledFlag, setDisabledFlag] = useState(false);
  const [isIMPSAddress, setIsIMPSAddress] = useState(false);
  const [impsDetailsError, setImpsDetailsError] = useState(false);
  const [formInitialValue, setformInitialValue] = useState({
    email: "",
    phonenumber: "",
    transaction_address: "",
    bankAccountNo: "",
    bankIFSC: "",
    nameInBank: "",
  });

  const editUserInfo = (info) => {
    setDisabledFlag(true);
    const prevTransactionAddress = userDetails.transaction_address || "";
    const prevImpsAddress = (userDetails.impsAddress = "");
    var updateInfo = {
      phone: info.phonenumber,
    };
    if (!userDetails.transactionAddressUpdate) {
      // if transactionAddressUpdate is false, then user can update transaction address
      if (prevTransactionAddress !== info.transaction_address) {
        updateInfo = {
          ...updateInfo,
          transactionAddress: info.transaction_address,
          transactionAddressUpdate: true,
        };
      }
    }
    if (!userDetails.impsAddressUpdate) {
      // if impsAddressUpdate is false, then user can update transaction address
      if (
        !isEmpty(info.bankAccountNo) ||
        !isEmpty(info.bankIFSC) ||
        !isEmpty(info.nameInBank)
      ) {
        // if atleast one of them is non empty then all should be non empty
        if (
          !(
            !isEmpty(info.bankAccountNo) &&
            !isEmpty(info.bankIFSC) &&
            !isEmpty(info.nameInBank)
          )
        ) {
          // if atleast one is non empty show error
          setImpsDetailsError(true);
          setDisabledFlag(false);
          return;
        } else if (
          prevImpsAddress === "" ||
          !(
            prevImpsAddress.bankAccountNo === info.bankAccountNo1 &&
            prevImpsAddress.bankIFSC === info.bankIFSC &&
            prevImpsAddress.nameInBank === info.nameInBank
          )
        ) {
          // if there is no prev imps or not of (all are equal) that is atleast one is not equal to previous
          updateInfo = {
            ...updateInfo,
            impsAddress: {
              bankAccountNo: info.bankAccountNo,
              bankIFSC: info.bankIFSC,
              nameInBank: info.nameInBank,
            },
            impsAddressUpdate: true,
          };
        }
      }
    }
    dispatch(updateUserInfo(updateInfo, navigate, setDisabledFlag));
  };

  useEffect(() => {
    setDisabledFlag(true);
    setformInitialValue({
      email: userDetails.emailId,
      phonenumber: userDetails.phone ? userDetails.phone : "",
      transaction_address:
        userDetails && userDetails.transactionAddress
          ? userDetails.transactionAddress
          : "",
      bankAccountNo:
        userDetails && userDetails.impsAddress
          ? userDetails.impsAddress.bankAccountNo
          : "",
      bankIFSC:
        userDetails && userDetails.impsAddress
          ? userDetails.impsAddress.bankIFSC
          : "",
      nameInBank:
        userDetails && userDetails.impsAddress
          ? userDetails.impsAddress.nameInBank
          : "",
    });

    setIsIMPSAddress(userDetails.impsAddressUpdate);
    setDisabledFlag(false);
  }, []);

  const editInfoSchema = yup.object().shape({
    email: yup
      .string()
      .email("Please enter valid email")
      .required("Email Address is Required"),
    phonenumber: yup.number().required("Please enter the phone number"),
    transaction_address: yup.string(),
    bankAccountNo: yup.number(),
    bankIFSC: yup.string(),
    nameInBank: yup.string(),
  });

  return (
    <div className="">
      <HeaderTitle title={"Edit Profile Information"} />
      <div className="contant space ">
        <Formik
          enableReinitialize
          validationSchema={editInfoSchema}
          initialValues={formInitialValue}
          onSubmit={(values) => {
            editUserInfo(values);
          }}
        >
          {({
            handleChange,
            handleBlur,
            handleSubmit,
            values,
            errors,
            isValid,
            dirty,
            setFieldValue,
          }) => (
            <>
              <div className="info-content p-3">
                <div className="info-box bg-white rounded p-3 my-bx-shadow">
                  <div className="row">
                    <div className="input-cont-info">
                      <div className="input-log">
                        <label
                          htmlFor="mobile_country"
                          className="fs-6 font-weight-500"
                        >
                          Email
                        </label>
                        <div className="row align-items-center">
                          <div className="col-12">
                            <input
                              disabled={true}
                              type="email"
                              name="emailId"
                              value={values.email}
                              onChange={handleChange("email")}
                              placeholder="Enter email "
                              className="form-input custom_input w-100"
                            />
                          </div>
                        </div>
                      </div>

                      {errors.email && (
                        <div>
                          <span fontSize={10} style={{ color: "red" }}>
                            {" "}
                            {errors.email}
                          </span>
                        </div>
                      )}
                    </div>
                    <div className="col-6"></div>
                  </div>

                  <div className="row">
                    <div className="col-12">
                      <div className="input-cont-info">
                        <div className="input-log">
                          <label
                            htmlFor="mobile_country"
                            className="fs-6 font-weight-500"
                          >
                            Mobile Number
                          </label>
                          <div className="row align-items-center">
                            <div className="col-12">
                              <input
                                type="tel"
                                name="emailId"
                                value={values.phonenumber}
                                onChange={handleChange("phonenumber")}
                                placeholder="Enter Mobile Number"
                                className="form-input custom_input w-100"
                              />
                            </div>
                          </div>
                        </div>

                        {errors.phonenumber && (
                          <div>
                            <span fontSize={10} style={{ color: "red" }}>
                              {" "}
                              {errors.phonenumber}
                            </span>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-12">
                      <div className="input-cont-info">
                        <div className="input-log">
                          <label
                            htmlFor="mobile_country"
                            className="fs-6 font-weight-500"
                          >
                            Withdrawal Address(TRC20)
                          </label>
                          <div className="row align-items-center">
                            <div className="col-12">
                              <input
                                disabled={userDetails.transactionAddressUpdate}
                                type="text"
                                name="transaction"
                                value={values.transaction_address}
                                onChange={handleChange("transaction_address")}
                                placeholder="Enter Transaction Address"
                                className="form-input custom_input w-100"
                              />
                            </div>
                          </div>
                        </div>

                        {errors.transaction_address && (
                          <div>
                            <span fontSize={10} style={{ color: "red" }}>
                              {" "}
                              {errors.transaction_address}
                            </span>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-12">
                      <div className="input-cont-info">
                        <div className="input-log">
                          <label
                            htmlFor="mobile_country"
                            className="fs-6 font-weight-500"
                          >
                            Withdrawal Address(IMPS details)
                          </label>
                          <div className="row align-items-center">
                            <div className="col-12">
                              <input
                                disabled={isIMPSAddress}
                                type="text"
                                name="bankAccountNo"
                                value={values.bankAccountNo}
                                onChange={(e) => {
                                  setFieldValue(
                                    "bankAccountNo",
                                    e.target.value
                                  );
                                  setImpsDetailsError(false);
                                }}
                                placeholder="Enter Bank Account Number"
                                className="form-input custom_input w-100"
                              />
                            </div>
                            <div className="hrStyle" />
                          </div>
                          {impsDetailsError &&
                            isEmpty(values.bankAccountNo) && (
                              <div>
                                <span fontSize={10} style={{ color: "red" }}>
                                  {" "}
                                  {"Please Enter Bank Account Number"}
                                </span>
                              </div>
                            )}
                          <div className="row align-items-center">
                            <div className="col-12">
                              <input
                                disabled={isIMPSAddress}
                                type="text"
                                name="bankIFSC"
                                value={values.bankIFSC}
                                onChange={(e) => {
                                  setFieldValue("bankIFSC", e.target.value);
                                  setImpsDetailsError(false);
                                }}
                                placeholder="Enter Bank IFSC Code"
                                className="form-input custom_input w-100"
                              />
                            </div>
                            <div className="hrStyle" />
                          </div>
                          {impsDetailsError && isEmpty(values.bankIFSC) && (
                            <div>
                              <span fontSize={10} style={{ color: "red" }}>
                                {" "}
                                {"Please Enter Bank IFSC Code"}
                              </span>
                            </div>
                          )}
                          <div className="row align-items-center">
                            <div className="col-12">
                              <input
                                disabled={isIMPSAddress}
                                type="text"
                                name="nameInBank"
                                value={values.nameInBank}
                                onChange={(e) => {
                                  setFieldValue("nameInBank", e.target.value);
                                  setImpsDetailsError(false);
                                }}
                                placeholder="Enter Account Holders Name"
                                className="form-input custom_input w-100"
                              />
                              {impsDetailsError &&
                                isEmpty(values.nameInBank) && (
                                  <div>
                                    <span
                                      fontSize={10}
                                      style={{ color: "red" }}
                                    >
                                      {" "}
                                      {"Please Enter Account Holders name "}
                                    </span>
                                  </div>
                                )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row"></div>
                </div>
                <div className="row">
                  <div className="d-flex justify-content-end">
                    <button
                      style={{ marginLeft: 10 }}
                      disabled={!isValid || disabledFlag}
                      onClick={handleSubmit}
                      type="submit"
                      className="theme-btn btn w-100 mt-3"
                    >
                      Save
                    </button>
                  </div>
                </div>
              </div>
            </>
          )}
        </Formik>
      </div>
    </div>
  );
}

export default EditProfileInformation;
