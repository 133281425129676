import { Navigate } from "react-router";
import { toast } from "react-toastify";
import { api, axiosInstance } from "../../api/index";
import {
  SIGNIN_SUCCESS,
  SIGNIN_FAILED,
  SIGNUP_FAILED,
  SIGNUP_SUCCESS,
  IS_LOADING,
  UPDATE_USER_SUCCESS,
  UPDATE_USER_FAILED,
  SEND_VERIFICATION_SUCCESS,
  SEND_VERIFICATION_FAILED,
  GET_TRANSACTION_SUCCESS,
  GET_TRANSACTION_FAILED,
  DELETE_TRANSACTION_SUCCESS,
  DELETE_TRANSACTION_FAILED,
  ADD_WITHDRAW_SUCCESS,
  ADD_WITHDRAW_FAILED,
  UPDATE_WITHDRAWAL_SUCCESS,
  UPDATE_WITHDRAWAL_FAILED,
  DELETE_WITHDRAWAL_SUCCESS,
  DELETE_WITHDRAWAL_FAILED,
  ADD_DEPOSIT_SUCCESS,
  ADD_DEPOSIT_FAILED,
  GET_DEPOSIT_SUCCESS,
  GET_DEPOSIT_FAILED,
  GET_FUNDDETAILS_FAILED,
  GET_FUNDDETAILS_SUCCESS,
  GET_TRANSACTION_DATA,
  GET_TRANSACTION_DATA_FAILED,
  GET_COINDETAILS_DATA,
  GET_COINDETAILS_DATA_FAILED,
  IS_TRANSACTION_LOADING,
  GET_USER_FAILED,
  GET_USER_SUCCESS,
  GET_WITHDRAWAL_SUCCESS,
  GET_WITHDRAWAL_FAILED,
  GET_USER_TEAMS_SUCCESS,
  GET_USER_TEAMS_FAILED,
  GET_TRANSACTION_HISTORY_DATA_SUCCESS,
  GET_TRANSACTION_DATA_HISTORY_FAILED,
  GET_PAYMENTDETAILS_DATA,
  GET_PAYMENTDETAILS_DATA_FAILED,
  GET_FUNDSDETAILS_DATA,
  GET_FUNDSDETAILS_DATA_FAILED,
  GET_KYC_DATA_FAILED,
  GET_KYC_DATA,
  GET_KYC_DATA_UPLOAD,
  GET_KYC_DATA_UPLOAD_FAILED,
  GET_TRADE_DATA_FAILED,
  GET_TRADE_DATA_SUCCESS,
  IS_TRADE_SUCCESS,
  USER_LOGOUT,
  IS_WITHDRAWAL_SUCCESS,
} from "../constant";

export function isLoading(isLoading = false) {
  return {
    type: IS_LOADING,
    isLoading: isLoading,
  };
}
export function isTradeSuccess() {
  return {
    type: IS_TRADE_SUCCESS,
    issuccess: true,
  };
}
export function isWithdrawalSuccess() {
  return {
    type: IS_WITHDRAWAL_SUCCESS,
    issuccess: true,
  };
}
export function isTransactionLoading(isLoading = false) {
  return {
    type: IS_TRANSACTION_LOADING,
    isLoading: isLoading,
  };
}
export function userLogout() {
  return {
    type: USER_LOGOUT,
    isLoading: isLoading,
  };
}

export function loginUser(userDetails) {
  return async (dispatch) => {
    try {
      api
        .post("userMaster/login", userDetails)
        .then((response) => {
          localStorage.setItem("token", JSON.stringify(response.data.token));
          // console.log("Im data bithc", response.data.data);
          localStorage.setItem("user", JSON.stringify(response.data.data));
          axiosInstance.defaults.headers.common.Authorization = `${response.data.token}`;
          dispatch(getUser({ emailId: response.data.data.emailId }));
          dispatch({
            type: SIGNIN_SUCCESS,
            payload: response.data.data,
          });
          <Navigate to="/" replace />;
        })
        .catch((error) => {
          toast.error(error.message);
          dispatch(isLoading(false));
          dispatch({
            type: SIGNIN_FAILED,
            errorMessage: error.message,
          });
        });
    } catch (error) {
      dispatch(isLoading(false));
      dispatch({
        type: SIGNIN_FAILED,
        payload: error.message,
      });
    }
  };
}

export function getUser(userDetails) {
  return (dispatch) => {
    try {
      dispatch(isLoading(true));
      const user = localStorage.getItem("token");
      var token = JSON.parse(user);
      axiosInstance.defaults.headers.common.Authorization = `${token}`;
      api
        .post("userMaster/get", userDetails)
        .then((response) => {
          if (response.data.data.data[0].deleted) {
            dispatch(isLoading(false));
            dispatch(userLogout());
            localStorage.clear();
            <Navigate to="/login" />;
          } else {
            dispatch({
              type: GET_USER_SUCCESS,
              payload: response.data.data.data[0],
            });
            localStorage.setItem(
              "user",
              JSON.stringify(response.data.data.data[0])
            );

            dispatch(isLoading(false));
          }
        })
        .catch((error) => {
          toast.error(error.message);
          dispatch(isLoading(false));
          dispatch({
            type: GET_USER_FAILED,
            errorMessage: error.message,
          });
        });
    } catch (error) {
      dispatch(isLoading(false));
      dispatch({
        type: GET_USER_FAILED,
        payload: error.message,
      });
    }
  };
}

export function SendOtp(userDetails, setMailSend) {
  return async () => {
    try {
      await api.post("forgotPassword/generateOtp", userDetails);
      toast.success("OTP sent to mail Sucessfully!");
      setMailSend(true);
    } catch (error) {
      toast.error(error.message);
    }
  };
}

export function verifyOtp(userDetails, setOtpVerified) {
  return async () => {
    try {
      await api.patch("forgotPassword/verifyOtp", userDetails);
      toast.success("OTP verified Sucessfully!");
      setOtpVerified(true);
    } catch (error) {
      toast.error(error.message);
    }
  };
}

export function changePassword(userDetails) {
  return async () => {
    try {
      await api.patch("forgotPassword/changePassword", userDetails);
      toast.success("Password changed Sucessfully!");
    } catch (error) {
      toast.error(error.message);
    }
  };
}
export function changeKey(userDetails) {
  return async () => {
    try {
      await api.patch("forgotPassword/changeKey", userDetails);
      toast.success("Withdrawal Key Changed Sucessfully!");
    } catch (error) {
      toast.error(error.message);
    }
  };
}

export function registerNewUser(userDetails) {
  return (dispatch) => {
    try {
      api
        .post("userMaster/add", userDetails)
        .then((response) => {
          toast.success("User signed up sucessfully!");
          localStorage.setItem("token", JSON.stringify(response.data.token));
          localStorage.setItem("user", JSON.stringify(response.data.data));
          axiosInstance.defaults.headers.common.Authorization = `${response.data.token}`;
          dispatch({
            type: SIGNUP_SUCCESS,
            payload: response.data,
          });
        })
        .catch((error) => {
          toast.error(error.message);
          dispatch(isLoading(false));
          dispatch({
            type: SIGNUP_FAILED,
            errorMessage: error.message,
          });
        });
    } catch (error) {
      dispatch(isLoading(false));
      dispatch({
        type: SIGNUP_FAILED,
        payload: error.message,
      });
    }
  };
}

export function updateUserInfo(userDetails, navigate, setDisabledFlag) {
  return (dispatch) => {
    try {
      const user = JSON.parse(localStorage.getItem("user"));
      const userID = user._id;
      api
        .put(`userMaster/update/${userID}`, userDetails)
        .then((response) => {
          toast.success("User updated sucessfully!");
          navigate("/profile");
          dispatch({
            type: UPDATE_USER_SUCCESS,
            payload: response.data,
          });
          dispatch(getUser({ emailId: user.emailId }));
          dispatch(isLoading(false));
        })
        .catch((error) => {
          toast.error(error.message);
          dispatch(isLoading(false));
          dispatch({
            type: UPDATE_USER_FAILED,
            errorMessage: error.message,
          });
        });
    } catch (error) {
      dispatch(isLoading(false));
      dispatch({
        type: UPDATE_USER_FAILED,
        payload: error.message,
      });
    } finally {
      setDisabledFlag(false);
    }
  };
}

export function sendVerificationCode(emailInfo) {
  return (dispatch) => {
    try {
      api
        .post(`userMaster/otpGenerate`, emailInfo)
        .then((response) => {
          toast.success("OTP sent sucessfully!");
          dispatch({
            type: SEND_VERIFICATION_SUCCESS,
            payload: response.data,
          });
        })
        .catch((error) => {
          toast.error(error.message);
          dispatch(isLoading(false));
          dispatch({
            type: SEND_VERIFICATION_FAILED,
            errorMessage: error.message,
          });
        });
    } catch (error) {
      dispatch(isLoading(false));
      dispatch({
        type: SEND_VERIFICATION_FAILED,
        payload: error.message,
      });
    }
  };
}

export function getReferralTasks(setReferralTasks, setDailyTasks) {
  return (dispatch) => {
    const user = JSON.parse(localStorage.getItem("user"));
    try {
      dispatch(isLoading(true));
      api
        .post("rewards/get")
        .then((response) => {
          const referralTasks = response.data.data.data.filter(
            (task) => task.type === "referral"
          );
          const dailyTasks = response.data.data.data.filter(
            (task) => task.type === "daily"
          );
          for (const task of referralTasks) {
            const isClaimed = task.claimedBy.some(
              (claimed) => claimed.userId.toString() === user._id.toString()
            );
            task.claimed = isClaimed;
          }
          setReferralTasks(referralTasks);
          for (const task of dailyTasks) {
            const isPresent = task.claimedBy.find(
              (claimed) => claimed.userId.toString() === user._id.toString()
            );
            if (!isPresent || isPresent.status === "rejected") {
              task.status = "Upload Screenshot";
            } else {
              task.status = isPresent.status;
            }
          }
          setDailyTasks(dailyTasks);
          dispatch(isLoading(false));
        })
        .catch((error) => {
          toast.error(error.message);
          dispatch(isLoading(false));
        });
    } catch (error) {
      dispatch(isLoading(false));
    }
  };
}

export function claimTasks(
  taskId,
  type,
  body,
  setReferralTasks,
  setDailyTasks
) {
  return async (dispatch) => {
    const user = JSON.parse(localStorage.getItem("user"));
    try {
      dispatch(isLoading(true));
      let response;
      if (type === "referral") {
        response = await api.post(`rewards/claim/${taskId}`);
      } else {
        response = await api.post(`rewards/dailyClaim/${taskId}`, body);
      }

      const referralTasks = response.data.data.data.filter(
        (task) => task.type === "referral"
      );
      const dailyTasks = response.data.data.data.filter(
        (task) => task.type === "daily"
      );
      for (const task of referralTasks) {
        const isClaimed = task.claimedBy.some(
          (claimed) => claimed.userId.toString() === user._id.toString()
        );
        task.claimed = isClaimed;
      }
      setReferralTasks(referralTasks);
      for (const task of dailyTasks) {
        const isPresent = task.claimedBy.find(
          (claimed) => claimed.userId.toString() === user._id.toString()
        );
        if (!isPresent || isPresent.status === "rejected") {
          task.status = "upload";
        } else {
          task.status = isPresent.status;
        }
      }
      console.log(dailyTasks);
      setDailyTasks(dailyTasks);
      dispatch(isLoading(false));
    } catch (error) {
      toast.error(error.message);
      dispatch(isLoading(false));
    }
  };
}

export function getAnnouncements(setAnnouncements) {
  return (dispatch) => {
    try {
      api
        .post("announcements/get")
        .then((response) => {
          setAnnouncements(response.data.data.data);
        })
        .catch((error) => {
          toast.error(error.message);
          dispatch(isLoading(false));
        });
    } catch (error) {
      dispatch(isLoading(false));
    }
  };
}

export function changeTransactionDetails(transactionInfo) {
  return (dispatch) => {
    try {
      api
        .post(`transaction/get`, transactionInfo)
        .then((response) => {
          dispatch({
            type: GET_TRANSACTION_SUCCESS,
            payload: response.data,
          });
        })
        .catch((error) => {
          toast.error(error.message);
          dispatch(isLoading(false));
          dispatch({
            type: GET_TRANSACTION_FAILED,
            errorMessage: error.message,
          });
        });
    } catch (error) {
      dispatch(isLoading(false));
      dispatch({
        type: GET_TRANSACTION_FAILED,
        payload: error.message,
      });
    }
  };
}

export function deleteTransaction(transactionInfo) {
  return (dispatch) => {
    try {
      api
        .delete(`transaction/delete`, transactionInfo)
        .then((response) => {
          dispatch({
            type: DELETE_TRANSACTION_SUCCESS,
            payload: response.data,
          });
        })
        .catch((error) => {
          toast.error(error.message);
          dispatch(isLoading(false));
          dispatch({
            type: DELETE_TRANSACTION_FAILED,
            errorMessage: error.message,
          });
        });
    } catch (error) {
      dispatch(isLoading(false));
      dispatch({
        type: DELETE_TRANSACTION_FAILED,
        payload: error.message,
      });
    }
  };
}

export function addWithdraw(withdrawInfo, navigate) {
  return (dispatch) => {
    try {
      dispatch(isLoading(true));
      api
        .post(`withdraw/add`, withdrawInfo)
        .then((response) => {
          toast.success("Withdraw sucessfully!");
          dispatch({
            type: ADD_WITHDRAW_SUCCESS,
            payload: response.data,
          });
          dispatch({
            type: IS_WITHDRAWAL_SUCCESS,
            issuccess: true,
          });
          navigate("/profile");
        })
        .catch((error) => {
          toast.error(error.message);
          dispatch(isLoading(false));
          dispatch({
            type: ADD_WITHDRAW_FAILED,
            errorMessage: error.message,
          });
        });
    } catch (error) {
      dispatch(isLoading(false));
      dispatch({
        type: ADD_WITHDRAW_FAILED,
        payload: error.message,
      });
    }
  };
}

export function updateWithdraw(withdrawInfo) {
  return (dispatch) => {
    try {
      api
        .put(`withdraw/update/628949aa1d3f4c9e0c032751`, withdrawInfo)
        .then((response) => {
          dispatch({
            type: UPDATE_WITHDRAWAL_SUCCESS,
            payload: response.data,
          });
        })
        .catch((error) => {
          toast.error(error.message);
          dispatch(isLoading(false));
          dispatch({
            type: UPDATE_WITHDRAWAL_FAILED,
            errorMessage: error.message,
          });
        });
    } catch (error) {
      dispatch(isLoading(false));
      dispatch({
        type: UPDATE_WITHDRAWAL_FAILED,
        payload: error.message,
      });
    }
  };
}

export function getDetailsFunds() {
  return (dispatch) => {
    try {
      dispatch(isLoading(true));
      const user = localStorage.getItem("user");
      var token = JSON.parse(user);
      api
        .post("userMaster/get", { referalBy: token.referalCode })
        .then((response) => {
          dispatch({
            type: GET_FUNDSDETAILS_DATA,
            payload: response.data.data.data,
          });
          dispatch(isLoading(false));
        })
        .catch((error) => {
          toast.error(error.message);
          dispatch(isLoading(false));
          dispatch({
            type: GET_FUNDSDETAILS_DATA_FAILED,
            errorMessage: error.message,
          });
        });
    } catch (error) {
      dispatch(isLoading(false));
      dispatch({
        type: GET_FUNDSDETAILS_DATA_FAILED,
        payload: error.message,
      });
    }
  };
}

export function uploadKycDocs(data) {
  return (dispatch) => {
    try {
      dispatch(isLoading(true));
      const user = localStorage.getItem("user");
      var token = JSON.parse(user);
      api
        .patch(`userMaster/multipleUpload/${token?._id}`, data, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response) => {
          // setSuccess((prev) => prev + 1);

          dispatch({
            type: GET_KYC_DATA_UPLOAD,
            payload: response.data.data.data,
          });
        })
        .catch((error) => {
          toast.error(error.message);
          dispatch(isLoading(false));
          dispatch({
            type: GET_KYC_DATA_UPLOAD_FAILED,
            errorMessage: error.message,
          });
        });
    } catch (error) {
      dispatch(isLoading(false));
      dispatch({
        type: GET_KYC_DATA_UPLOAD_FAILED,
        payload: error.message,
      });
    }
  };
}
export function updateKycDocs(data) {
  return (dispatch) => {
    try {
      dispatch(isLoading(true));
      const user = localStorage.getItem("user");
      var token = JSON.parse(user);
      api
        .put(`userMaster/update/${token._id}`, data)
        .then((response) => {
          // setSuccess((prev) => prev + 1);
          dispatch({
            type: GET_KYC_DATA,
            payload: response.data.data.data,
          });
        })
        .catch((error) => {
          toast.error(error.message);
          dispatch(isLoading(false));
          dispatch({
            type: GET_KYC_DATA_FAILED,
            errorMessage: error.message,
          });
        });
    } catch (error) {
      // dispatch(isLoading(false));
      dispatch({
        type: GET_KYC_DATA_FAILED,
        payload: error.message,
      });
    }
  };
}

export function deleteWithDrawal(withdrawInfo) {
  return (dispatch) => {
    try {
      api
        .delete(`withdraw/delete/628949aa1d3f4c9e0c032751`, withdrawInfo)
        .then((response) => {
          dispatch({
            type: DELETE_WITHDRAWAL_SUCCESS,
            payload: response.data,
          });
        })
        .catch((error) => {
          toast.error(error.message);
          dispatch(isLoading(false));
          dispatch({
            type: DELETE_WITHDRAWAL_FAILED,
            errorMessage: error.message,
          });
        });
    } catch (error) {
      dispatch(isLoading(false));
      dispatch({
        type: DELETE_WITHDRAWAL_FAILED,
        payload: error.message,
      });
    }
  };
}

export function addDeposit(depositInfo, navigate) {
  return (dispatch) => {
    try {
      const user = localStorage.getItem("token");
      var token = JSON.parse(user);
      axiosInstance.defaults.headers.common.Authorization = `${token}`;
      api
        .post(`deposit/add`, depositInfo)
        .then((response) => {
          dispatch({
            type: ADD_DEPOSIT_SUCCESS,
            payload: response.data,
          });
          dispatch(getDeposit());
          toast.success("Amount deposit sucessfully!");
          navigate("/profile");
        })
        .catch((error) => {
          toast.error(error.message);
          dispatch(isLoading(false));
          dispatch({
            type: ADD_DEPOSIT_FAILED,
            errorMessage: error.message,
          });
        });
    } catch (error) {
      dispatch(isLoading(false));
      dispatch({
        type: ADD_DEPOSIT_FAILED,
        payload: error.message,
      });
    }
  };
}
export function getFunds() {
  return (dispatch) => {
    try {
      const user = localStorage.getItem("user");
      const id = JSON.parse(user)._id;
      api
        .post(`editFunds/get`, { id: id })
        .then((response) => {
          dispatch({
            type: GET_FUNDDETAILS_SUCCESS,
            payload: response.data.data.data,
          });
        })
        .catch((error) => {
          toast.error(error.message);
          dispatch(isLoading(false));
          dispatch({
            type: GET_FUNDDETAILS_FAILED,
            errorMessage: error.message,
          });
        });
    } catch (error) {
      dispatch(isLoading(false));
      dispatch({
        type: GET_FUNDDETAILS_FAILED,
        payload: error.message,
      });
    }
  };
}
export function getDeposit() {
  return (dispatch) => {
    try {
      const user = localStorage.getItem("user");
      const code = JSON.parse(user).referalCode;
      api
        .post(`deposit/get`, { referalCode: code })
        .then((response) => {
          dispatch({
            type: GET_DEPOSIT_SUCCESS,
            payload: response.data.data.data,
          });
        })
        .catch((error) => {
          toast.error(error.message);
          dispatch(isLoading(false));
          dispatch({
            type: GET_DEPOSIT_FAILED,
            errorMessage: error.message,
          });
        });
    } catch (error) {
      dispatch(isLoading(false));
      dispatch({
        type: GET_DEPOSIT_FAILED,
        payload: error.message,
      });
    }
  };
}
export function getWithdraw() {
  return (dispatch) => {
    try {
      const user = localStorage.getItem("user");
      const code = JSON.parse(user).referalCode;
      api
        .post(`withdraw/get`, { referalCode: code })
        .then((response) => {
          dispatch({
            type: GET_WITHDRAWAL_SUCCESS,
            payload: response.data.data.data,
          });
        })
        .catch((error) => {
          toast.error(error.message);
          dispatch(isLoading(false));
          dispatch({
            type: GET_WITHDRAWAL_FAILED,
            errorMessage: error.message,
          });
        });
    } catch (error) {
      dispatch(isLoading(false));
      dispatch({
        type: GET_WITHDRAWAL_FAILED,
        payload: error.message,
      });
    }
  };
}

export function getUserTeam() {
  return (dispatch) => {
    try {
      dispatch(isLoading(true));
      const user = localStorage.getItem("user");
      const code = JSON.parse(user).referalCode;
      api
        .post("userMaster/get", { referalBy: code })
        .then((response) => {
          dispatch({
            type: GET_USER_TEAMS_SUCCESS,
            payload: response.data.data.data,
          });
          dispatch(isLoading(false));
        })
        .catch((error) => {
          toast.error(error.message);
          dispatch(isLoading(false));
          dispatch({
            type: GET_USER_TEAMS_FAILED,
            errorMessage: error.message,
          });
        });
    } catch (error) {
      dispatch(isLoading(false));
      dispatch({
        type: GET_USER_FAILED,
        payload: error.message,
      });
    }
  };
}

export function getTransactionHistory(data) {
  return async (dispatch) => {
    try {
      api
        .post("trade/get", data)
        .then((response) => {
          dispatch({
            type: GET_TRANSACTION_HISTORY_DATA_SUCCESS,
            payload: response.data.data.data,
          });
        })
        .catch((error) => {
          toast.error(error.message);
          dispatch({
            type: GET_TRANSACTION_DATA_HISTORY_FAILED,
            errorMessage: error.message,
          });
        });
    } catch (error) {
      dispatch({
        type: GET_TRANSACTION_DATA_HISTORY_FAILED,
        payload: error.message,
      });
    }
  };
}

export function addTrade(details) {
  return async (dispatch) => {
    try {
      api
        .post("trade/add", details)
        .then((response) => {
          toast.success(
            "Trade Successfully Processed, MetaFoxBot is working for your Profit!",
            {
              position: "top-right",
              autoClose: 1000,
              hideProgressBar: true,
              closeOnClick: false,
              pauseOnHover: false,
              draggable: false,
              progress: undefined,
            }
          );
          dispatch({
            type: GET_TRADE_DATA_SUCCESS,
            payload: response.data.data.data,
          });

          <Navigate to="/transaction" replace />;
        })
        .catch((error) => {
          toast.error(error.message);
          dispatch({
            type: GET_TRADE_DATA_FAILED,
            errorMessage: error.message,
          });
        });
    } catch (error) {
      dispatch({
        type: GET_TRADE_DATA_FAILED,
        payload: error.message,
      });
    }
  };
}
export function getTransactionData() {
  return async (dispatch) => {
    try {
      api
        .post("transactionData/get")
        .then((response) => {
          dispatch({
            type: GET_TRANSACTION_DATA,
            payload: response.data.data,
          });
        })
        .catch((error) => {
          toast.error(error.message);
          dispatch({
            type: GET_TRANSACTION_DATA_FAILED,
            errorMessage: error.message,
          });
        });
    } catch (error) {
      dispatch({
        type: GET_TRANSACTION_DATA_FAILED,
        payload: error.message,
      });
    }
  };
}
export function getCoinDetailsData() {
  return (dispatch) => {
    try {
      api
        .post("coindetail/get")
        .then((response) => {
          dispatch({
            type: GET_COINDETAILS_DATA,
            payload: response.data.data,
          });
        })
        .catch((error) => {
          toast.error(error.message);
          dispatch(isLoading(false));
          dispatch({
            type: GET_COINDETAILS_DATA_FAILED,
            errorMessage: error.message,
          });
        });
    } catch (error) {
      dispatch(isLoading(false));
      dispatch({
        type: GET_COINDETAILS_DATA_FAILED,
        payload: error.message,
      });
    }
  };
}
export function getPaymentDetails() {
  return (dispatch) => {
    try {
      api
        .post("qrcode/get", { isActive: true })
        .then((response) => {
          dispatch({
            type: GET_PAYMENTDETAILS_DATA,
            payload: response.data.data.data,
          });
        })
        .catch((error) => {
          toast.error(error.message);
          dispatch(isLoading(false));
          dispatch({
            type: GET_PAYMENTDETAILS_DATA_FAILED,
            errorMessage: error.message,
          });
        });
    } catch (error) {
      dispatch(isLoading(false));
      dispatch({
        type: GET_COINDETAILS_DATA_FAILED,
        payload: error.message,
      });
    }
  };
}
