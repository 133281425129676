import {
  IS_TRANSACTION_LOADING,
  GET_COINDETAILS_DATA,
  GET_COINDETAILS_DATA_FAILED,
  GET_TRANSACTION_DATA_FAILED,
  GET_TRANSACTION_DATA,
  GET_DEPOSIT_SUCCESS,
  GET_DEPOSIT_FAILED,
  GET_WITHDRAWAL_SUCCESS,
  GET_WITHDRAWAL_FAILED,
  GET_TRANSACTION_HISTORY_DATA_SUCCESS,
  GET_TRANSACTION_DATA_HISTORY_FAILED,
  GET_PAYMENTDETAILS_DATA,
  GET_PAYMENTDETAILS_DATA_FAILED,
  GET_FUNDSDETAILS_DATA_FAILED,
  GET_FUNDSDETAILS_DATA,
  GET_TRADE_DATA_SUCCESS,
  GET_TRADE_DATA_FAILED,
  IS_TRADE_SUCCESS,
  GET_FUNDDETAILS_SUCCESS,
  GET_FUNDDETAILS_FAILED,
} from "../constant";

const initialstate = {
  transactionLoading: false,
  error: "",
  coinDetails: [],
  transactionData: [],
  updatedUser: null,
  verificationResponse: null,
  changePasswordRes: null,
  transactionDetails: null,
  deletedTransactionDetail: null,
  withdrawalDetails: null,
  updatedWithdrawalDetails: null,
  deletedWithdrawalResponse: null,
  depositResponse: null,
  transactionCount: 0,
  depositeRecords: [],
  withdrawRecords: [],
  otherRecords: [],
  transactionHistory: [],
  paymentData: [],
  fundsDetails: [],
  isTradeSuccess: false,
};
const TransactionReducer = (state = initialstate, action) => {
  switch (action.type) {
    case IS_TRANSACTION_LOADING:
      return { ...state, transactionLoading: action.isLoading };
    case GET_COINDETAILS_DATA:
      return {
        ...state,
        coinDetails: action.payload.data,
        loading: false,
      };
    case GET_COINDETAILS_DATA_FAILED:
      return {
        ...state,
        error: action.errorMessage,
        loading: false,
      };

    case GET_TRANSACTION_DATA:
      return {
        ...state,
        transactionData: action.payload.data,
        transactionCount: action.payload.count,
        transactionLoading: false,
      };
    case GET_TRANSACTION_DATA_FAILED:
      return {
        ...state,
        error: action.errorMessage,
        transactionLoading: false,
      };

    case GET_FUNDDETAILS_SUCCESS:
      return {
        ...state,
        otherRecords: action.payload,
      };
    case GET_FUNDDETAILS_FAILED:
      return {
        ...state,
        error: action.errorMessage,
        transactionLoading: false,
      };

    case GET_DEPOSIT_SUCCESS:
      return {
        ...state,
        depositeRecords: action.payload,
      };
    case GET_DEPOSIT_FAILED:
      return {
        ...state,
        error: action.errorMessage,
        transactionLoading: false,
      };
    case GET_WITHDRAWAL_SUCCESS:
      return {
        ...state,
        withdrawRecords: action.payload,
        transactionLoading: false,
      };
    case GET_WITHDRAWAL_FAILED:
      return {
        ...state,
        error: action.errorMessage,
        transactionLoading: false,
      };
    case GET_TRANSACTION_HISTORY_DATA_SUCCESS:
      return {
        ...state,
        transactionHistory: action.payload,
        transactionLoading: false,
      };
    case GET_TRANSACTION_DATA_HISTORY_FAILED:
      return {
        ...state,
        error: action.errorMessage,
        transactionLoading: false,
      };
    case GET_TRADE_DATA_SUCCESS:
      return {
        ...state,
        isTradeSuccess: true,
      };
    case GET_TRADE_DATA_FAILED:
      return {
        ...state,
        error: action.errorMessage,
        isTradeSuccess: false,
      };
    case IS_TRADE_SUCCESS:
      return {
        ...state,
        isTradeSuccess: false,
      };
    case GET_PAYMENTDETAILS_DATA:
      return {
        ...state,
        paymentData: action.payload,
        transactionLoading: false,
      };
    case GET_PAYMENTDETAILS_DATA_FAILED:
      return {
        ...state,
        error: action.errorMessage,
        transactionLoading: false,
      };
    case GET_FUNDSDETAILS_DATA:
      return {
        ...state,
        fundsDetails: action.payload,
        transactionLoading: false,
      };
    case GET_FUNDSDETAILS_DATA_FAILED:
      return {
        ...state,
        error: action.errorMessage,
        transactionLoading: false,
      };
    default:
      return state;
  }
};

export default TransactionReducer;
