/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef } from "react";
import HeaderTitle from "../../component/header-title";
import { useDispatch } from "react-redux";
import { useState } from "react";
import { claimTasks, getReferralTasks } from "../../redux/actions";

const Rewards = () => {
  const user = JSON.parse(localStorage.getItem("user"));
  const { numberOfActiveUsersReferred } = user;
  const dispatch = useDispatch();
  const [referralTasks, setReferralTasks] = useState("");
  const [dailyTasks, setDailyTasks] = useState("");
  const [taskPhotos, setTaskPhotos] = useState({});
  const [taskLinks, setTaskLinks] = useState({});

  const fileInputRefs = useRef({});

  const uploadImage = (taskId, photo) => {
    setTaskPhotos((prevPhotos) => ({
      ...prevPhotos,
      [taskId]: photo,
    }));
  };

  const handleLinkChange = (taskId, link) => {
    setTaskLinks((prevLinks) => ({
      ...prevLinks,
      [taskId]: link,
    }));
  };

  const handleRemoveScreenshot = (taskId) => {
    setTaskPhotos((prevPhotos) => {
      const updatedPhotos = { ...prevPhotos };
      delete updatedPhotos[taskId];
      return updatedPhotos;
    });
  };

  const handleButtonClick = (taskId) => {
    fileInputRefs.current[taskId].click();
  };

  useEffect(() => {
    dispatch(getReferralTasks(setReferralTasks, setDailyTasks));
  }, []);

  const handleClaim = (id, type) => {
    if (type === "daily" && (!taskPhotos[id] || !taskLinks[id])) {
      return;
    }
    let body = {};
    if (type === "daily") {
      const formData = new FormData();
      formData.append("type", type);
      formData.append("file", taskPhotos[id]);
      formData.append("link", taskLinks[id]);
      body = formData;
    }
    dispatch(claimTasks(id, type, body, setReferralTasks, setDailyTasks));
  };

  return (
    <div style={{ width: "100vw" }}>
      <HeaderTitle title={"Rewards"} />
      <div className="container" style={{ marginTop: "80px" }}>
        <div className="row align-items-center">
          <div className="text-center">
            <h6 className="mb-0 ">Daily Tasks</h6>
          </div>
        </div>
      </div>

      {Array.isArray(dailyTasks) ? (
        dailyTasks.length === 0 ? (
          <div
            className="d-flex justify-content-center bg-white p-3 fs-6 mt-3"
            style={{}}
          >
            No Daily tasks to display
          </div>
        ) : (
          dailyTasks.map((task) => (
            <div
              className="service-box p-3 my-3 bg-white shadow-sm rounded mx-4"
              key={task._id}
            >
              <div className="mb-3 mx-0">
                <div className="logo-text fw-bold fs-5">{task.title}</div>
              </div>
              <div className="d-flex flex-column w-100 row mt-2 mb-2 align-items-start">
                <div className="col-8 px-3 w-100">
                  <h3 className="text-muted">Reward: ${task.reward}</h3>
                </div>

                <div
                  className="col-2 px-3 w-100 "
                  style={{ marginTop: "-0.25rem" }}
                >
                  <input
                    className="border-0 text-white btn-sm px-4 rounded-pill"
                    type="file"
                    accept=".jpeg, .jpg, .png, .gif"
                    onChange={(e) => {
                      uploadImage(task._id, e.target.files[0]);
                    }}
                    ref={(input) => (fileInputRefs.current[task._id] = input)}
                    style={{
                      backgroundColor:
                        task.status === "Pending" ? "grey" : "#38B6FF",
                      display: "none",
                    }}
                  />
                  <p className="mb-2 fs-6 mt-3">
                    <span className="fw-bold">Goal: </span>
                    {task.goal}
                  </p>
                  {task.status !== "Pending" && task.status !== "Approved" ? (
                    <div>
                      <div className="d-flex flex-wrap w-100 ">
                        <label
                          htmlFor="link"
                          className="fs-6 font-weight-500 w-100 mb-1"
                        >
                          Share post link
                        </label>

                        <input
                          type="text"
                          name="link"
                          value={taskLinks[task._id] || ""}
                          placeholder="Paste Link here"
                          style={{
                            color: "rgba(0, 0, 0, 0.7)",
                            placeholder: {
                              color: "rgba(0, 0, 0, 0.5)",
                            },
                          }}
                          className="custom_input m-0 mb-3 w-100 fs-6 bg-light rounded-1"
                          onChange={(e) =>
                            handleLinkChange(task._id, e.target.value)
                          }
                        />
                      </div>
                      {taskPhotos[task._id] && (
                        <div className="d-flex justify-content-center">
                          <img
                            src={URL.createObjectURL(taskPhotos[task._id])}
                            alt={`Task ${task._id}`}
                            width={80}
                            height={80}
                          />
                        </div>
                      )}
                      <div className="d-flex justify-content-center pt-2">
                        {!taskPhotos[task._id] && task.status !== "Pending" ? (
                          <button
                            className="border-0 text-white btn-sm px-4 rounded-pill"
                            onClick={() => handleButtonClick(task._id)}
                            style={{
                              backgroundColor:
                                task.status === "Pending" ? "grey" : "#38B6FF",
                            }}
                          >
                            upload Screenshot
                          </button>
                        ) : (
                          <button
                            className="border-0 text-white btn-sm px-4 rounded-pill"
                            onClick={() => handleRemoveScreenshot(task._id)}
                            style={{
                              backgroundColor:
                                task.status === "Pending" ? "grey" : "#38B6FF",
                            }}
                          >
                            remove Screenshot
                          </button>
                        )}
                      </div>
                      <div className="d-flex justify-content-center pt-2">
                        <button
                          className="border-0 text-white btn-sm px-4 rounded-pill"
                          onClick={() => handleClaim(task._id, task.type)}
                          disabled={
                            !(taskPhotos[task._id] && taskLinks[task._id])
                          }
                          style={{
                            backgroundColor: !(
                              taskPhotos[task._id] && taskLinks[task._id]
                            )
                              ? "grey"
                              : "#38B6FF",
                          }}
                        >
                          Submit
                          {console.log(
                            task.title,
                            !(taskPhotos[task._id] && taskLinks[task._id])
                          )}
                        </button>
                      </div>
                    </div>
                  ) : (
                    <div className="d-flex justify-content-center pt-2">
                      Status: {task.status}
                    </div>
                  )}
                </div>
              </div>

              <div className="d-flex justify-content-center align-items-center">
                <div className="progress" style={{ width: "80%" }}>
                  <div
                    className="progress-bar theme-bg"
                    role="progressbar"
                    style={{
                      width:
                        task.status === "Pending" || task.status === "Approved"
                          ? "100%"
                          : "0",
                    }}
                    aria-valuenow={5}
                    aria-valuemin={0}
                    aria-valuemax={100}
                  />
                </div>
                <div className="ms-4">
                  {task.status === "Pending" || task.status === "Approved"
                    ? "1"
                    : "0"}{" "}
                  /1
                </div>
              </div>
            </div>
          ))
        )
      ) : (
        ""
      )}
      <div className="pb-3">
        <div className="container mt-3">
          <div className="row align-items-center">
            <div className="text-center">
              <h6 className="mb-0 ">Referral Tasks</h6>
            </div>
          </div>
        </div>
        {Array.isArray(referralTasks) ? (
          referralTasks.length === 0 ? (
            <div
              className="d-flex justify-content-center bg-white p-3 fs-6 mt-3"
              style={{}}
            >
              No Referral tasks to display
            </div>
          ) : (
            referralTasks.map((task) => (
              <div
                className="service-box p-3 my-3 bg-white shadow-sm rounded mx-4"
                key={task._id}
              >
                <div className="mb-3 mx-0">
                  <div className="logo-text fw-bold fs-5">{task.title}</div>
                </div>
                <div className="d-flex row mt-2 mb-2 align-items-start">
                  <div className="col-8 px-3">
                    <h3 className="text-muted">Reward: ${task.reward}</h3>
                  </div>
                  <div className="col-2 px-3" style={{ marginTop: "-0.25rem" }}>
                    <button
                      className="border-0 text-white btn-sm px-4 rounded-pill"
                      onClick={() => handleClaim(task._id, task.type)}
                      disabled={
                        numberOfActiveUsersReferred <
                          task.activeUsersRequired || task.claimed
                      }
                      style={{
                        backgroundColor:
                          numberOfActiveUsersReferred <
                            task.activeUsersRequired || task.claimed
                            ? "grey"
                            : "#38B6FF",
                      }}
                    >
                      {task.claimed ? "Claimed" : "Claim"}
                    </button>
                  </div>
                </div>

                <p className="mb-3 fs-6">
                  <span className="fw-bold">Goal: </span>
                  {task.goal}
                </p>
                <div className="d-flex justify-content-center align-items-center">
                  <div className="progress" style={{ width: "80%" }}>
                    <div
                      className="progress-bar theme-bg"
                      role="progressbar"
                      style={{
                        width: `${
                          (numberOfActiveUsersReferred /
                            task.activeUsersRequired) *
                          100
                        }%`,
                      }}
                      aria-valuenow={5}
                      aria-valuemin={0}
                      aria-valuemax={100}
                    />
                  </div>
                  <div className="ms-4">
                    {Math.min(
                      numberOfActiveUsersReferred,
                      task.activeUsersRequired
                    )}
                    /{task.activeUsersRequired}
                  </div>
                </div>
              </div>
            ))
          )
        ) : (
          ""
        )}
      </div>
    </div>
  );
};

export default Rewards;
