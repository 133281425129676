/* eslint-disable react-hooks/exhaustive-deps */
import { Link, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { getCoinDetailsData } from "../../redux/actions";
import Nav from "../../component/nav";
import Footer from "../../component/footer";
import Banner1 from "../../img/landing_image.png";
import LandingPage2 from "../../img/landingPage2.png";
import Logo from "../../img/logo_new.png";
import "./custom_styles.css";
import FAQ from "../../component/faq";
import Markets from "../../component/markets";
import { SocialIcon } from "react-social-icons";

function Landing() {
  const [registerEmail, setRegisterEmail] = useState("");
  var userDetails = localStorage.getItem("user");
  const navigate = useNavigate();
  const dispatch = useDispatch();
  useEffect(() => {
    if (userDetails) {
      navigate("/home", { replace: true });
    }
  }, []);

  const openInNewTab = (url) => {
    window.open(url, "_blank", "noreferrer");
  };

  const scrollToFAQ = () => {
    const faqSection = document.getElementById("faq");
    if (faqSection) {
      faqSection.scrollIntoView({ behavior: "smooth" });
    }
  };
  useEffect(() => {
    dispatch(getCoinDetailsData());
  }, []);

  return (
    <div style={{ width: "100vw", backgroundColor: "white" }}>
      <Nav scrollToFAQ={scrollToFAQ} />
      <div className="d-flex landing_header bg-white" style={{ marginTop: "80px" }}>
        <div className="  mx-4">
          <p className=" landing_title w-full">
            <strong className="colr">METAFOXBOT</strong> is the Most
            Artificially Intelligent Robot Ever Developed.
          </p>
          <p className="landing_text px-3 w-full">
            Register now and get upto 1000 USDT in Rewards
          </p>

          <div className="form-group">
            <input
              className="form-field"
              type="email"
              placeholder="Email"
              value={registerEmail}
              onChange={(e) => setRegisterEmail(e.target.value)}
            />
            <span
              className=""
              style={{ cursor: "pointer" }}
              onClick={() =>
                navigate("/register", { state: { email: registerEmail } })
              }
            >
              Register
            </span>
          </div>
          <div className="d-flex justify-content-center align-items-center">
            <p className="texxt"> Or continue with</p>
          </div>
          <div className=" d-flex justify-content-center align-items-center button_group">
            <button
              type="button"
              className="landing_button"
              onClick={() => {
                navigate("/register");
              }}
            >
              <img
                className="mx-1 google_1"
                src="https://img.icons8.com/color/48/google-logo.png"
                alt="google-logo"
              />
              <img
                className="google_2 mx-1"
                src="https://img.icons8.com/material-sharp/24/google-logo.png"
                alt="google"
              />
              Google
            </button>
            <button
              type="button"
              className="landing_button"
              onClick={() => {
                navigate("/register");
              }}
            >
              <img
                className="mx-1 mb-1 apple"
                src="https://img.icons8.com/ios-glyphs/30/mac-os.png"
                alt="apple"
              />
              Apple
            </button>
          </div>
          <div className="d-flex justify-content-center align-items-center">
            <p className="p-3  texxt">
              {" "}
              Already Have an Account?{" "}
              <span
                type="button"
                onClick={() => {
                  navigate("/login");
                }}
                className="blue landing_login"
              >
                {" "}
                Login{" "}
              </span>
            </p>
          </div>
        </div>
      </div>
      <div className="d-flex banner justify-content-center align-items-center bg-white">
        <img src={Banner1} alt="Banner_img" width="90%" />
      </div>

      {/*TODO: Add media query break point to text*/}

      <div
        className="mx-auto pt-5 px-4 px-sm-5 bg-white fs-6 lh-sm"
        style={{
          color: "rgb(156,163,175)",
          textAlign: "justify",
        }}
      >
        <div className="w-50" style={{ height: "80px", marginLeft: "-9px" }}>
          <img src={Logo} alt="" width={"180rem"} />
        </div>
        Where Crypto Investing Meets Confidence! Explore seamless cryptocurrency
        staking on our platform, tailored for both beginners and seasoned
        investors. With our exclusive No Risk, No Loss guarantee, your
        investments are safeguarded, ensuring a worry-free experience amidst
        market fluctuations. Simplify your journey into the crypto space with
        our user-friendly interface and transparent processes.{" "}
        <Link to={"/register"} className="fw-bold">
          Join MetaFoxBot{" "}
        </Link>
        today, where your financial well-being is our top priority. Elevate your
        investment with secure stakes and unparalleled peace of mind.
      </div>
      <Markets />
      <div className="d-flex banner justify-content-center align-items-center bg-white">
        <img src={LandingPage2} alt="Banner_img" width="90%" />
      </div>
      <Footer />
    </div>
  );
}

export default Landing;
