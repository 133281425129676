import UsdtIcon from "../../img/usdt-logo.png";
import ChartLineIcon from "../../img/chart-line-up.svg";
import ClockIcon from "../../img/clock.svg";
import FacemeshIcon from "../../img/face-meh.svg";
import WalletIcon from "../../img/Wallet.svg";
import "./buy.css";
import { useState } from "react";
import { toast } from "react-toastify";

function Buy() {
  const [amount, setAmount] = useState(0);
  const [totalAmount, setTotalAmount] = useState(0);
  const [profit, setProfit] = useState(0);

  const handleAddamount = (value, percentage) => {
    setAmount(value);
    let text = (value * percentage) / 100;
    setProfit(text);
  };

  const handleSubmit = () => {
    if (totalAmount > amount) {
      toast.success("amount added Successfully", {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: true,
        closeOnClick: false,
        pauseOnHover: false,
        draggable: false,
        progress: undefined,
      });
    } else {
      toast.error("Insufficient amount", {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: true,
        closeOnClick: false,
        pauseOnHover: false,
        draggable: false,
        progress: undefined,
      });
    }
  };

  return (
    <div style={{ width: "290px", maxWidth: "300px" }}>
      <div className="border-bottom px-3 mb-3">
        <div className="row ">
          <div className="col-8 px-0">
            <ul className="list-unstyled">
              <li className="d-inline-block pe-2 lg-ic">
                <img src={UsdtIcon} width="60px" alt="" />
              </li>
              <li className="d-inline-block ps-2">
                <img height={16} src={ChartLineIcon} alt="" />
              </li>
              <li className="d-inline-block  ps-2">
                <img height={16} src={ClockIcon} alt="" />
              </li>
              <li className="d-inline-block  ps-2">
                <img height={16} src={FacemeshIcon} alt="" />
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div className="d-flex align-items-center">
        <img src={WalletIcon} width="20px" alt="" />
        <div className="d-flex align-items-center mt-2">
          <h4 className="mx-2 mb-2  text-muted">Available Balance:</h4>
          <h4>$0</h4>
        </div>
      </div>
      <p>Enter Amount</p>
      <p>
        <input type="text" className="form-control" value={amount} />
      </p>
      <div className="my-3 mb-3 d-flex">
        <p
          className="btn btn-sm btn-outline-light rounded-pill me-3 active"
          onClick={() => handleAddamount(100, 1)}
        >
          +$100
        </p>
        <p
          className="btn btn-sm btn-outline-light rounded-pill me-3 active"
          onClick={() => handleAddamount(500, 1)}
        >
          +$500
        </p>
        <p
          className="btn btn-sm btn-outline-light rounded-pill me-3 active"
          onClick={() => handleAddamount(1000, 1)}
        >
          +$1000
        </p>
        <p
          className="btn btn-sm btn-outline-light rounded-pill me-3 active"
          onClick={() => handleAddamount(5000, 1)}
        >
          +$5000
        </p>
      </div>
      <div>
        <div className="d-flex align-items-center mt-2 mb-2">
          <h4 className="mx-2 mb-2 text-muted">Total Profit:</h4>
          <h4>
            {amount}*1% =${profit}
          </h4>
        </div>
      </div>

      <div className="text-center">
        <button
          className="btn btn-sm theme-btn w-50 py-2"
          onClick={handleSubmit}
        >
          Submit
        </button>
      </div>
    </div>
  );
}
export default Buy;
