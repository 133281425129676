/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import HeaderTitle from "../../component/header-title";
import FooterTab from "../../component/footer-tab";
import { getUserTeam } from "../../redux/actions";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import moment from "moment";

function MyTeam() {
  const dispatch = useDispatch();
  const myTeam = useSelector((state) => state.user.myTeam);
  const [totalIncome, setTotalIncome] = useState(0);
  const userDetails = useSelector((state) => state.user.userData);

  useEffect(() => {
    dispatch(getUserTeam());
  }, []);

  useEffect(() => {
    if (myTeam && myTeam.length) {
      let totalIncome = myTeam.reduce((accumulator, curValue) => {
        return accumulator + curValue.todayProfit;
      }, 0);
      setTotalIncome(totalIncome);
    }
  }, [myTeam]);

  return (
    <div>
      <HeaderTitle title={"My Team"} />
      <div className="contant space">
        <div className="team-dtl pt-4 px-3">
          <div className="tab-content mt-3 px-4 pb-3">
            <div
              role="tabpanel"
              className="tab-pane fade show active"
              id="first"
            >
              <div className="row my-3">
                <div style={{ textAlign: "center" }} className="col-12">
                  <p className="text-white mb-1">Total Incomes</p>
                  <h3 className="text-white">
                    $
                    {userDetails?.teamIncome
                      ? userDetails?.teamIncome.toFixed(3)
                      : 0}
                  </h3>
                </div>
              </div>
            </div>
            <div role="tabpanel" className="tab-pane fade" id="second">
              LV2
            </div>
            <div role="tabpanel" className="tab-pane fade" id="thirld">
              LV3
            </div>
          </div>
        </div>
        <div className="info-content p-3">
          <div className="info-box bg-white rounded p-2 my-bx-shadow">
            <div className="row align-items-center">
              <div className="col-3">
                <p className="mb-0">ID</p>
              </div>
              <div className="col-2">
                <p className="mb-0">Grade</p>
              </div>
              <div className="col-3">
                <p className="mb-0">Nickname</p>
              </div>
              <div className="col-4">
                <p className="mb-0 d-flex justify-content-end">
                  Registration Time
                </p>
              </div>
            </div>
          </div>
          <div className="fund-dtl p-2">
            {Array.isArray(myTeam) &&
              myTeam.map((ele, index) => (
                <div
                  className="row align-items-center"
                  style={{ padding: 15 }}
                  key={index}
                >
                  <div className="col-3">
                    <p className="mb-0 text-truncate">{ele._id}</p>
                  </div>
                  <div className="col-2">
                    <p className="mb-0">Lv{ele.Updatelevel}</p>
                  </div>
                  <div className="col-3">
                    <p className="mb-0">
                      {ele && ele.userId ? ele.userId : "N/A"}
                    </p>
                  </div>
                  <div className="col-4">
                    <p className="mb-0 d-flex justify-content-end">
                      {moment(`${ele.addedAt}`).format("DD/MM/YYYY hh:mm")}
                    </p>
                  </div>
                </div>
              ))}
          </div>
        </div>
      </div>
      <FooterTab to="/myteam" />
    </div>
  );
}

export default MyTeam;
