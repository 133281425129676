/* eslint-disable react-hooks/exhaustive-deps */
import "./style.css";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import WalletIcon from "../../img/Wallet.svg";
import HeaderTitle from "../../component/header-title";
import DepositeModal from "../depositemodal";
import { Modal } from "react-responsive-modal";
import { useNavigate } from "react-router-dom";
import { getPaymentDetails } from "../../redux/actions";
import { useDispatch } from "react-redux";

function AddAmount() {
  const navigate = useNavigate();
  const [amount, setAmount] = useState(0);
  const [selectedOption, setSelectedOption] = useState("TRC20");
  const userDetails = JSON.parse(localStorage.getItem("user"));
  const [open, setOpen] = useState(false);
  const dispatch = useDispatch();

  const onCloseModal = () => setOpen(false);

  const handleAddamount = (value, percentage) => {
    setAmount(value);
  };
  const handleOptionChange = (event) => {
    setSelectedOption(event.target.value);
  };

  useEffect(() => {
    dispatch(getPaymentDetails());
  }, []);

  const handleSubmit = () => {
    // toast.success('amount added Successfully', {
    //     position: "top-right",
    //     autoClose: 2000,
    //     hideProgressBar: true,
    //     closeOnClick: false,
    //     pauseOnHover: false,
    //     draggable: false,
    //     progress: undefined,
    // });
    if (amount >= 10) {
      if (selectedOption === "TRC20") {
        navigate("/qrdeposit", { state: { amount: amount } });
      } else {
        navigate("/impsdeposit", { state: { amount: amount * 89 } });
      }
    } else {
      toast.warn(`Add $10 minimum amount`, {
        position: "top-right",
        autoClose: 1000,
        hideProgressBar: true,
        closeOnClick: false,
        pauseOnHover: false,
        draggable: false,
        progress: undefined,
      });
    }

    // onOpenModal();
  };

  const handleUserInput = (e) => {
    const value = e.target.value;
    setAmount(value);
  };

  return (
    <div className="bg-white heightDiv">
      <HeaderTitle title={"Add Amount"} />
      <div className="mx-3 mt-3 service-box p-3 space">
        <div className="d-flex align-items-center">
          <img src={WalletIcon} width="20px" alt="" />
          <div className="d-flex align-items-center mt-2">
            <h4 className="mb-0 mx-2 mb-2  text-muted">Available Balance:</h4>
            <h4>${userDetails?.availableBalance.toFixed(3)}</h4>
          </div>
        </div>
        <p>Enter Amount</p>
        <p>
          <input
            type="number"
            onChange={handleUserInput}
            className="form-control"
            value={amount}
          />
        </p>
        <h4 className="trade_label">Minimun Amount: $10</h4>
        <div className="my-3 mb-3 d-flex">
          <p
            className="btn btn-sm btn-outline-light rounded-pill me-3 active"
            onClick={() => handleAddamount(100)}
          >
            +$100
          </p>
          <p
            className="btn btn-sm btn-outline-light rounded-pill me-3 active"
            onClick={() => handleAddamount(500)}
          >
            +$500
          </p>
          <p
            className="btn btn-sm btn-outline-light rounded-pill me-3 active"
            onClick={() => handleAddamount(1000)}
          >
            +$1000
          </p>
          <p
            className="btn btn-sm btn-outline-light rounded-pill me-3 active"
            onClick={() => handleAddamount(5000)}
          >
            +$5000
          </p>
          <p
            className="btn btn-sm btn-outline-light rounded-pill me-3 active"
            onClick={() => handleAddamount(10000)}
          >
            +$10000
          </p>
        </div>
        <div className="d-flex flex-row">
          <p className="">Recharge Method : </p>

          <label className="mx-4">
            <input
              type="radio"
              value="TRC20"
              checked={selectedOption === "TRC20"}
              onChange={handleOptionChange}
              className="mx-1"
            />
            TRC20
          </label>

          <label className="mx-4">
            <input
              className="mx-1"
              type="radio"
              value="imps"
              checked={selectedOption === "imps"}
              onChange={handleOptionChange}
            />
            IMPS
          </label>
          <br />
        </div>
        <div className="text-center">
          <button
            className="btn btn-sm theme-btn w-50 py-2"
            onClick={handleSubmit}
          >
            Continue to pay
          </button>
        </div>
      </div>
      <Modal open={open} onClose={onCloseModal} center>
        <DepositeModal />
      </Modal>
    </div>
  );
}

export default AddAmount;
