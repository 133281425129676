/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import Bitcoin from "../../img/bit-logo.png";
import BNB from "../../img/bnb_logo.png";
import Eth from "../../img/eth-logo.png";
import Doge from "../../img/dogecoin_logo.png";
import USDT from "../../img/usdt-logo-1.png";
import axios from "axios";

const Markets = () => {
  const [coins, setCoins] = useState([]);

  const symbolMapping = {
    BTCUSDT: { symbol: "BTC", src: Bitcoin, alt: "bitcoin", name: "Bitcoin" },
    ETHUSDT: { symbol: "ETH", src: Eth, alt: "ethereum", name: "Ethereum" },
    BNBUSDT: { symbol: "BNB", src: BNB, alt: "bnb", name: "BNB" },
    DOGEUSDT: { symbol: "DOGE", src: Doge, alt: "doge", name: "DOGE" },
  };

  const updateState = (newData) => {
    setCoins((prev) => {
      const newDataArray = Object.entries(newData).map(([symbol, data]) => ({
        symbol: symbolMapping[symbol].symbol,
        src: symbolMapping[symbol].src,
        alt: symbolMapping[symbol].alt,
        name: symbolMapping[symbol].name,
        ...data,
      }));

      return [...prev, ...newDataArray];
    });
  };

  const getMarketData = async () => {
    const { data } = await axios.get(
      'https://api.binance.com/api/v3/ticker?symbols=["BTCUSDT","BNBUSDT","DOGEUSDT","ETHUSDT"]'
    );
    // eslint-disable-next-line array-callback-return
    data.map((item) => {
      updateState({
        [item.symbol]: {
          price: item.lastPrice,
          percentageChange: item.priceChangePercent,
        },
      });
    });
    setCoins((prev) => {
      return [
        ...prev,
        {
          symbol: "USDT",
          src: USDT,
          alt: "usdt",
          name: "USDT",
          price: 1,
          percentageChange: 0,
        },
      ];
    });
  };

  useEffect(() => {
    getMarketData();
  }, []);

  return (
    <div className="d-flex bg-white flex-column justify-content-center align-items-center">
      <div className="">
        <h1 className=" landing_heading"> Markets </h1>
      </div>
      {coins.map((coin, index) => {
        return (
          <div
            key={index}
            className="d-flex w-100 align-items-center justify-content-center"
          >
            <p className="coin">
              <span>
                <img
                  width="24"
                  height="24"
                  src={coin.src}
                  alt={coin.alt}
                  className="mb-1 mx-1 "
                />
                {coin.symbol}
              </span>{" "}
              {coin.name}
            </p>
            <p className="price">${parseFloat(coin.price).toFixed(2)}</p>
            <p
              className={
                coin.percentageChange < 0 ? "current-red" : "current-green"
              }
            >
              {coin.percentageChange >= 0 && "+"}
              {parseFloat(coin.percentageChange).toFixed(2)}%
            </p>
          </div>
        );
      })}
    </div>
  );
};

export default Markets;
