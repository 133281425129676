import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import Logo from "../../img/logo_new.png";
import "./nav.css";

function Header({ scrollToFAQ }) {
  const navigate = useNavigate();

  const [isOpen, setIsOpen] = useState(false);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  return (
    <header className="fixed-top ">
      <div className="container ">
        <div className="d-flex flex-row align-items-center justify-content-between">
          <div
            className="nav_logo"
            onClick={() => navigate("/")}
            style={{ cursor: "pointer" }}
          >
            <img src={Logo} className=" " style={{}} alt="logo" />
          </div>
          <div className="text-center d-flex flex-row align-items-center justify-content-between p-0 m-0">
            <button
              className="nav_btn m-0"
              onClick={() => navigate("/login", { replace: true })}
            >
              {" "}
              Login
            </button>
            <button
              className="nav_btn login_button_nav"
              onClick={() => navigate("/register")}
            >
              {" "}
              Sign up
            </button>

            <div className="custom-dropdown">
              <div className="dropdown-header" onClick={toggleDropdown}>
                <img
                  width="30"
                  height="30"
                  src="https://img.icons8.com/badges/48/list.png"
                  alt="list"
                />
              </div>
              {isOpen && (
                <div className="dropdown-options">
                  <div
                    className="option"
                    onClick={() => {
                      toggleDropdown();
                      navigate("/register");
                    }}
                  >
                    Sign up
                  </div>
                  {scrollToFAQ && (
                    <div
                      className="option"
                      onClick={() => {
                        toggleDropdown();
                        scrollToFAQ();
                      }}
                    >
                      FAQ
                    </div>
                  )}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </header>
  );
}

export default Header;
