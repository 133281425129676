/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react-hooks/exhaustive-deps */
import Userprofile from "../../img/user.png";
import HeaderTitle from "../../component/header-title";
import MoneyDeposit from "../../img/money-in-out.svg";
import PersonalInfoIcon from "../../img/contact-card.png";
import CardIcon from "../../img/card.png";
import KycIcon from "../../img/kyc.png";
import RechargeIcon from "../../img/todo-list.png";
import WalletIcon from "../../img/wallet.png";
import FundIcon from "../../img/funds.png";
import MyTeamIcon from "../../img/crowd--v2.png";
import ShareIcon from "../../img/share.png";
import CopyIcon from "../../img/copy-white.svg";
import TotalRevenIcon from "../../img/eye-white.svg";
import FooterTab from "../../component/footer-tab";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { userLogout, getUser } from "../../redux/actions";
import "./style.css";
import { useEffect, useState } from "react";

function Profile() {
  const navigate = useNavigate();
  const [user, setUser] = useState(null);
  const userDetails = JSON.parse(localStorage.getItem("user"));
  const dispatch = useDispatch();

  const handleCopyClipart = (value) => {
    navigator.clipboard.writeText(value);
    toast.success("Invitation code is successfully copied", {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: true,
      closeOnClick: false,
      pauseOnHover: false,
      draggable: false,
      progress: undefined,
    });
  };

  useEffect(() => {
    const userData = JSON.parse(localStorage.getItem("user"));
    setUser(userData);
    dispatch(getUser({ emailId: userData?.emailId }));
  }, []);

  const gotoLogout = () => {
    dispatch(userLogout());
    localStorage.clear();
    navigate("/");
  };

  return (
    <div>
      <HeaderTitle title={"Profile"} />
      <div className="contant space">
        <div className="padd-around ">
          <section className="profile-section2">
            <div className="container mb-3">
              <div className="row align-items-center">
                <div className="col-2">
                  <div className="user">
                    <img src={Userprofile} className="img-fluid" alt="" />
                  </div>
                </div>
                {userDetails && (
                  <div className="col-9 ml-3">
                    <h3 className="text-white mb-0">{userDetails.userId}</h3>
                    <p className="d-inline-flex align-items-center text-white mb-0">
                      Invitation code:{" "}
                      <span className="mx-2">{userDetails.referalCode}</span>
                      <img
                        src={CopyIcon}
                        height={22}
                        className="opacity-5"
                        onClick={() => handleCopyClipart(user.referalCode)}
                        alt=""
                        style={{ cursor: "pointer" }}
                      />
                    </p>
                  </div>
                )}
              </div>
            </div>
            <div className="container ">
              <div className="row">
                <div className="col-6">
                  <p className="mb-0 ps-2">
                    Total Revenue USDT{" "}
                    <img
                      src={TotalRevenIcon}
                      height={15}
                      className="d-inline opacity-5"
                      alt=""
                    />
                  </p>
                </div>
              </div>
              <div className="row">
                <div className="col-6">
                  <h2 className="mt-3 text-white">
                    $
                    {userDetails && userDetails.availableBalance
                      ? userDetails.availableBalance.toFixed(3)
                      : 0}{" "}
                    <br />
                    <p className="pt-2">
                      $
                      {userDetails && userDetails.availableBalance
                        ? userDetails.availableBalance.toFixed(3)
                        : 0}
                    </p>
                  </h2>
                </div>
              </div>
              <div className="row">
                <div className="col-7">
                  <p className="mb-0 ">Today Profit (USDT) </p>
                  <h2 className="text-white pt-1 mb-0">
                    $
                    {userDetails && userDetails.todayProfit
                      ? userDetails.todayProfit.toFixed(3)
                      : 0}
                  </h2>
                </div>
                <div className="col-5">
                  <p className="mb-0 ">Trade (USDT)</p>
                  <h2 className="text-white pt-1 mb-0">
                    $
                    {userDetails && userDetails.tradeUsdt
                      ? userDetails.tradeUsdt.toFixed(3)
                      : 0}
                  </h2>
                </div>
              </div>
            </div>
          </section>
        </div>

        <section className="pay my-3">
          <div className="container">
            <div className="row justify-content-around">
              <div className="col-5">
                <Link to="/addamount">
                  <h2 className="d-flex pay1 align-items-center text-default-color">
                    <img
                      src={MoneyDeposit}
                      height={20}
                      className="d-inline"
                      alt=""
                    />
                    &nbsp; Deposit
                  </h2>
                </Link>
              </div>
              <div className="col-5">
                <Link to="/withdrawal">
                  <h2 className="pay2 d-flex align-items-center text-default-color">
                    <img
                      src={MoneyDeposit}
                      height={20}
                      className="d-inline"
                      alt=""
                    />
                    &nbsp; Withdraw
                  </h2>
                </Link>
              </div>
            </div>
          </div>
        </section>
        <section className="service mb-3">
          <div className="container">
            <div className="row mx-0 py-3 px-2 bg-white service-box">
              <div className="col-12">
                <h3 className="text-dark">My Account</h3>
                <hr />
              </div>
              <div className="col-4 px-0 my-2">
                <div className="text-muted">
                  <div className="card border-0 text-center">
                    <Link to="/userinfo">
                      <img
                        src={PersonalInfoIcon}
                        className="img-fluid mx-auto mb-1"
                        width={50}
                        alt="official"
                      />
                      <div className="card-body p-1">
                        <h4 className="mb-0">Personal Information</h4>
                      </div>
                    </Link>
                  </div>
                </div>
              </div>
              <div className="col-4 px-0 my-2">
                <div className="text-muted">
                  <div className="card border-0 text-center">
                    <Link to="/kyc">
                      <img
                        src={KycIcon}
                        className="img-fluid mx-auto mb-1"
                        width={50}
                        alt="official"
                      />
                      <div className="card-body p-1">
                        <h4 className="mb-0">KYC</h4>
                      </div>
                    </Link>
                  </div>
                </div>
              </div>
              <div className="col-4 px-0 my-2">
                <div className="text-muted">
                  <div className="card border-0 text-center">
                    <Link to="/editprofile">
                      <img
                        src={CardIcon}
                        className="img-fluid mx-auto mb-1"
                        width={50}
                        alt="official"
                      />
                      <div className="card-body p-1">
                        <h4 className="mb-0">Withdrawal Address</h4>
                      </div>
                    </Link>
                  </div>
                </div>
              </div>
              <div className="col-4 px-0 my-2">
                <div className="text-muted">
                  <div className="card border-0 text-center">
                    <Link to="/recharge">
                      <img
                        src={RechargeIcon}
                        className="img-fluid mx-auto mb-1"
                        width={50}
                        alt="official"
                      />
                      <div className="card-body p-1">
                        <h4 className="mb-0">Recharge Records</h4>
                      </div>
                    </Link>
                  </div>
                </div>
              </div>
              <div className="col-4 px-0 my-2">
                <div className="text-muted">
                  <div className="card border-0 text-center">
                    <Link to="/withdrawalRecords">
                      <img
                        src={WalletIcon}
                        className="img-fluid mx-auto mb-1"
                        width={50}
                        alt="official"
                      />
                      <div className="card-body p-1">
                        <h4 className="mb-0">Withdrawal Records</h4>
                      </div>
                    </Link>
                  </div>
                </div>
              </div>
              <div className="col-4 px-0 my-2">
                <div className="text-muted">
                  <div className="card border-0 text-center">
                    <Link to="/funds">
                      <img
                        src={FundIcon}
                        className="img-fluid mx-auto mb-1"
                        width={50}
                        alt="official"
                      />
                      <div className="card-body p-1">
                        <h4 className="mb-0">Details of Funds</h4>
                      </div>
                    </Link>
                  </div>
                </div>
              </div>
              <div className="col-4 px-0 my-2">
                <div className="text-muted">
                  <div className="card border-0 text-center">
                    <Link to="/myteam">
                      <img
                        src={MyTeamIcon}
                        className="img-fluid mx-auto mb-1"
                        width={50}
                        alt="official"
                      />
                      <div className="card-body p-1">
                        <h4 className="mb-0">My Team</h4>
                      </div>
                    </Link>
                  </div>
                </div>
              </div>
              <div className="col-4 px-0 my-2">
                <div className="text-muted">
                  <div className="card border-0 text-center">
                    <Link to="/referalfriend">
                      <img
                        src={ShareIcon}
                        className="img-fluid mx-auto mb-1"
                        width={50}
                        alt="official"
                      />
                      <div className="card-body p-1">
                        <h4 className="mb-0">Invite your friends</h4>
                      </div>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <div className="mx-4 my-5">
          <div onClick={gotoLogout} className="btn theme-btn w-100">
            Logout
          </div>
        </div>
      </div>
      <FooterTab to="/profile" />
    </div>
  );
}

export default Profile;
