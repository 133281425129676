import React from "react";
import Nav from "../../component/nav";
import Footer from "../../component/footer";

const AboutUs = () => {
  const scrollToFAQ = () => {
    const faqSection = document.getElementById("faq");
    if (faqSection) {
      faqSection.scrollIntoView({ behavior: "smooth" });
    }
  };
  return (
    <>
      <Nav scrollToFAQ={scrollToFAQ} />
      <div style={{ textAlign: "justify" }} className="d-flex bg-white flex-column align-items-center  justify-content-center px-4">
        <div className="fs-1 fw-bold pb-2" style={{ marginTop: "80px" }}>
          ABOUT US
        </div>
        <div className="fw-bold">
          Welcome to MetaFoxBot - Your Gateway to Seamless Crypto Staking!
        </div>
        <div>
          At MetaFoxBot, we redefine the crypto staking experience by merging
          cutting-edge technology with the power of artificial intelligence. Our
          platform offers a unique proposition: staking without loss. We
          understand the importance of security and profitability in the crypto
          world, and our AI-driven robot ensures that your assets are optimized
          for maximum returns.
        </div>
        <div className="fw-bold pt-2">Our Mission:</div>
        <div>
          Empowering users to navigate the complex world of crypto staking with
          confidence and ease. We strive to eliminate uncertainties by providing
          a secure and intelligent solution that minimizes risks while
          maximizing rewards.
        </div>
        <div className="fw-bold pt-2">What Sets Us Apart: </div>
        <div>
          <span className="fw-bold pt-1">Loss-Free Staking: </span>
          Our innovative approach allows you to stake your crypto assets without
          the fear of losses. Our AI robot adapts to market conditions, making
          strategic decisions to safeguard your investments.
        </div>
        <div>
          <span className="fw-bold pt-1">Artificial Intelligence Integration: </span>
          The heart of MetaFoxBot lies in its sophisticated AI system.
          Constantly learning and evolving, our AI adapts to market trends,
          ensuring your staking strategy remains ahead of the curve.
        </div>
        <div>
          <span className="fw-bold pt-1">User-Centric Design: </span>
          We prioritize user experience, offering an intuitive platform that
          caters to both beginners and seasoned investors. Your journey with
          MetaFoxBot is designed to be seamless, from onboarding to staking and
          beyond.
        </div>
        <div className="fw-bold pt-2">Why Choose MetaFoxBot: </div>

        <div>
          <div>
            1. <span className="fw-bold pt-1">Security First: </span>
            Your assets are our top priority. We implement industry-leading
            security measures to safeguard your investments.
          </div>
          <div>
            2. <span className="fw-bold pt-1">Innovation at Its Core: </span>
            Embrace the future of crypto staking with our AI-driven robot that
            brings innovation to the forefront.
          </div>
          <div>
            3.
            <span className="fw-bold pt-1">Transparent and Accountable: </span>
            We believe in transparency. Track and monitor your staking
            activities in real-time, giving you complete control and visibility.
          </div>
          <div>
            4. <span className="fw-bold pt-1">Community-Centric Approach: </span>
            Join a thriving community of like-minded investors. Share insights,
            learn from others, and grow together in the world of crypto staking.
          </div>
        </div>

        <div className="fw-bold pt-3">Get Started:</div>
        <div>
          Embark on a journey of loss-free crypto staking with MetaFoxBot. Join
          us today and experience the next evolution in intelligent staking
          solutions.
        </div>
        <div className="fw-bold fs-5 pt-2" style={{ textAlign: "justify" }}>
          MetaFoxBot - Where AI meets Crypto Staking for a Secure and Lucrative
          Future.
        </div>
      </div>
      <Footer />
    </>
  );
};

export default AboutUs;
