/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import Logo from "../../img/logo_new.png";
import { Link, useNavigate } from "react-router-dom";
import { Formik } from "formik";
import * as yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { loginUser } from "../../redux/actions";
import Modal from "react-responsive-modal";
import ForgotPasswordModal from "../forgotPasswordModal";
import AngelImg from "../../img/angle-left.svg";

function Login() {
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const loginValidationSchema = yup.object().shape({
    email: yup
      .string()
      .email("Please enter valid email")
      .required("Email Address is Required"),
    password: yup
      .string()
      .min(8, ({ min }) => `Password must be at least ${min} characters`)
      .required("Password is required"),
  });

  const userData = useSelector((state) => state.user.userData);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleloginUser = (values) => {
    const loginData = {
      emailId: values.email,
      password: values.password,
    };
    dispatch(loginUser(loginData));
  };

  useEffect(() => {
    if (userData) {
      navigate("/");
    }
  }, [userData]);
  return (
    <>
      <div className="w-96 h-96">
        <Modal open={open} onClose={handleClose} center>
          <ForgotPasswordModal setOpen={setOpen} />
        </Modal>
      </div>
      <div>
        <div className="mt-3">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-2" onClick={() => navigate("/")}>
                <img src={AngelImg} height={25} alt="" />
              </div>
              <div className="col-8 text-center">
                <h4 className="mb-0 fs-3">Login</h4>
              </div>
            </div>
          </div>
        </div>
        <section className="register  ">
          <div className="container">
            <div className="d-flex py-3">
              <img src={Logo} className=" mx-auto" width={200} alt="logo" />
            </div>
            <div className=" px-3">
              <div className="mx-auto">
                <Formik
                  validationSchema={loginValidationSchema}
                  initialValues={{ email: "", password: "" }}
                  onSubmit={(values) => {
                    handleloginUser(values);
                  }}
                >
                  {({
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    values,
                    errors,
                    isValid,
                    dirty,
                  }) => (
                    <>
                      <form className="needs-validation" noValidate>
                        <div className="input-cont">
                          <div className="input-log">
                            <label
                              htmlFor="mobile_country"
                              className="fs-6 font-weight-500"
                            >
                              Email
                            </label>
                            <div className="row align-items-center">
                              <div className="col-12">
                                <input
                                  type="email"
                                  name="emailId"
                                  placeholder="Enter email "
                                  className="form-input custom_input w-100"
                                  value={values.email}
                                  onChange={handleChange("email")}
                                />
                              </div>
                            </div>
                          </div>

                          {errors.email && (
                            <div>
                              <span fontSize={10} style={{ color: "red" }}>
                                {" "}
                                {errors.email}
                              </span>
                            </div>
                          )}
                        </div>

                        <div className="input-cont">
                          <div className="input-log">
                            <label
                              htmlFor="mobile_country"
                              className="fs-6 font-weight-500"
                            >
                              Password
                            </label>
                            <div className="row align-items-center">
                              <div className="col-12">
                                <input
                                  type="password"
                                  placeholder="Enter Password"
                                  className="form-input custom_input w-100"
                                  value={values.password}
                                  onChange={handleChange("password")}
                                />
                              </div>
                            </div>
                          </div>
                          {errors.password && (
                            <div>
                              <span fontSize={10} style={{ color: "red" }}>
                                {" "}
                                {errors.password}
                              </span>
                            </div>
                          )}
                        </div>

                        <button
                          type="submit"
                          disabled={!isValid}
                          className="theme-btn btn w-100 mt-3"
                          onClick={handleSubmit}
                        >
                          Submit
                        </button>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          <p className="mt-4">
                            <Link to="/register">Click here to Register</Link>
                          </p>
                          <p className="mt-4" onClick={handleOpen}>
                            {" "}
                            <Link to="#">Forgot Password?</Link>
                          </p>
                        </div>
                      </form>
                    </>
                  )}
                </Formik>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
}
export default Login;
