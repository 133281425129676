export const HelpData = [
    {
        id: 1,
        title: 'How to recharge',
        description: 'After successful registration, you will obtain an exclusive TRC20 recharge address. Recharge may be realized by transferring money to this address. Your address will receive the money immediately after the transfer is successful. Tip: Minimum transfer amount is 9 USDT. Otherwise, the recharge will be failed, and the recharge amount will not be refunded.'
    },
    {
        id: 2,
        title: 'How to withdraw',
        description: 'Before withdrawal, you need to bind your own TRC20 beneficiary address. Once the binding is successful, any change of address will be unavailable. Pease make sure that the address belongs to you.'
    },
    {
        id: 3,
        title: 'Solution for problem: no monetary amount is received after recharge or withdrawal',
        description: 'If no monetary amount is received after recharge or withdrawal, please contact customer service staff, we will solve it for you timely.'
    },
    {
        id: 4,
        title: 'How to improve address security',
        description: 'It is suggested that login passwords and transaction passwords shall be different. Password shall be a combination of letters and numbers. Real-name verification shall be conducted at time of transaction.'
    },
    {
        id: 5,
        title: 'How to obtain higher revenue',
        description: 'By inviting your friends to login, you may receive commissions from the profits earned by your friends permanently. After your commission reaches a certain amount, you may apply for a higher commission ratio.'
    },
    {
        id: 6,
        title: 'What is digital currency?',
        description: 'Digital currency is an alternative currency in the form of electronic money. Also known as cryptocurrency. In theory, bureaucratic approval is avoided. For example, Bitcoin, Litecoin and USDT rely on checksum cryptography to create, issue and circulate electronic money. It is characterized by the use of P2P peer-to-peer network technology to issue and manage currency in circulation.'
    },
    {
        id: 7,
        title: 'What Is MetaFoxBot ?',
        description: 'Automate your trading with the MetaFoxBot Ai Powered crypto trading bot With Fixed Income. Start earning passive income in your sleep.'
    },
    {
        id: 8,
        title: 'Why use USDT as a payment currency?',
        description: 'USDT is a decentralized digital currency linked to the U.S. dollar. USDT strictly abides by the 1:1 reserve guarantee, that is, every USDT is endorsed by one U.S. dollar fund, so it creates the stability of USDT relative to other digital currencies, and it has also become a digital currency. Anchor currency.'
    },
]