/* eslint-disable react-hooks/exhaustive-deps */
import HeaderTitle from "../../component/header-title";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import ForgotPasswordModal from "../forgotPasswordModal";
import Modal from "react-responsive-modal";
import ForgotKeyModal from "../forgetKeyModal";

function PersonalInformation() {
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const handleClose = () => setOpen(false);
  const [keyOpen, setKeyOpen] = useState(false);
  const handleKeyClose = () => setKeyOpen(false);
  const [user, setUser] = useState(null);
  const userData = JSON.parse(localStorage.getItem("user"));

  const handleRoute = () => {
    navigate("/editprofile");
  };

  useEffect(() => {
    setUser({
      ...userData,
    });
  }, []);

  return (
    <div>
      <Modal open={open} onClose={handleClose} center>
        <ForgotPasswordModal
          setOpen={setOpen}
          email={user?.emailId}
          isMailExists={true}
        />
      </Modal>
      <Modal open={keyOpen} onClose={handleKeyClose} center>
        <ForgotKeyModal setOpen={setKeyOpen} email={user?.emailId} />
      </Modal>

      <HeaderTitle title={"Personal information"} />
      <div className="contant space">
        <div className="info-content p-3">
          <div className="info-box bg-white rounded p-3 my-bx-shadow">
            <div className="row">
              <div className="col-6">
                <p className="mb-0">Profile</p>
              </div>
              <div className="col-6">
                <p
                  className="nav-link m-0 p-0 d-flex justify-content-end"
                  onClick={handleRoute}
                  style={{ cursor: "pointer" }}
                >
                  Modify
                </p>
              </div>
            </div>
            {user && (
              <div className="row">
                <div className="col-6">
                  <p className="my-2">{user.userId}</p>
                </div>
              </div>
            )}
            <hr className="my-0" />
            <div className="row mt-2">
              <div className="col-6">
                <p className="my-2">Mobile Phone Number</p>
              </div>
            </div>
            {user && (
              <div className="row">
                <div className="col-6">
                  <p className="m-0">{user.phone}</p>
                </div>
              </div>
            )}
            <hr />
            <div className="row mt-4">
              <div className="col-6">
                <p className="mb-0">Withdrawal Key</p>
              </div>
              <div className="col-6">
                <p
                  className="nav-link m-0 p-0 d-flex justify-content-end"
                  onClick={() => setKeyOpen(true)}
                  style={{ cursor: "pointer" }}
                >
                  Change Key
                </p>
              </div>
            </div>
            {user && (
              <div className="row">
                <div className="col-6 m-2 mt-0">
                  <p className="m-0">########</p>
                </div>
              </div>
            )}
            <div className="row mt-4">
              <div className="col-6">
                <p className="mb-0"> Login Password</p>
              </div>
              <div className="col-6">
                <p
                  className="nav-link m-0 p-0 d-flex justify-content-end "
                  onClick={() => setOpen(true)}
                  style={{ cursor: "pointer" }}
                >
                  Change Password
                </p>
              </div>
            </div>
            {user && (
              <div className="row">
                <div className="col-6 m-2 mt-0">
                  <p className="m-0">########</p>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default PersonalInformation;
