import HeaderTitle from '../../component/header-title';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import { getWithdraw } from '../../redux/actions';
import moment from 'moment';


function WithdrawRecharge() {
    const dispatch = useDispatch();
    const withdrawRecords = useSelector(state => state.trasactionReducer.withdrawRecords)

    useEffect(() => {
        dispatch(getWithdraw());
    }, [])


    return (
        <div>
            <HeaderTitle title={'Withdraw records'} />
            <div className="contant space">
                <div className="info-content p-3">
                    <div className="info-box bg-white rounded p-2 my-bx-shadow">
                        <div className="row border-bottom px-1">
                            <div className="col-3">
                                <b className="mb-0">Time</b>
                            </div>
                            <div className="col-2">
                                <b className="mb-0 ">Amount</b>
                            </div>
                            <div className="col-3">
                                <b className="mb-0 d-flex justify-content-end">Status</b>
                            </div>
                            <div className="col-4">
                                <b className="mb-0 d-flex justify-content-end">Remark</b>
                            </div>
                        </div>
                        {Array.isArray(withdrawRecords) && withdrawRecords.map((ele, index) => (
                            <div className="row py-2 mt-4" key={index}>
                                <div className="col-3">
                                    <p className="mb-0">{moment(`${ele.addedAt}`).format('DD/MM/YYYY hh:mm')}</p>
                                </div>
                                <div className="col-2">
                                    <p className="mb-0">${ele.actualAmount.toFixed(4)}</p>
                                </div>
                                <div className="col-3">
                                    <p className="mb-0 d-flex justify-content-end">{ele.status}</p>
                                </div>
                                <div className="col-4">
                                    <p className="mb-0 d-flex justify-content-end">{ele.remark}</p>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </div>

    )
}
export default WithdrawRecharge;