import {
  SIGNIN_SUCCESS,
  SIGNIN_FAILED,
  IS_LOADING,
  SIGNUP_FAILED,
  SIGNUP_SUCCESS,
  UPDATE_USER_FAILED,
  UPDATE_USER_SUCCESS,
  SEND_VERIFICATION_FAILED,
  SEND_VERIFICATION_SUCCESS,
  CHANGE_PASSWORD_FAILED,
  CHANGE_PASSWORD_SUCCESS,
  GET_TRANSACTION_SUCCESS,
  GET_TRANSACTION_FAILED,
  DELETE_TRANSACTION_SUCCESS,
  DELETE_TRANSACTION_FAILED,
  ADD_WITHDRAW_SUCCESS,
  ADD_WITHDRAW_FAILED,
  UPDATE_WITHDRAWAL_SUCCESS,
  UPDATE_WITHDRAWAL_FAILED,
  DELETE_WITHDRAWAL_FAILED,
  DELETE_WITHDRAWAL_SUCCESS,
  ADD_DEPOSIT_FAILED,
  ADD_DEPOSIT_SUCCESS,
  GET_USER_SUCCESS,
  IS_WITHDRAWAL_SUCCESS,
  GET_USER_FAILED,
  GET_USER_TEAMS_SUCCESS,
  GET_USER_TEAMS_FAILED,
  USER_LOGOUT,
} from "../constant";

const initialstate = {
  loading: false,
  error: "",
  userData: null,
  registerData: null,
  updatedUser: null,
  verificationResponse: null,
  changePasswordRes: null,
  transactionDetails: null,
  deletedTransactionDetail: null,
  withdrawalDetails: null,
  updatedWithdrawalDetails: null,
  deletedWithdrawalResponse: null,
  depositResponse: null,
  myTeam: [],
  isWithdrawalSuccess: false,
};
const registerUser = (state = initialstate, action) => {
  switch (action.type) {
    case IS_LOADING:
      return { ...state, loading: action.isLoading };
    case SIGNIN_SUCCESS:
      return {
        ...state,
        userData: action.payload,
        loading: false,
      };
    case USER_LOGOUT:
      return {
        ...state,
        registerData: null,
        userData: null,
        loading: false,
      };
    case SIGNIN_FAILED:
      return {
        ...state,
        error: action.errorMessage,
        loading: false,
      };
    case SIGNUP_SUCCESS:
      return {
        ...state,
        registerData: action.payload,
        loading: false,
      };
    case SIGNUP_FAILED:
      return {
        ...state,
        error: action.errorMessage,
        loading: false,
      };
    case GET_USER_SUCCESS:
      return {
        ...state,
        userData: action.payload,
        loading: false,
      };
    case GET_USER_FAILED:
      return {
        ...state,
        error: action.errorMessage,
        loading: false,
      };
    case GET_USER_TEAMS_SUCCESS:
      return {
        ...state,
        myTeam: action.payload,
        loading: false,
      };
    case GET_USER_TEAMS_FAILED:
      return {
        ...state,
        error: action.errorMessage,
        loading: false,
      };
    case UPDATE_USER_SUCCESS:
      return {
        ...state,
        updatedUser: action.payload,
        loading: false,
      };
    case UPDATE_USER_FAILED:
      return {
        ...state,
        error: action.errorMessage,
        loading: false,
      };
    case SEND_VERIFICATION_SUCCESS:
      return {
        ...state,
        verificationResponse: action.payload,
        loading: false,
      };
    case SEND_VERIFICATION_FAILED:
      return {
        ...state,
        error: action.errorMessage,
        loading: false,
      };
    case CHANGE_PASSWORD_SUCCESS:
      return {
        ...state,
        changePasswordRes: action.payload,
        loading: false,
      };
    case CHANGE_PASSWORD_FAILED:
      return {
        ...state,
        error: action.errorMessage,
        loading: false,
      };
    case GET_TRANSACTION_SUCCESS:
      return {
        ...state,
        transactionDetails: action.payload,
        loading: false,
      };
    case GET_TRANSACTION_FAILED:
      return {
        ...state,
        error: action.errorMessage,
        loading: false,
      };
    case DELETE_TRANSACTION_SUCCESS:
      return {
        ...state,
        deletedTransactionDetail: action.payload,
        loading: false,
      };
    case DELETE_TRANSACTION_FAILED:
      return {
        ...state,
        error: action.errorMessage,
        loading: false,
      };
    case IS_WITHDRAWAL_SUCCESS:
      return {
        ...state,
        isWithdrawalSuccess: true,
      };
    case ADD_WITHDRAW_SUCCESS:
      return {
        ...state,
        withdrawalDetails: action.payload,
        loading: false,
        isWithdrawalSuccess: true,
      };
    case ADD_WITHDRAW_FAILED:
      return {
        ...state,
        error: action.errorMessage,
        loading: false,
        isWithdrawalSuccess: false,
      };
    case UPDATE_WITHDRAWAL_SUCCESS:
      return {
        ...state,
        updatedWithdrawalDetails: action.payload,
        loading: false,
        isWithdrawalSuccess: true,
      };
    case UPDATE_WITHDRAWAL_FAILED:
      return {
        ...state,
        error: action.errorMessage,
        loading: false,
        isWithdrawalSuccess: false,
      };
    case DELETE_WITHDRAWAL_SUCCESS:
      return {
        ...state,
        deletedWithdrawalResponse: action.payload,
        loading: false,
      };
    case DELETE_WITHDRAWAL_FAILED:
      return {
        ...state,
        error: action.errorMessage,
        loading: false,
      };
    case ADD_DEPOSIT_SUCCESS:
      return {
        ...state,
        depositResponse: action.payload,
        loading: false,
      };
    case ADD_DEPOSIT_FAILED:
      return {
        ...state,
        error: action.errorMessage,
        loading: false,
      };
    default:
      return state;
  }
};

export default registerUser;
