import React from "react";
import HeaderTitle from "../../component/header-title";
import { HelpData } from "./constant";

function HelpSection() {
  return (
    <div>
      <HeaderTitle title={"Help Support"} />
      <div className="bg-white pl-3 py-3 px-3 space">
        {HelpData.map((ele, index) => (
          <div key={index}>
            <h4 className="mb-0 mx-3 mb-2">
              {ele.id}.{ele.title}
            </h4>
            <p className="mb-0 text-muted mx-3 mt-2  mb-4">{ele.description}</p>
          </div>
        ))}
      </div>
    </div>
  );
}

export default HelpSection;
